



import * as React from 'react'
import  { useRef, useState,useEffect, } from 'react'
import {useSpring,a,useChain,config} from 'react-spring'
import {CgArrowLongLeft} from 'react-icons/cg'
import {easeInOutCirc} from '../../helperFunctions'
import {useResource} from 'react-three-fiber'
import "./node-card.css"
export function NodeCard({focus,node}:any) {
    const [dotRef,dotMesh]:any = useResource()
    const props:any = useSpring({
        
        opacity:focus? 1.0:0.0,
        native:true,
       
    })
    useEffect(()=>{
        if(node) node.listDotEl = dotMesh
    },[node,dotMesh])
   
    //const arrowProps:any = useSpring({opacity: enableNavigation ? 1.0:0.0})

    //useChain([props,escProps])
    return (
		<a.div  className="nodecard" style={props}>
                                {/*<a.div style={escProps} className="arrow"><CgArrowLongLeft/></a.div>*/}
                                
                                
                                <h2  >{node.title}</h2>
                                <p > {node.subheadline}</p>
                                <div ref={dotRef} className="colorBadge" style={{background:node.contentCount > 0?node.color:"white"}}></div>
                                <div className="category">{node.category?node.category:"?"}</div>
                                <div className={node.contentCount > 0? "actionText enlarge":"actionText"} style={{background:node.contentCount > 0?"white":"none",border:node.contentCount > 0?"none":"solid 1px white"}}>{node.contentCount > 0?"OPEN":"only for refence"}</div>
                            </a.div>
    )
}
