import {useThree,useFrame,extend} from 'react-three-fiber'
import CameraControls from 'camera-controls'
import { useEffect,useMemo,useRef,useState } from 'react'
import {Clock, Vector3} from 'three'
import * as THREE from 'three'
import * as React from 'react'
import { screenToWorldCoords } from '../helperFunctions';
import {Box3,Sphere} from 'three'
declare global {
	namespace JSX {
	  interface IntrinsicElements {
		cameraControls: {args:any,ref:any};
	  }
	}
  }
extend({CameraControls})
export default function Controls(props:any){
	const {camera,gl}:any = useThree()
	const clock:any = useMemo(()=> new Clock(),[]);
	const unlocked:any = useRef(false)
	const cameraOffsetState:any = useRef('initial')
	const dimensions:any = useRef(3)
	const cameraOffset:any={
		initial:{
			"2d":{x:0,y:0,z:0},
			"3d":{x:0,y:0,z:0}
		},
		desktop:{
			"2d":{x:-50,y:0,z:10},
			"3d":{x:-40,y:0,z:-10}
		},
		mobile:{
			"2d":{x:0,y:0,z:30},
			"3d":{x:0,y:-60,z:0}
		}
	}
	console.log(camera,gl)
	const [controls,setControls]:any = useState(null)
	useEffect(()=>{
		CameraControls.install( { THREE: THREE } );
		const cameraControls = new CameraControls( camera, gl.domElement );
		//const cameraControls = new CameraControls( camera, gl.domElement );
		cameraControls.dollyToCursor = true
		cameraControls.mouseButtons.left = props.editMode?CameraControls.ACTION.NONE:CameraControls.ACTION.TRUCK
		if(props.editMode){
			
			cameraControls.mouseButtons.right = CameraControls.ACTION.ROTATE
			cameraControls.mouseButtons.wheel = CameraControls.ACTION.TRUCK
		}
		console.log(cameraControls)
		setControls(cameraControls)
		cameraControls.enabled = false
		cameraControls.rotateTo(0,-Math.PI/4,false)
		cameraControls.moveTo(0,0,0,false)
		cameraControls.zoomTo(3.5,false)
	},[])
	/*useEffect(()=>{
		if(!controls) return
		controls.addEventListener( 'control', ()=>{
			//user is interacting
			//check for zoom value
			console.log("camera zoom",camera.zoom)
			var currentZoom=Math.round((camera.zoom)/3)
			var snapPointZoom = props.zoomSubject.value.zoom
			if(currentZoom < props.minZoom || currentZoom > props.maxZoom ) return
			if(currentZoom != snapPointZoom) props.zoomSubject.next({zoom:currentZoom,source:'controls',action:currentZoom < snapPointZoom? 'pop':'add'})
			
		}) 
		return ()=>controls.removeEventListener('control',null)
	},[props.zoom])*/


	/*useEffect(()=>{
		if(!props.searching || props.minimize) return
		if(controls){
			console.log("nodes",props.nodes)
			const boundingBox = new THREE.Box3();
				
			props.nodes.forEach((n:any)=>{
				boundingBox.expandByObject(n.mesh)
			})
			const sphere = boundingBox.getBoundingSphere(new THREE.Sphere());
			const { center, radius } = sphere;
			controls.fitToSphere(sphere,true)
			console.log("BOX",boundingBox)
		}
	},[props.nodes,props.searching,props.minimize,controls])
*/
	useEffect(()=>{
		if(controls) controls.dampingFactor = 0.1
		console.log("fddsaf, dimension change in camera",props.editMode)
		resetControls()
		
	},[controls])
	const resetControls=()=>{
		if(!controls || !unlocked.current) return
		controls.azimuthRotateSpeed = 1
		controls.polarRotateSpeed = 1
		controls.enabled = true
		controls.maxPolarAngle=Math.PI
		controls.minAzimuthAngle=-Infinity
		controls.maxAzimuthAngle=Infinity
		controls.dampingFactor = 0.1 
		
		var offset = cameraOffset[cameraOffsetState.current][dimensions.current+"d"]
		var bb = new Box3() 
		bb.expandByObject(props.root.mesh)
		
		console.log("fddsaf, camera reset controls",cameraOffsetState.current,props.root.mesh,bb)
			if(dimensions.current==2) {
				console.log("fddsaf, 2d");
				
				controls.rotateTo(0,-Math.PI/4,true)
				controls.moveTo(offset.x,offset.y,offset.z,true)
				
				controls.zoomTo(zoom(1,5,bb,0.5),true)
			}
			else if(dimensions.current==3){
				controls.rotateTo(-Math.PI/4,Math.PI/4,true)
				controls.moveTo(offset.x,offset.y,offset.z,true)
				controls.zoomTo(zoom(1,5,bb,0.5),true)
			}
		
		
	}
	const fitMesh=(mesh:any,rotate1:any,rotate2:any,padding:any)=>{
		controls.rotateTo(rotate1,rotate2,true)
		controls.fitTo(mesh,true,{paddingLeft:padding,paddingRight:padding,paddingTop:padding,paddingBottom:padding})
	}
	const zoom = (minZoom:any,maxZoom:any,AABB:any,PADDING:any)=> {
		var width = gl.domElement.clientWidth
		var height = gl.domElement.clientHeight
		return Math.max(
		Math.min(
		  Math.min(
			width / (AABB.max.x - AABB.min.x),
			height / (AABB.max.y - AABB.min.y)
		  ) * PADDING, // i.e. 0.95 = 5% padding,
		  maxZoom
		),
		minZoom
	  );
		  }
	const fitNodes=(nodes:any,zoomTo:any)=>{
		if(!unlocked.current) return
		const boundingBox:any = new Box3();
			nodes.forEach((n:any)=>{
				if(!n.mesh) return
				boundingBox.expandByObject(n.mesh)
			})
			
			/*if(props.dimensions == 2){
				var point = boundingBox.min.clone()
				point.setComponent(0,point.x-100)
				point.setComponent(1,point.y+10)
				boundingBox.expandByPoint(point)
			}
			else if(props.dimensions == 3){
				var point = boundingBox.min.clone()
				point.setComponent(0,point.x-110)
				//point.setComponent(1,point.y-10)
				var point2 = boundingBox.max.clone()
				point2.setComponent(1,point2.y+40)
				boundingBox.expandByPoint(point2)
				boundingBox.expandByPoint(point)
			}*/
			
			
			const sphere = boundingBox.getBoundingSphere(new Sphere());
			sphere.set ( sphere.center, sphere.radius )
			
			if(controls && dimensions.current==2) controls.rotateTo(0,-Math.PI/4,true)
			if(controls && dimensions.current==3) controls.rotateTo(-Math.PI/4,Math.PI/4,true)
			
			var offset = cameraOffset[cameraOffsetState.current][dimensions.current+"d"]
			sphere.translate(new Vector3(offset.x,offset.y,offset.z))
			const { center, radius } = sphere;
			controls.moveTo(center.x,center.y,center.z,true)
			controls.zoomTo(zoom(1,5,boundingBox,0.4),true)
			console.log("BOX",boundingBox)
			//currentBox.current = boundingBox

			
	}
	useEffect(()=>{
		var sub = props.operateSubject.subscribe(({type,args,options}:any)=>{
			if(!controls) return
			for(var key in options){
				controls[key] = options[key]
			}
			
			switch(type){
				case 'fitNodes':
					fitNodes(args[0],args[1])
					break;
				case 'fitMesh':
					fitMesh(args[0],args[1],args[2],args[3])
					break;
				case "zoom":
					controls.zoom(args[0],true)
					break;
				case "reset":
					if(controls) controls.dampingFactor = 0.1
					resetControls()
					break;
				case "unlock":
					unlocked.current = args[0]
					if(unlocked.current) resetControls()
					break;
				case "offsetState":
					console.log("offsetState",cameraOffsetState.current,args,type,options)
					cameraOffsetState.current = args[0]
					resetControls()
				case "offsetStateNoReset":
					cameraOffsetState.current = args[0]
				
			}
		})
		return ()=> sub.unsubscribe()
	},[props.operateSubject,controls,cameraOffsetState,props.device])
	useEffect(()=>{
		if(!props.device) return 
		cameraOffsetState.current = props.device
		resetControls()
	},[props.device])
	/*useEffect(()=>{
		console.log("zoom",props.zoom)
		var currentZoom = Math.round((camera.zoom)/3-1)
		var snapPointZoom = (props.zoom+1)*3

		if(controls && snapPointZoom != currentZoom) controls.zoomTo( (props.zoom+1)*3, true );
	},[props.zoom,controls])*/

	useEffect(()=>{
		//if(controls) controls.rotateTo(0,Math.PI/2,true)

		if(controls){
			resetControls()
		}
        
		camera.orbit = controls
        
    },[controls])

	
	useEffect(()=>{
		var sub = props.flagSubject.subscribe((e:any)=>{
			console.log("flagsubject in camera",e)
			if(e.dimensions != dimensions.current){
				console.log("dimensions change",e.dimensions,dimensions.current)
				dimensions.current = e.dimensions
				resetControls()
			}
			
		})
		return ()=>{
			sub.unsubscribe()
		}
	},[props.flagSubject,controls])
	useFrame( ({gl,scene,camera}:any) => {
		// snip
		
		if(controls){
			//console.log("controls",controls)
			const delta = clock.getDelta();
			const hasControlsUpdated = controls.update( delta );
			//gl.render( scene, camera );
		}
	


	// you can skip this condition to render though

		

	
	  })
	  /*useEffect(()=>{
		if(props.editMode && controls){
			controls.enabled = false
		}
	  },[props.editMode,controls])*/
	return (controls && <primitive object={controls}/>)

}