import * as React from 'react'
import { useEffect, useState } from 'react';
import { useFirebase } from 'react-redux-firebase'
import './firebase-image.css'
var cache:any  = {}
//@ts-ignore
export default function FirebaseImage({path}){
  const firebase = useFirebase()
  var [url,setUrl] = useState('')
  
  function downloadImageFromPath(path:any){
    var ref = firebase.storage().ref()
    return ref.child(path).getDownloadURL().then((downloadUrl:any)=> {
      // `url` is the download URL for 'images/stars.jpg'
      setUrl(downloadUrl)
      cache[path] = downloadUrl 
      
    }).catch(function(error) {
      // Handle any errors
    });
  }
  
  useEffect(()=>{
    downloadImageFromPath(path)
  },[path])
 
  
  
  //console.log("rendering firebaseimage")
    return (
        <div className="firebase-image">
          {/*
          @ts-ignore */}
          {url &&  <img src={url}></img>}
          
        </div>
      
    );
}

  