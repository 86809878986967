import * as React from 'react'
import  { useEffect } from 'react'
import Dropzone from 'react-dropzone'
import FirebaseImage from '../../firebase-image/firebase-image'
import FirebaseImageUpload from '../firebase-image-upload/firebase-image-upload.component'
import { useSelector } from 'react-redux'
import { useFirebaseConnect, isLoaded, isEmpty, useFirestoreConnect,useFirestore,useFirebase } from 'react-redux-firebase'
import {useState} from 'react'
import uuidv1 from 'uuid'
import './content-form.css'
import FirebaseInput from '../firebase-input/firebase-input.component'
import { CgFormatText } from 'react-icons/cg'
import { AiFillCamera, AiFillVideoCamera } from 'react-icons/ai'
import {BiBold,BiItalic} from 'react-icons/bi'
import {GrTextAlignCenter,GrTextAlignLeft,GrTextAlignRight} from 'react-icons/gr'
import FirebaseFilmUpload from '../firebase-film-upload/firebase-film-upload.component'
export default function ContentForm({id}:any){
	useFirestoreConnect(() => [
		{ collection: 'Contents', doc: id,storeAs:"adminContentDetail" } // or `todos/${props.todoId}`
	  ])
	const firestore:any =  useFirestore()
	const firebase:any = useFirebase()
	var content = useSelector((state:any) => {
		console.log("content",state.firestore.data.adminContentDetail)
		return state.firestore.data.adminContentDetail
		
	})
	
	
	
	  if(isEmpty(content) || !isLoaded(content)){
		  return <div>not loaded</div>
	  }
	  
	
	


	  

	  







	  console.log("content",content)


	return <div className="content-form" key={content.id}>
	<div className="section">
		<div className="title section">
		<div className="contentTypeBanner">
					  {content.type == 'text'?  <CgFormatText/>:content.type== 'film'?<AiFillVideoCamera/>:<AiFillCamera/>}
					  <p>{content.type} </p>
				  </div>
	</div>
	  
	  {content.type == 'text' && (
		  <div>
		  	<FirebaseInput type="textarea" placeholder="write a medium sized paragraph about this commitment" value={content.source&& content.source.text || ""} name="source.text" id={id} collection="Contents"/>
			  <FirebaseInput saveDelay={0} type="color"  placeholder="Color" name="style.color" value={content.style&&content.style.color} id={id} collection="Contents"/>
			  <FirebaseInput saveDelay={0} type="number"  placeholder="Line Height" name="style.lineHeight" value={content.style&&content.style.lineHeight} id={id} collection="Contents"/>
			  <FirebaseInput saveDelay={0} type="number"  placeholder="Letter Spacing" name="style.letterSpacing" value={content.style&&content.style.letterSpacing} id={id} collection="Contents"/>
			  <FirebaseInput saveDelay={0} type="number" placeholder="Font Size" name="style.fontSize" value={content.style&&content.style.fontSize} id={id} collection="Contents"/>
			  <div className="optionBar">
			 	<FirebaseInput saveDelay={0} type="button" name="style.bold" value={content.style && content.style.bold} id={id} collection="Contents"><BiBold/></FirebaseInput>
				<FirebaseInput saveDelay={0} type="button" name="style.italic" value={content.style && content.style.italic} id={id} collection="Contents"><BiItalic/></FirebaseInput>
				  
			  </div>
			  <div className="optionBar">
			 	<FirebaseInput saveDelay={0} type="button" name="style.textAlign" value={content.style && content.style.textAlign == "left"} valueToAssign={'left'} id={id} collection="Contents"><GrTextAlignLeft/></FirebaseInput>
				<FirebaseInput saveDelay={0} type="button" name="style.textAlign" value={content.style && content.style.textAlign == "center"} valueToAssign={'center'} id={id} collection="Contents"><GrTextAlignCenter/></FirebaseInput>
				<FirebaseInput saveDelay={0} type="button" name="style.textAlign" value={content.style && content.style.textAlign == "right"} valueToAssign={'right'} id={id} collection="Contents"><GrTextAlignRight/></FirebaseInput>
			  </div>
			  </div>
	  )}
	  
		{content.type == 'photo' && (
				<FirebaseImageUpload values={content.source && [content.source] || []} name={"source"} collection={"Contents"} id={id} multiple={false}></FirebaseImageUpload>
		)}
		{content.type == 'film' && (
			<div>
				<p>Film</p>
				<FirebaseFilmUpload value={content.source && content.source || null} name={"source"} collection={"Contents"} id={id} createPreview={true}></FirebaseFilmUpload>
				<p>Poster Image</p>
				<FirebaseImageUpload values={content.source && [content.source] || []} name={"source"} collection={"Contents"} id={id} multiple={false}></FirebaseImageUpload>
		</div>)}
	  
	  
		</div>

  </div>
}