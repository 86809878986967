import { SCRUB, ADD_COMMITEMNT ,ADD_TRACK,ADD_COMMITEMNT_TRACK,SET_TIME_SCALE,COMMIT_TO_COMMITMENT} from "../actionTypes";
import uuidv1 from 'uuid/v1'

const initialState = {
    commitments:[],
    commitmentIds:{},
    commitmentStats:{
      start:null,
      end:null
    },
    tracks:[],
    trackIds:{},
    timeScale:null,
    selection:{
      date:new Date(),
      location:"Loading..",
      commitments:[]

    },
    trackLayoutAttributes:{
      topLimit:-1,
      bottomLimit:0
    }
    
  }
 
  const getTrack = (state:any,c1:any) => {
      var selectedTrack = null
    var tracks = state.tracks.filter((t:any)=>{return t.category == c1.category})
    for(var trackId in tracks){
      var collision = false
      var track = tracks[trackId]
      var trackCommitments = state.commitments.filter((c:any)=> {return c.track.id == track.id})
      console.log(trackCommitments);
      for(var n = 0; n < trackCommitments.length; n++){
        var c2 = trackCommitments[n]
        //if the commitemnt is inside the new one we can't place the new one in this track
        var inside = c2.start >= c1.start && c2.end <= c1.end
        var overlapStart = c1.start >= c2.start && c2.end > c1.start
        var overlapEnd = c2.start >= c1.start && c2.start < c1.end && c2.end > c1.end

        if(inside || overlapEnd || overlapStart){
          collision = true
          break;
        }
      }
      if(!collision){
        selectedTrack = track
        break;
      }
    }
    var y;
    if(!selectedTrack){
      //create track
      
      if(Math.abs(state.trackLayoutAttributes.topLimit) > state.trackLayoutAttributes.bottomLimit){
        //add to bottom limit
        y = state.trackLayoutAttributes.bottomLimit
        state.trackLayoutAttributes = {... state.trackLayoutAttributes, bottomLimit: y+1}
      }else{
        y = state.trackLayoutAttributes.topLimit
        state.trackLayoutAttributes = {... state.trackLayoutAttributes, topLimit: y-1}
      }
      var id = uuidv1()
      var t = {id:id,y,category:c1.category,count:0, start:c1.start, end:c1.end, first:c1.id,last:c1.id,bucketId: 1}
      state = addTrack(state,t)
      
      selectedTrack = {...t}
    }
    selectedTrack.count++;
    if(selectedTrack.end < c1.end){
      //assign new last commitment on the track
      selectedTrack.last = c1.id
      selectedTrack.end = c1.end
    }
    if(selectedTrack.start > c1.start){
      //assign new last commitment on the track
      selectedTrack.first = c1.id
      selectedTrack.start = c1.start
    }
    return {newState:state,track:selectedTrack}
  }

const addTrack = (state:any,payload:any)=>{
    return {...state,
      tracks:[...state.tracks,payload],
      trackIds:{...state.trackIds,[payload.id]:state.tracks.length}
  }
}
export default function timeline(state:any = initialState, action:any) {
    switch (action.type) {
      case SCRUB:
        console.log("SCRUB");
        var date = action.payload
        var commitments = state.commitments.map((c:any)=>{
          c.bucketId = date > c.end? 0:date < c.start? 2:1
          
          return {...c}
        })

        var currentImportance = 0, location="";
        var tracks = state.tracks.map((t:any,i:any)=>{
          t.bucketId = date > t.end? 0:date < t.start? 2:1
          return {...t}
        })
        var selectionCommitments = commitments.filter((c:any)=>{ return c.bucketId == 1})
        selectionCommitments.forEach((o:any,i:any)=>{
          
          if(o.importance > currentImportance) {
            currentImportance = o.importance
            location = o.location
            
          }
        })
        var selection = {
          date:date,
          location:location,
          commitments:selectionCommitments,
          tracks:tracks
        }
        return {...state,selection:selection,commitements:[...commitments]}
      
      case COMMIT_TO_COMMITMENT:
        //Put all commitments into bucket 4 accept the one with the payload id
        var id = action.payload.id
        var newCommitments = state.commitments.map((c:any)=>{
          console.log(c.id,id);
          if(c.id == id) c.bucketId = 4
          else c.bucketId = 3
          return {...c}
        })
        return {...state,commitments:[...newCommitments]}
      case ADD_COMMITEMNT:
        console.log("ADD_COMMITEMNT",state);
          var c = {...action.payload}
        //before we add this object we want to read the current date 
        var bucketId = state.date > c.end? 2:state.date < c.start? 0:1
        c.bucketId = bucketId
        //set inFocus to false
        c.inFocus = false
        //since each of our commitment needs to be layed out on a track in our timeline we spend this time 
        //finding the track we will place it on. since no UI specific values are needed we don't have to do this in the component.
        var {newState,track} = getTrack(state,c)
        c.track = track
        state = newState
        
        c.x = 400;
        c.y = 400; 
        c.rDynamic = 20*c.importance; 
        c.rOriginal = 100;
        var bigbang = !state.commitmentStats.start || state.commitmentStats.start > c.start? c.start:state.commitmentStats.start
        var bigend = !state.commitmentStats.end || state.commitmentStats.end < c.end? c.end:state.commitmentStats.end 
        //we push our new data to the commitments list
        return {
            ...state,
            commitments:[...state.commitments,c],
            commitmentIds:{...state.commitmentIds,[c.id]:state.commitments.length},
            commitmentStats:{...state.commitmentStats,start:bigbang,end:bigend}
        }

        
      case ADD_TRACK:
        console.log("ADD_TRACK");
        return addTrack(state,action.payload)
        
      case ADD_COMMITEMNT_TRACK:
          console.log("ADD_COMMITEMNT_TRACK");
          //get commitment
          var c = {... state.commitments[state.commitmentIds[action.payload.id]]}
          //set the track to a copy of the track in the tracks array
          c.track = {...state.tracks[state.trackIds[action.payload.trackId]]}
          //copy all commitments to a new array
          var cs = [...state.commitments]
          //set the new update commitement back into its old index
          cs[state.commitmentIds[action.payload.id]] = c
          
          return {...state,
            commitments:cs
          }
      case SET_TIME_SCALE:
        return {...state,timeScale:action.payload}

      default:
        return state
    }
  }
