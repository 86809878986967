import {  useResource } from 'react-three-fiber'
import * as React from 'react'
import { useRef, useState,useMemo,useEffect } from 'react'
//@ts-ignore
import {useSpring,a,config} from 'react-spring/three'
import {Text,Plane, Html} from 'drei'
import {easeInOutCirc,hexToRGB} from '../helperFunctions'
import DateBoxV2 from './dateboxv2.component'
import { MouseCrossair } from './mouse-crossair.component'
import { Vector3 } from 'three'
 









	


	

	export default function Node(props:any){
	var [meshRef,timelineMesh]:any = useResource()
    var level:number = props.data.level

	var node = props.data
	var leaf:boolean = props.data.leaf
	var root:boolean = node.parent?false:true
	var children:any = props.children
	
	var [zoom,setZoom] = useState(props.zoom)
	//var [mode,setMode] = useState(props.zoom==level? 2:props.zoom+1==level? 1:props.zoom> level?3:0)
	var animationInterval:any = useRef(null)
	var dimensions = useRef(3)
	var layoutState = useRef(props.editMode?"final":leaf?"origin":"final")
	var [expanded,setExpanded] = useState(true)
	var selectable = useRef(false)
	var frontier = false
	var isSearchResult = useRef(false)
	var hideRef = useRef(true)
	var [particleRef,particleMesh]:any = useResource()
	/*var [childLayouts, setChildLayouts] = useState(children.map((child:any)=>{
		return null
	}))*/
	var [hovered,setHovered] = useState(false)
	//var [nodeLayout,setLayout] = useState(node.layout || {x:0,y:0,w:1,h:1,d:1,padding:0})
	var [color,setColor] = useState(node.color)
	//var [childRefs,setChildRefs]:any = useState({})
	const boxGeometry:any = useRef()

	const [animatedProps, update,stop]:any = useSpring(() => {
		//console.log("making spring",node.layout)
		return{
		meshOpacity:0.0,//mode==0 || mode==2 ?0.0:1.0
		wireframeOpacity:0.0,//(mode==1|| mode==2)&&hovered?1.0:mode==0 ?0.1:0.3,
		outerTextOpacity:0.0,//mode==2&&hovered?1.0: mode==2 ?0.3:0.0,
		tooltipOpacity:0.0,
		
		scale:[1,1,1],//[nodeLayout.w,nodeLayout.h,nodeLayout.d],
		rotation:[0,0,0],
		dateLineScale:[0.0001,0.000001,0.00001],
		outerTextScale:[0.0001,0.000001,0.00001],
		outerTextRotation:[0,0,0],
		position:[0,0,0],
		childGroupPosition:[0,0,0],//[0,nodeLayout.y+nodeLayout.h/2 - nodeLayout.padding/2,0],
		innerTextPosition:[0,0,0],//[nodeLayout.x-nodeLayout.w/2+1,nodeLayout.y,nodeLayout.d/2+1],
		innerTextScale:[0.0001,0.000001,0.00001],
		innerTextRotation:[0,0,0],
		outerTextPosition:[0,0,0],//[nodeLayout.x-nodeLayout.w/2-3,nodeLayout.y,0]
		parentPosition:[0,0,0],
		
		particlePosition:[0,0,0],
		particleScale:[1,1,1],
		particleRotation:[0,0,0],

		
		meshColor:"#ffffff",
		dateLinePosition:[0,0,0],
		native:true,
		immediate:false
       
		}
	})
	


	var onClick=(e:any)=>{
		//console.log("clicked",l,zoom,mode,e)
		if(props.editMode && props.editSubject && !hideRef.current){
			console.log("clicked in edit",node)
			props.editSubject.next(node)
			//if(e) e.stopPropagation()

		}
		if(!selectable.current) return
		if(e) e.stopPropagation()
		//setHovered(false)
		if(node.contentCount > 0){
			
			
			//console.log("click approved", l,cz,m);
			//props.onClick(node,mesh.current,!expanded)
			if(!leaf) return
			//pulseNode(1000)
			updateVisibility(selectable.current,frontier,searching.current,isSearchResult.current,false,false,false,0)
			console.log("onCLick",node,e)
			props.openSubject.next(node)
		}else{
			console.log("shaking node click")
			if(e) e.stopPropagation()
			shakeNode(500)
		}
		
	}
	

	var onHover=(hover:any,propagate:any)=>{
		if(!selectable.current || !leaf || hideRef.current) return
			
		
		if(props.hasOwnProperty('hoverSubject')&&propagate){
			console.log("executing hover subject next")
			props.hoverSubject.next({n:node,h:hover,origin:"node"})
		}

		setHovered(hover)
		updateVisibility(selectable.current,frontier,searching.current,isSearchResult.current,hover,false,hideRef.current,0)
		updateLayout(node.layout,expanded,hover,searching.current,isSearchResult.current,dimensions.current,hideRef.current,0,{})
	}
	/*var updateCycle:any = 0
	var layoutBucket:any
	var onLayout=(i:any,l:any,isLeaf:any)=>{
		if(updateCycle > 0) return
		//on layout we will update the nodes own layout
		if(!layoutBucket){
			layoutBucket = children.map(()=>null)
		}
		layoutBucket[i] = l
		const layoutsInBucket = layoutBucket.filter((a:any)=>a).length
		const totalChildLayouts = children.length
		const timeTolayout = totalChildLayouts == layoutsInBucket
		//console.log("hgdghf","child update happened",level,name);
		
		console.log("layout request",level,layoutsInBucket,totalChildLayouts,timeTolayout,layoutBucket)
		
		if(timeTolayout){
			const layouts = layoutBucket
			layoutBucket = null
			var padding = (isLeaf?3.0: (expanded && !frontier? 30:10.0)) 
			//console.log("hgdghf","child update spwaned rerended of layouts",level,name);
			var [updatedLayout,updatedChildlayouts,childrenAreDifferent] = computeLayout(layouts,level,padding,props.dateRange,[...childLayouts])
			
			
			
			console.log("layout computed",level,childrenAreDifferent,updatedLayout,nodeLayout)
			if(childrenAreDifferent){
				setChildLayouts([...updatedChildlayouts])
				//console.log(level, "sdfsdf", )
				//we have collected layouts for
				
				
			}
			
			if(props.hasOwnProperty('onLayout')) props.onLayout(updatedLayout)
			if(level==0){
				setLayout({...updatedLayout})
				updateLayout(updatedLayout,expanded,hovered,searching.current,isSearchResult.current,dimensions.current,hideRef.current,0,{})
			}
			//updateCycle++;
			
		}
		
	}*/
	const shakeNode=(duration:any)=>{
		var angle = 0
		var scaleFactor = 0.5
		var totalDuration = 0
		var increment =40
		var interval = setInterval(()=>{
			if(duration < totalDuration) {
				clearInterval(interval)
				update({
					rotation:[0,0,0]
				})
				return
			}
			console.log("shaking node")
			update({
				rotation:[0,Math.sin(angle)*scaleFactor,0]
			})
			angle+=1
			totalDuration+= increment
		},increment)
		
	}
	var pulseInterval:any = useRef()
	const pulseNode=(duration:any)=>{
		var angle = 0
		var scaleFactor = 0.5
		var totalDuration = 0
		var increment =40
		if(pulseInterval.current) clearInterval(pulseInterval.current)
		pulseInterval.current = setInterval(()=>{
			if(duration < totalDuration) {
				clearInterval(pulseInterval.current)
				update({
					scale:[1,1,1]
				})
				return
			}
			console.log("shaking node")
			var scale = Math.sin(angle)*scaleFactor
			update({
				scale:[scale,scale,scale]
			})
			angle+=1
			totalDuration+= increment
		},increment)
		
	}
	
	const updateLayout = (l:any,e:any,h:any,s:any,isr:any,d:any,hide:any,delay:any,options:any)=>{
		console.log("updated layout"+ level,l,timelineMesh)
		
		//node.layout = l
		node.hoverLayout = {...l,h:l.h*1.5,d:l.d*1.5}
		var layoutOverride = layoutState.current != 'final'
		var particleLayout:any = {x:0,y:0,z:0,w:1,h:1,d:1,padding:0}
		var updateObj = {...options,	
			innerTextPosition:[level==1?l.x-l.w/2-10:l.x-l.w/2-(d==3?6:0),level==1?0:-l.d/2,l.y-(level==1?l.padding:0)],
			innerTextScale:hide || (s && !isr) || d==2 || leaf?[0.001,0.001,0.001]:[1,1,1],
			innerTextRotation:[-Math.PI/2,0,-Math.PI/2],
			outerTextPosition:[level==1?l.x-l.w/2-(d==3?10:10):l.x-l.w/2-(d==3?6:0),level==1?0:d==3?0:l.d/2,level==1||d==3?l.y-(level==1?l.padding:0):l.y-l.h/2-2],
			outerTextRotation:d == 2&&level!=1? [-Math.PI/2,0,0]:[-Math.PI/2,0,-Math.PI/2],
			outerTextScale:hide || (s && !isr) || d==3?[0.001,0.001,0.001]:[1,1,1],
			
			scale:[1,1,1],
			position:[l.x,0.0,l.y +(level==1 && !props.editMode?l.h/2 + l.padding:0.0)],
			childGroupPosition:[0,0,l.y+l.h/2 - l.padding/2],
			parentPosition:[0,0,level==0?l.h/2+ l.padding/2:0],
			dateLinePosition:[0,0,-l.h/2 + l.padding/2],
			dateLineScale:hide?[0.00001,0.00001,0.000001]:[1,1,1],
			delay,
			immediate:layoutOverride || (options && options.immediate)
	}
	if(level==0) updateObj.particleScale = [l.w,l.d,l.h]
		update(updateObj)
		if(timelineMesh){
			
			timelineMesh.updateMatrixWorld(true)
			timelineMesh.updateMatrix()
			if(leaf && layoutState.current == 'initial'){
				if(animationInterval.current) clearInterval(animationInterval.current)
				var vector = new Vector3(node.initial.x,node.initial.y,node.initial.z)
				var vectorScale = new Vector3(node.initial.w,node.initial.h,node.initial.d)
				var vectorRotation = new Vector3(node.initial.rx,node.initial.ry,node.initial.rz)
				console.log("hello",timelineMesh,vector,node.initial,node)
				 timelineMesh.worldToLocal(vector)
				 particleLayout.x = vector.x
				particleLayout.y = vector.y
				particleLayout.z = vector.z
				particleLayout.w = vectorScale.x
				particleLayout.h = vectorScale.y
				particleLayout.d = vectorScale.z
				update({
					particlePosition:[particleLayout.x,particleLayout.y,particleLayout.z],
					particleScale:[particleLayout.w,particleLayout.h,particleLayout.d],
					particleRotation:[vectorRotation.x,vectorRotation.y,vectorRotation.z],
					config:{friction:30,tension:180,mass:5},
					delay:node.delayIndex*10,
					immediate:false
				})
			}else if(leaf && layoutState.current == 'origin'){
				if(animationInterval.current) clearInterval(animationInterval.current)
					var vector = new Vector3(0,0,0)
						var vectorScale = new Vector3(1,1,1)
						var vectorRotation = new Vector3(0,0,0)
						 timelineMesh.worldToLocal(vector)
					update({
						particlePosition:[vector.x,vector.y,vector.z],
						particleScale:[vectorScale.x,vectorScale.y,vectorScale.z],
						particleRotation:[vectorRotation.x,vectorRotation.y,vectorRotation.z],
						
						immediate:true
					})
				}else if(leaf && layoutState.current == 'particle'){
				if(animationInterval.current) clearInterval(animationInterval.current)
				var vector = new Vector3(node.fragmented.x,node.fragmented.y,node.fragmented.z)
					var vectorScale = new Vector3(node.fragmented.w,node.fragmented.h,node.fragmented.d)
					var vectorRotation = new Vector3(node.fragmented.rx,node.fragmented.ry,node.fragmented.rz)
				 	timelineMesh.worldToLocal(vector)
					update({
						particlePosition:[vector.x,vector.y,vector.z],
						particleScale:[vectorScale.x,vectorScale.y,vectorScale.z],
						particleRotation:[vectorRotation.x,vectorRotation.y,vectorRotation.z],
						config:{friction:30,tension:180,mass:3},
						delay:node.delayIndex*30
					})
					
				
				
			}else if(leaf && layoutState.current == "rotateParticle"){
				if(animationInterval.current) clearInterval(animationInterval.current)
				var angle = Math.random()*Math.PI*2
				var tension = 180
				var scaleFactor = 50
				var totalSpinDuration = 1000
				var currentSpintDuration = 0
				var randomZ = Math.random()
				//var randomX = Math.random()
					animationInterval.current = setInterval(()=>{
						
						var x = Math.cos(angle)*scaleFactor
						var y = -Math.sin(angle)*scaleFactor
						var z = Math.sin(angle)*scaleFactor*randomZ
						var vector = new Vector3(node.particle.x+x,node.particle.y+y,node.particle.z+z)
						var vectorScale = new Vector3(node.particle.w,node.particle.h,node.particle.d)
						var vectorRotation = new Vector3(node.particle.rx,node.particle.ry,node.particle.rz)
						console.log("hello",timelineMesh,vector,node.initial,node)
						 timelineMesh.worldToLocal(vector)
						 var obj = {...options,
							particlePosition:[vector.x,vector.y,vector.z],
							particleScale:[vectorScale.x,vectorScale.y,vectorScale.z],
							particleRotation:[Math.PI*2*Math.cos(angle),Math.PI*2*Math.cos(angle),Math.PI*2*Math.cos(angle)],
							config:{friction:40,tension:280,mass:30},
							delay:node.delayIndex*20}
						 if(options && options.config){
							obj.config = options.config
						}
						update(obj)
						angle+= 0.3
						//currentSpintDuration+=5
						//tension+=2
						
					},50)
			}
			else if(layoutState.current == 'final'||layoutState.current == 'pre-final'||layoutState.current == "hide"){
				if(animationInterval.current) clearInterval(animationInterval.current)
							console.log(node.layout,l)
							var obj = {...options,
								particlePosition:[0,0,0],
								particleScale:hide&&level!=0?[0.1,0.1,0.1]:h&&leaf? [l.w,l.d*1.5,l.h*1.5]:level==0?[l.w,l.d,l.h]:[l.w,level==1&&!props.editMode?0.001:l.d,level==1&&!props.editMode?0.01:l.h],
								particleRotation:[0,0,0],
								config:layoutState.current == 'final'? {friction:30,tension:80,mass:5}:layoutState.current == 'hide'?{friction:30,tension:220,mass:2}:{friction:30,tension:80,mass:10},
								delay:layoutState.current == 'final'||layoutState.current == 'hide'?delay:node.delayIndex*20,
							}
							if(options && options.config){
								obj.config = options.config
							}
							if(options && options.immediate){
								delete obj['delay']
								delete obj['config']
								obj.immediate = options.immediate
							} 
							console.log("hello10", options,obj)
							update(obj)
			}
			
			
			
			
		}
		
		
		
		//var position = animatedProps.position.getValue()
		//console.log("updated layout position"+ level,name,l,position[0] != l.x,position[1]!= l.y,position[1],position)
		
		
	}
	
	var updateVisibility = (s:any,f:any,search:any,isr:any,h:any,w:any,hide:any,delay:any) =>{
		console.log("update visibility",props)
		const hasContent = node && node.contentCount && node.contentCount > 0
		const initialStateOverride = leaf&&(layoutState.current != 'final' && layoutState.current !='pre-final' && layoutState.current !='hide')
		var meshOpacity:any =0.0 
		var wireframeOpacity:any =0.0
		if(hide || level == 0){
			meshOpacity = 0
			wireframeOpacity = 0.0
		}else{
			if(initialStateOverride){
				meshOpacity = 1
				wireframeOpacity = 0.0
			}else{
				if(leaf){
					if(s&&hasContent){
						meshOpacity = 1
						wireframeOpacity = 0.0
					}else{
						meshOpacity = search? isr?0.2:0.03:h?0.2:0.04
						wireframeOpacity = isr?0.0:0.0
						
					}
					
				}else{
					if(isr || !search){
						meshOpacity = 0.02
						wireframeOpacity = 0.06
					}else{
						meshOpacity = 0.01
						wireframeOpacity = 0.01
					}
					
				}
			}
		}
		
		
		update({
			meshOpacity:meshOpacity,//m==1?leaf && h?1.0:0.1:(m==2? leaf?1.0:0.0:0.0),
			meshColor: leaf&&s&&hasContent?color: "#f1f5ff",
			wireframeOpacity:wireframeOpacity,
			tooltipOpacity:h&&leaf?1.0:0.0,
			config:{friction:30,tension:280,mass:1},
			delay
		})
		
	  }
	  
	
	  
	  /*useEffect(()=>{
		//focus is an array of {node,mesh}
		//find id in the array means that the ndoe is in the drill down path
		var nodeIndex = props.focus.findIndex((a:any)=>{
			return a.node.id == node.id
		})
		var e = nodeIndex > -1
		//if it was found it means that the node is expanded
		//next we compare to see if we can find our parentId in the focusList
		
		var s = props.parentExpanded && !e && level != 1
		var f = nodeIndex == props.focus.length-1

		setHovered(false)
		setZoom(props.zoom)
		selectable.current = s
		setExpanded(e)
		//setFrontier(f)
		if(leaf){
			/*var l = {x:node.x+node.w/2,y:node.y,w:node.w,h:node.h,d:node.d,padding:0,startDate:node.startDate,endDate:node.endDate,invisible:!e.value}
			setLayout(l);
			if(props.hasOwnProperty('onLayout')) props.onLayout(l,leaf)*
			selectable.current = true
			//updateVisibility(true,false,false,true,false,false,0)
		}else{
			if(level < 1) return
			//updateVisibility(false,true,true,false,true,false,0)
			
		}
		console.log("sdfsdfsf",node.id,s,e,f)
		if(leaf){
			updateLayout(nodeLayout,e,false,searching.current,isSearchResult.current,dimensions.current,hideRef.current,0,{})
		}
		
	  },[props.zoom,props.focus,props.parentExpanded])*/
	  

	  var searching:any = useRef(false)

	  useEffect(()=>{
		  console.log("setup of subject listener", node)
		  var sub = node.subject.subscribe((e:any)=>{
			  console.log("subject event inside node",e,node.layout)
			  switch(e.action){
				  case "hover":
					  
					  //if(searching) return 
					  console.log("hover event in node",e)
					  onHover(e.value,false)
					  break;
					case "miniHighlight":
						updateVisibility(true,false,false,false,true,false,false,0)
						break;
					case "search":
						//if search is triggered we want to expand all
						searching.current = e.value
						isSearchResult.current = e.value
						selectable.current = leaf
						updateLayout(node.layout,expanded,hovered,searching.current,e.value,dimensions.current,hideRef.current,0,e.options||{})
						updateVisibility(selectable.current,false,searching.current,false,false,false,hideRef.current,0)
						break;
					case "searchResult":
						//if search is triggered we want to expand all
						if(leaf){
							/*var l = {x:node.x+node.w/2,y:node.y,w:node.w,h:node.h,d:node.d,padding:0,startDate:node.startDate,endDate:node.endDate,invisible:!e.value}
							setLayout(l);
							if(props.hasOwnProperty('onLayout')) props.onLayout(l,leaf)*/
							updateLayout(node.layout,expanded,hovered,searching.current,e.value,dimensions.current,hideRef.current,0,e.options||{})
							updateVisibility(e.value,false,searching.current,e.value,false,false,hideRef.current,0)
							selectable.current = e.value
						}else{
							if(level < 1) return
							updateLayout(node.layout,expanded,hovered,searching.current,e.value,dimensions.current,hideRef.current,0,e.options||{})
							updateVisibility(false,true,searching.current,e.value,false,false,false,0)
							selectable.current = false
							
							//setExpanded(true)
							
							//setFrontier(e.value)
						}
						isSearchResult.current = e.value
						break;
					case "dimensions":
						updateLayout(node.layout,expanded,hovered,searching.current,isSearchResult.current,e.value,hideRef.current,0,e.options||{})
						
						dimensions.current = e.value
						break;
					case "state":
						layoutState.current = e.value
						if(leaf && (e.value == 'initial' || e.value == 'rotateParticle')){
							hideRef.current = false
							selectable.current = false
						}else if(e.value == 'final'){
							hideRef.current = false
							if((leaf && !searching.current) || (leaf && searching.current && isSearchResult.current)) selectable.current = true
						}else if(e.value == 'hide'){
							hideRef.current = e.value2
						}
						//console.log("hello11", e.value, e.options)
						updateLayout(node.layout,expanded,hovered,searching.current,isSearchResult.current,dimensions.current,hideRef.current,e.delay||0,e.options||{})
						updateVisibility(selectable.current,frontier,searching.current,isSearchResult.current,hovered,level>0 && !leaf,hideRef.current,e.delay)
						

						

						
			  }
			
		  })
		  if(!node.mesh && timelineMesh){
			node.mesh = timelineMesh
			props.readySubject.next({node,isReady:true,level})
		  }
		  
		 
		  return ()=>sub.unsubscribe()
		  
	  },[searching,node,timelineMesh])
	
	  
/*
	  useEffect(()=>{
		  if(!leaf) return
		  var l = {x:node.x+node.w/2,y:node.y,w:node.w,h:node.h,d:node.d,padding:0,startDate:node.startDate,endDate:node.endDate}
				setColor(hexToRGB(node.color))
				if(props.hasOwnProperty('onLayout')) props.onLayout(l,leaf)


	  },[props.zoom])*/
	  
	 /* useEffect(() => {
		//console.log("onLayout override,",props.layout, nodeLayout, name);
		//if(updateCycle > 0) return
		if(props.layout == null && nodeLayout == null){
			setLayout({x:0,y:0,w:1,h:1,d:1})
		}else if(props.layout != null){
			//console.log(level,'sdfsdf',props.layout);
			//var isDifferent = props.layout.x != nodeLayout.x || props.layout.y != nodeLayout.y || props.layout.w != nodeLayout.w || props.layout.h != nodeLayout.h
			//if(!isDifferent) return 
			updateLayout(props.layout,expanded,hovered,searching.current,isSearchResult.current,dimensions.current,hideRef.current,0,{})
			setLayout(props.layout);
			
		}else{
			
			
			
		}
		//onHover(false)
		//updateVisibility(selectable,frontier,expanded,false)
	  }, [props.layout])*/
	
	 

	   {/*
			  //@ts-ignore */}
			  
			 // if(!node.layout) console.log("error node node",node);
		return  <a.group  frustumCulled={false} dispose={null} position={animatedProps.parentPosition} >
			
			  {/*
			  //@ts-ignore */}
			  <a.group ref={meshRef} position={animatedProps.position} scale={animatedProps.scale} rotation={animatedProps.rotation} >
				  <a.mesh 
					  ref={particleRef} 
					  position={animatedProps.particlePosition} 
					  scale={animatedProps.particleScale} 
					  rotation={animatedProps.particleRotation}
					  frustumCulled={false}   
					  onPointerOver={(e:any) => onHover(true,true)} 
					  onPointerOut={(e:any) => onHover(false,true)} 
					  onClick={(e:any)=>{onClick(e)}} 
					  dispose={null}>
					<boxBufferGeometry ref={boxGeometry} attach="geometry" args={[1,1,1]}></boxBufferGeometry>
     	   			<a.meshStandardMaterial attach="material" color={ animatedProps.meshColor} transparent={true} opacity={animatedProps.meshOpacity} depthTest={false} depthWrite={false}  />	
	  			</a.mesh>
				  {boxGeometry.current && <a.lineSegments frustumCulled={false} position={animatedProps.particlePosition} scale={animatedProps.particleScale} rotation={animatedProps.particleRotation} dispose={null}> 
	  	<edgesGeometry attach="geometry" args={[boxGeometry.current]} /> 
		  <a.lineBasicMaterial attach="material" color={ 'white'} transparent opacity={animatedProps.wireframeOpacity} depthWrite={false} />
      </a.lineSegments>}
			  </a.group>
      	
		
		
	  
	  {/*
		//@ts-ignore */}
	  

		{/*
		//@ts-ignore */}
	  
	  
	  <a.group position={animatedProps.innerTextPosition} rotation={animatedProps.innerTextRotation} scale={animatedProps.innerTextScale}>
	  <Text	
	  frustumCulled={false}
    	  fontSize={level<2? 4:3/*Math.max(1,Math.min(50/node.title.length,3))*/}
    		maxWidth={props.data.layout.h}
    		lineHeight={1.2}
    		letterSpacing={0}
    		textAlign="left"
          font={"./assets/lato-bold.woff"}
          anchorX="center"
		  anchorY="middle"
		  key={"nodeText"}
        >
			{node.title.toUpperCase()}
            <meshBasicMaterial  attach="material" color={'white'} opacity={(level==2 || level==1)?0.4:0.0}  depthWrite={false}/>
        </Text>
		
	  </a.group>

	  
		{/*
		//@ts-ignore */}
		<a.group position={animatedProps.outerTextPosition} rotation={animatedProps.outerTextRotation} scale={animatedProps.outerTextScale}>
		<Text 
    	  fontSize={level<2? 4:3}
    	  color="white"
    		maxWidth={props.data.layout.w*2}
    		lineHeight={1}
    		letterSpacing={0}
			textAlign="left"
			
          font={"./assets/lato-bold.woff"}
          anchorX={level==1?"center":"left"}
          anchorY={level==1?"top":"bottom"}
        >
			{node.title.toUpperCase()}
            <a.meshBasicMaterial attach="material" color={"white"} opacity={(level==2 || level==1)?0.4:0.0} depthWrite={false}/>
        </Text>
	</a.group>
		{/*
		//@ts-ignore */}
		<a.group  position={animatedProps.position}>
			  
		{root && <MouseCrossair layout={node.layout} hoverSubject={props.hoverSubject} openSubject={props.openSubject} flagSubject={props.flagSubject}/>}
			  </a.group>
			  {/*
		//@ts-ignore */}
			 <a.group  position={animatedProps.childGroupPosition}>
			 {/*
			  {childLayouts.map((childLayout:any, i:number) => {
				  var n = children[i]
				  return <Node 
					  key={n.id} 
					  zoom={zoom} 
					  data={n} 
					  children={n.children}
					  layout={childLayout} 
					  //focus={props.focus}
					  //parentExpanded={expanded}
					  //parentFrontier={frontier}
					  hovered={hovered} 
					  
					  //onLayout={(l:any,isLeaf:any)=>onLayout(i,l,isLeaf)} 
					  
					  currentHover={props.currentHover}

					  hoverSubject={props.hoverSubject}
					  openSubject={props.openSubject}
					  readySubject={props.readySubject}
					  editSubject={props.editSubject}
					  editMode={props.editMode}
					  flagSubject={props.flagSubject}
					  dateRange={props.dateRange}
					  zoomMode={props.zoomMode}
					  onRef={props.onRef}
					  ></Node>
				  
				}
				  )
			}*/}
				{/*
		//@ts-ignore */}
				<a.group frustumCulled={false} position={animatedProps.dateLinePosition} scale={animatedProps.dateLineScale}>
				{root && <DateBoxV2 dateRange={props.dateRange} layout={node.layout}></DateBoxV2>}
			</a.group>
			
			</a.group>
			
			</a.group>
		  
		  
		  
		

	
}

