export const SCRUB = "SCRUB";
export const ADD_COMMITEMNT = "ADD_COMMITEMNT";
export const ADD_TRACK = "ADD_TRACK"
export const ADD_COMMITEMNT_TRACK = "ADD_COMMITEMNT_TRACK"
export const SET_TIME_SCALE = "SET_TIME_SCALE"
export const COMMIT_TO_COMMITMENT = "COMMIT_TO_COMMITMENT"

export const ADD_CIRCLE = "ADD_CIRCLE"
export const EDIT_CIRCLE = "EDIT_CIRCLE"
export const REMOVE_CIRCLE = "REMOVE_CIRCLE"


export const SET_COMMITMENT = "SET_COMMITMENT"
export const SET_LOCATION = "SET_LOCATION"
export const SET_CIRCLE = "SET_CIRCLE"