import * as React from 'react'
import  { useEffect,useMemo} from 'react'
import Dropzone from 'react-dropzone'
import FirebaseImage from '../../firebase-image/firebase-image'
import { useSelector } from 'react-redux'
import { useFirebaseConnect, actionTypes,isLoaded, isEmpty, useFirestoreConnect,useFirestore,useFirebase } from 'react-redux-firebase'
import {useState} from 'react'
import uuidv1 from 'uuid'
import './firebase-image-upload.css'
import { BehaviorSubject } from 'rxjs'
import {BsFillTrashFill} from 'react-icons/bs'
// Create a reducer using the following action type:
export default function FirebaseImageUpload({values,name,collection,id,multiple}:any){
	const [images,setImages]:any = useState(values)
	const [tasks,setTasks]:any = useState([])
	const uploadTasks:any = useMemo(()=> new BehaviorSubject([]),[])
	const firebase:any = useFirebase()
	const firestore:any = useFirestore()
	const filesPath = "Files"

	
	const deleteFile=(path:any)=>{
		// Create a reference to the file to delete
		console.log("deleting",path)
		firebase.deleteFile(path).catch((err:any)=>console.log(err))
		
	  }
	  const deleteImage=(img:any,index:any)=>{
		  if(!img) throw "could not find image based on id"
		  if(img.pathLow) deleteFile(img.pathLow)
		  if(img.pathHigh) deleteFile(img.pathHigh)
		if(multiple){
			var imgs = [...images]
			imgs.splice(index,1)
			setImages(imgs)
			var obj:any = {}
			obj[name] = imgs
	  		firestore.collection(collection).doc(id).update(obj)
		}else{
			setImages([])
			var update:any = {
				
			}
			update[`${name}.pathLow`] = firebase.firestore.FieldValue.delete()
			update[`${name}.pathHigh`] = firebase.firestore.FieldValue.delete()
			firestore.collection(collection).doc(id).update(update)
		}
		 
	  }
	 
	const saveAdd=(image:any)=>{
		var obj:any = {}
		if(multiple){
			obj[name] = firebase.firestore.FieldValue.arrayUnion(image)
		}else{
			obj[name+'.pathLow'] = image.pathLow
			obj[name+'.pathHigh'] = image.pathHigh
		}
		console.log("kk",name,id,obj,collection)
	  	firestore.collection(collection).doc(id).update(obj)
	}
	  const compress=(files:any,width:any,fileName:string,filter:any)=> {
		
		var p = new Promise((resolve, reject) => {
		  
		const reader = new FileReader();
		reader.readAsDataURL(files[0]);
		  reader.onload = (event:any) => {
			const img = new Image();
			img.src = event.target.result;
			img.onload = function() {
					const elem = document.createElement('canvas');
					const scaleFactor = width / img.width;
					elem.width = width;
					elem.height = img.height * scaleFactor;
					const ctx:any = elem.getContext('2d');
					ctx.filter = filter
					ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);
					
					ctx.canvas.toBlob((blob:any) => {
						const file = new File([blob], fileName, {
							type:files[0].type,
							lastModified: Date.now()
						});
						resolve(file)
					}, files[0].type, 1);
				};
			};
			reader.onerror = error => console.log(error);
		})
		
		return p
	}
	const updateUploadTaskStatusById = (taskId:any,status:any)=>{
		const taskIndex = uploadTasks.value.findIndex((a:any)=>a.id == taskId)
			const task = {...uploadTasks.value[taskIndex]}
			task.status = status
			const newUploadTasks = [...uploadTasks.value]
			newUploadTasks[taskIndex] = task
			console.log("upload tasks", newUploadTasks)
			uploadTasks.next(newUploadTasks)
	}
	const removeUploadTaskById = (taskId:any)=>{
		const taskIndex = uploadTasks.value.findIndex((a:any)=>a.id == taskId)
		const newUploadTasks = [...uploadTasks.value]
		newUploadTasks.splice(taskIndex,1)
		uploadTasks.next(newUploadTasks)

	}
	const prepocess = async (e:any,id:any)=>{
  	  updateUploadTaskStatusById(id,"compressing") 
	  var coverHighRes = await compress(e,2160,id+'-highrescover','')
	  var coverLowRes = await compress(e,480,id+'-lowrescover','')
	  //var coverHighResBW = await compress(e,2160,'-highres-bw','grayscale(1) contrast(1.1)')
	  return [coverLowRes,coverHighRes]
		
	  
	}
	const upload = (files:any,taskId:any)=>{
		// Create a root reference
		console.log("upload begin")
		updateUploadTaskStatusById(taskId,"uploading")
		 
			var storageRef = firebase.storage().ref();
			var filePaths:any = []
		var promises = files.map((file:any,index:any) => {
			return new Promise((resolve:any,reject:any)=>{


			console.log("upload begin 2", index,file)
		// Create a reference to 'mountains.jpg'
		var filePath = `${filesPath}/${file.name}`
		filePaths.push(filePath)
		console.log("upload has path",filePath)
		var fileRef = storageRef.child(filePath)
		var uploadTask = fileRef.put(file)
		console.log("upload has task",uploadTask,fileRef)
		uploadTask.on('state_changed', (snapshot:any)=>{
			// Observe state change events such as progress, pause, and resume
			// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
			
			var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
			console.log("upload in progress",progress,snapshot)
			
			const taskIndex = uploadTasks.value.findIndex((a:any)=>a.id == taskId)
			const task = {...uploadTasks.value[taskIndex]}
			console.log("upload in progress task",progress,task)
			if(index == 0) task.progressLow = progress
			else if(index == 1) task.progressHigh = progress
			const newUploadTasks = [...uploadTasks.value]
			task.status = `${(Math.round(task.progressLow+task.progressHigh)/2)}%`
			newUploadTasks[taskIndex] = task
			uploadTasks.next(newUploadTasks)
			
		}, (error:any)=> {
			// Handle unsuccessful uploads
		  }, () =>{
			
				resolve(filePath)
				
		  })
		})
	});
		return Promise.all(promises)
	
	

	}
	const onFilesDrop = async (files:any) =>{
		if(files[0].type.indexOf('image') == -1) return
		console.log("image upload 1",files)
		var id = uuidv1()
		var fileUploadObject = {
			id:id,
			progressLow:0,
			progressHigh:0,
			status:'dropped..'
		}
		uploadTasks.next([...uploadTasks.value,fileUploadObject])
		var preprocessed_files = await prepocess(files,id)
		console.log("image upload 2",preprocessed_files)
		var paths:any = await upload(preprocessed_files,id)
		removeUploadTaskById(id)
		//save paths to the node
		var image = {
			pathLow:paths[0],
			pathHigh:paths[1],
			//highbw:coverHighResBWDownloadUrl
		  }
		  var newImages=[...images,image]
		setImages(newImages)
		saveAdd(image)
	  		 
	  }
	  useEffect(()=>{
		  
		  setImages([...values])
	  },[values])


	  useEffect(()=>{
		  var sub = uploadTasks.subscribe((tasks:any)=>{
			setTasks(tasks)
		  })
		  return ()=>sub.unsubscribe()
	  },[])


	  console.log("kk",images,tasks)

	return <div className={`firebase-image-upload ${!multiple? 'single':''}`}>
		
	<div className="image-grid">
	
	{images && 
	images.map((image:any,index:any)=>{
		return image.pathLow && <div className="section cover-image">
		<FirebaseImage path={image.pathLow}></FirebaseImage>
		<button className="deleteImageButton" onClick={()=>deleteImage(image,parseInt(index))}><BsFillTrashFill/></button>
		
	</div>})}
	{
	tasks.map((task:any,index:any)=>{
		return <div className="section upload-task">
			{task.status}
		
	</div>})}
	{((!multiple && tasks.length == 0 && images.length == 0) || (tasks.length == 0 && images.length > 0 && !("pathLow" in images[0])) || (tasks.length == 0 && images.length > 0 && !images[0])) &&
		<Dropzone onDrop={(f)=>onFilesDrop(f)}>
		{({getRootProps, getInputProps}) => (
		<section className="dropzone">
	  		<div {...getRootProps()}>
				<input {...getInputProps()} />
				<p>drop file</p>
  			</div>
		</section>
		)}
</Dropzone>
	}
	
	</div>
		

	
	</div>
}