import * as THREE from 'three'
import TWEEN from '@tweenjs/tween.js'
import { Quaternion, Vector3 } from 'three';


export function easeInOutCirc (x: number){
  return x < 0.5
    ? (1 - Math.sqrt(1 - Math.pow(2 * x, 2))) / 2
    : (Math.sqrt(1 - Math.pow(-2 * x + 2, 2)) + 1) / 2;
  }


export function hexToRGB(h:any) {
    let r:any = 0, g:any = 0, b:any = 0;
  
    // 3 digits
    if (h.length == 4) {
      r = "0x" + h[1] + h[1];
      g = "0x" + h[2] + h[2];
      b = "0x" + h[3] + h[3];
  
    // 6 digits
    } else if (h.length == 7) {
      r = "0x" + h[1] + h[2];
      g = "0x" + h[3] + h[4];
      b = "0x" + h[5] + h[6];
    }
    
    return "rgb("+ +r + "," + +g + "," + +b + ")";
  }


  export function argMax(array:any) {
    return array.map((x:any, i:any) => [x, i]).reduce((r:any, a:any) => (a[0] > r[0] ? a : r))[1];
  }
  /*export function argMax(array:any) {
    return array.map((x:any, i:any) => [x, i]).reduce((r:any, a:any) => (a[0] > r[0] ? a : r))[1];
  }*/


  export function LinearScale(domain:any,range:any){
    var istart = domain[0],
        istop  = domain[1],
        ostart = range[0],
        ostop  = range[1];
  
    return function scale(value:any) {
      return ostart + (ostop - ostart) * ((value - istart) / (istop - istart));
    }
  };
  export function worldToCamera(vector:any,camera:any){
      camera.updateMatrixWorld();
      vector.project(camera)
      return vector
  }
  export function worldToCameraCoords(pos:any, camera:any,renderer:any,parentEl:any) 
{ 
  //console.log(renderer.context.canvas,renderer.context)
//var top = renderer.context.canvas
var width = renderer.context.canvas.width, height = renderer.context.canvas.height;
var widthHalf = width / 2, heightHalf = height / 2;

pos.project(camera);
pos.x = ( pos.x * widthHalf ) + widthHalf;
pos.y = - ( pos.y * heightHalf ) + heightHalf;
return new THREE.Vector2(pos.x/window.devicePixelRatio + (parentEl? parentEl.offsetLeft:0),pos.y/window.devicePixelRatio + (parentEl? parentEl.offsetTop:0))
}
  export function cameraToWorldCoords(x:any,y:any,camera:any){
    var vector = new THREE.Vector3(x, y, camera.position.z);
    vector.unproject( camera );
    var dir = vector.sub( camera.position ).normalize();
    var distance = - camera.position.z / dir.z;
    var pos = camera.position.clone().add( dir.multiplyScalar( distance ) );
    //console.log(pos,distance,)
    return pos
  }

  export function screenToWorldCoords(clientX:any,clientY:any,camera:any,canvas:any){
    var cameraX = ( clientX / canvas.clientWidth ) * 2 - 1;
    var cameraY = - ( clientY / canvas.clientHeight ) * 2 + 1;
    return cameraToWorldCoords(cameraX,cameraY,camera)
  }
export function screenToCameraCoords(clientX:any,clientY:any,camera:any,canvas:any,plane:any){
  var cameraX = ( clientX / canvas.clientWidth ) * 2 - 1;
    var cameraY = - ( clientY / canvas.clientHeight ) * 2 + 1;
    return new THREE.Vector3(cameraX,cameraY,plane)
}

export function screenToWorld(x:any,y:any,camera:any,el:any,plane:any){
  var cameraVector = screenToCameraCoords(x,y,camera,el,plane)
	var worldCoords = new Vector3(cameraVector.x, cameraVector.y, cameraVector.z);
    worldCoords.unproject( camera );
  return worldCoords
}


export function rotateVectorAroundPivotPointWithEuler(point:any,pivotPoint:any,rotation:any){
  var vector:any = new Vector3(point[0],point[1],point[2])
  var pivot:any = new Vector3(pivotPoint[0],pivotPoint[1],pivotPoint[2])
  var object:any = new THREE.Object3D()
  var quart:any = object.quaternion
  object.rotation.set(rotation[0],rotation[1],rotation[2])
  vector.sub(pivot); // remove the offset
  vector.applyQuaternion(quart)//.appl applyAxisAngle(axis, theta); // rotate the POSITION
  vector.add(pivot); // re-add the offset
  return vector//[vector.x,vector.y,vector.z]
}
export function rotateVectorAroundPivotPoint(point:any,pivotPoint:any, quart:any){
 
  
  var vector:any = new Vector3(point[0],point[1],point[2])
  var pivot:any = new Vector3(pivotPoint[0],pivotPoint[1],pivotPoint[2])
  vector.sub(pivot); // remove the offset
  vector.applyQuaternion(quart)//.appl applyAxisAngle(axis, theta); // rotate the POSITION
  vector.add(pivot); // re-add the offset
  return vector//[vector.x,vector.y,vector.z]
}

export function computeBoundingBoxFromGroup (group:any){
  var bbox = new THREE.Box3().setFromObject(group);
  return bbox
}
//animation functions
export function animateValues(v1:any,v2:any,options:any){
  var start:any = v1
  var to:any = v2,
  easing = options.easing || TWEEN.Easing.Quadratic.InOut,
  duration = options.duration || 2000,
  delay = options.delay || 0
  var tweenOpacity = new TWEEN.Tween(start)
  .delay(delay)
        .to(to,duration)
        
        .easing(easing)
        .onUpdate((d)=> {
             if(options.update) options.update(d)
         })
         .onComplete(function(){
            if(options.callback) options.callback();
         });
    tweenOpacity.start();
    return tweenOpacity;
}
export function animateValue(v1:any,v2:any,options:any){
  var start:any = { value: v1}
  var to:any = { value: v2},
  easing = options.easing || TWEEN.Easing.Quadratic.InOut,
  duration = options.duration || 2000,
  delay = options.delay || 0
  var tweenOpacity = new TWEEN.Tween(start)
  .delay(delay)
        .to(to,duration)
        
        .easing(easing)
        .onUpdate((d)=> {
             if(options.update) options.update(d)
         })
         .onComplete(function(){
            if(options.callback) options.callback();
         });
    tweenOpacity.start();
    return tweenOpacity;
}
export function animateVector3(vectorToAnimate:any, target:any, options:any){
  options = options || {};
  // get targets from options or set to defaults
  var to = target || new THREE.Vector3(),
      easing = options.easing || TWEEN.Easing.Quadratic.In,
      duration = options.duration || 2000;
  // create the tween
  var tweenVector3 = new TWEEN.Tween(vectorToAnimate)
      .to({ x: to.x, y: to.y, z: to.z, }, duration)
      .easing(easing)
      .onUpdate(function(d) {
        //console.log("tween update: ",d)
          if(options.update){ 
            options.update(d)
          }
       })
      .onComplete(function(){
        if(options.callback) options.callback();
      });
  // start the tween
  tweenVector3.start();
  // return the tween in case we want to manipulate it later on
  return tweenVector3;
}
export var animations:any = {

}
export var animationCategories:any = {
  pos:0,
  rotate:0,
  position:0,
  opacity:0
}
export async function animateObjectToState(obj:any,state:any,duration:any,randomDelayMax:any,recursive:boolean){
  var randomDelay = Math.random()*randomDelayMax
  var promises = []
  var objHasState = typeof state == 'string' && 'userData' in obj && 'states' in obj.userData && state in obj.userData.states
  var stateIsTemp = typeof state == 'object'
  var newState = stateIsTemp? state:objHasState? obj.userData.states[state]:null
  if (newState){
    if('pos' in newState){
      var p1 = new Promise((resolve:any,reject:any)=>{
        if(obj.id + '-pos' in animations){
          //console.log('hello' ,animations[obj.id + '-pos'])
          animations[obj.id + '-pos'].stop()
          delete animations[obj.id + '-pos']
          animationCategories.pos--;
        }
        if(obj.position.x == newState.pos.x && obj.position.y == newState.pos.y && obj.position.z == newState.pos.z) return resolve()
        animationCategories.pos++;
        animations[obj.id + '-pos'] = animateVector3(obj.position, newState.pos.clone(), {
          duration: duration + randomDelay, 
          easing : TWEEN.Easing.Exponential.InOut,
          update: null,
          callback : function(){
              delete animations[obj.id + '-pos']
              //console.log("Completed");
              animationCategories.pos--;
              resolve()
          }
      });
      })
        promises.push(p1)
      }
      if('scale' in newState){
        var p2 = new Promise((resolve,reject)=>{
          if(obj.id + '-scale' in animations){
            animations[obj.id + '-scale'].stop()
            delete animations[obj.id + '-scale']
            animationCategories.scale--
            if(obj.scale.x == newState.scale.x && obj.scale.y == newState.scale.y && obj.scale.z == newState.scale.z) return resolve()  
          }
          
          animationCategories.scale++
          animations[obj.id + '-scale'] = animateVector3(obj.scale, newState.scale.clone(), {
            duration: duration + randomDelay, 
            easing : TWEEN.Easing.Exponential.InOut,
            update: null,
            callback : function(){
                delete animations[obj.id + '-scale']
                animationCategories.scale--
                resolve()
            }
        });
        })
        promises.push(p2)
      }
      if('rotate' in newState){
        console.log("rotation: ",obj.rotation)
        
        var p = new Promise((resolve,reject)=>{
          if(obj.id + '-rotate' in animations){
            animations[obj.id + '-rotate'].stop()
            delete animations[obj.id + '-scale']
            animationCategories.rotate--
          }
          if(obj.rotation.x == newState.rotate.x && obj.rotation.y == newState.rotate.y && obj.rotation.z == newState.rotate.z)return resolve()
          animationCategories.rotate++
          animations[obj.id + '-rotate'] = animateVector3(obj.rotation, newState.rotate.clone(), {
            duration: duration + randomDelay, 
            easing : TWEEN.Easing.Exponential.InOut,
            update: null,
            callback : function(){
                console.log("Completed");
                delete animations[obj.id + '-rotate']
                animationCategories.rotate--
                resolve()
            }
        });
        })
        promises.push(p)
      }
      if('opacity' in newState){
        if('material' in obj){
          var p = new Promise((resolve,reject)=>{
          var currentOpacity:any = 'uniforms' in obj.material? obj.material.uniforms.opacity.value:obj.material.opacity
          if(obj.id + '-opacity' in animations && currentOpacity != newState.opacity){
             animations[obj.id + '-opacity'].stop()
             delete animations[obj.id + '-opacity']
             animationCategories.opacity--
          }
          if(currentOpacity == newState.opacity) return resolve()
          animationCategories.opacity++
          animations[obj.id + '-opacity'] = animateValue(currentOpacity,newState.opacity,{
            duration:duration,
            delay:Math.random()*randomDelayMax,
            update:(d:any)=>{
              obj.material.opacity = d.value
              if('uniforms' in obj.material && 'opacity' in obj.material.uniforms){
                obj.material.uniforms.opacity.value =  d.value
              }else{
                obj.material.opacity = d.value
              }
            },
            callback:function(){
              delete animations[obj.id + '-opacity']
              animationCategories.opacity--
              resolve()
            }
          
        })
      });
        promises.push(p)
        }
      }
      if('color' in newState){
        if('material' in obj){
          if('uniforms' in obj.material && 'color' in obj.material.uniforms){
            //obj.material.uniforms.color.value = newState.color
            //obj.geometry.update({color:'white'})
          }else{
            obj.material.color.set(newState.color)
          }
        }
      }
      if('show' in newState){
        if('visible' in obj){
          obj.visible = newState.show
        }
      }
      
  }
  
  if(recursive){
    obj.children.forEach((obj:any)=>{
      promises.push(animateObjectToState(obj,state,duration,randomDelayMax,recursive))
    })
  }
  return Promise.all(promises)
}
export async function animateObjectsToState(objs:any,state:any,duration:any,randomDelayMax:any,recursive:boolean){
  var promises = objs.map((obj:any)=>{
    return animateObjectToState(obj,state,duration,randomDelayMax,recursive)
  });
  return Promise.all(promises)
}










export function getTextHeight(text:any,width:any,size:any,tag:any,lineHeight:any,weight:any){
  var shadowElement = document.createElement(tag)
  shadowElement.innerHTML = text
  shadowElement.style.cssText = 'font-size:'+ size + 'px; width:'+width+'px; line-height:'+lineHeight+'; font-weight:'+weight+'; position:absolute; display:block; margin:0px;'
  document.body.appendChild(shadowElement);
  var height = shadowElement.clientHeight
  //console.log("shadowElement",shadowElement,height)
  shadowElement.remove()
  return height
}






var matchItem = function(string:any, data:any) {
  var i = 0,
      j = 0,
      html = '',
      regex,
      regexv,
      match,
      matches,
      version;
  
  for (i = 0; i < data.length; i += 1) {
      regex = new RegExp(data[i].value, 'i');
      match = regex.test(string);
      if (match) {
          regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
          matches = string.match(regexv);
          version = '';
          if (matches) { if (matches[1]) { matches = matches[1]; } }
          if (matches) {
              matches = matches.split(/[._]+/);
              for (j = 0; j < matches.length; j += 1) {
                  if (j === 0) {
                      version += matches[j] + '.';
                  } else {
                      version += matches[j];
                  }
              }
          } else {
              version = '0';
          }
          return {
              name: data[i].name,
              version: parseFloat(version)
          };
      }
  }
  return { name: 'unknown', version: 0 };
}


var dataos = [
        { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
        { name: 'Windows', value: 'Win', version: 'NT' },
        { name: 'iPhone', value: 'iPhone', version: 'OS' },
        { name: 'iPad', value: 'iPad', version: 'OS' },
        { name: 'Kindle', value: 'Silk', version: 'Silk' },
        { name: 'Android', value: 'Android', version: 'Android' },
        { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
        { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
        { name: 'Macintosh', value: 'Mac', version: 'OS X' },
        { name: 'Linux', value: 'Linux', version: 'rv' },
        { name: 'Palm', value: 'Palm', version: 'PalmOS' }
    ]
    var databrowser = [
        { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
        { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
        { name: 'Safari', value: 'Safari', version: 'Version' },
        { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
        { name: 'Opera', value: 'Opera', version: 'Opera' },
        { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
        { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
    ]
export function getBrowserInfo(){
  var header=  [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor]
    var agent = header.join(' ')
    var os = matchItem(agent, dataos)
   var  browser = matchItem(agent, databrowser);
    console.log(os,browser)
    return{os,browser}
}




