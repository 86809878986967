import * as React from 'react'
import  { useEffect } from 'react'
import Dropzone from 'react-dropzone'
import FirebaseImage from '../../firebase-image/firebase-image'
import { useSelector } from 'react-redux'
import { useFirebaseConnect, isLoaded, isEmpty, useFirestoreConnect,useFirestore,useFirebase } from 'react-redux-firebase'
import {useState} from 'react'
import uuidv1 from 'uuid'
import './firebase-input.css'
import {BiCheckDouble, BiCheck} from 'react-icons/bi'
import { stringify } from 'querystring'
export default function FirebaseInput(props:any){
	
	const firestore:any =  useFirestore()
	const format:any = {
		"date":(text:any)=>{
	  return new Date(text) || new Date()
  
	},
	  "array":(text:any)=>{
	  return text.split(props.formatOptions.seperator)
	},
	"string":(text:any)=>{
	  return text
	},
	"color":(text:any)=>{
	  return text
	}
  }

  const flatten:any = {
	  "date":(date:any)=>{
	return date.toISOString().split('T')[0]

  },
	"array":(array:any)=>{
	return array.join(props.formatOptions.seperator)
  },
  "string":(text:any)=>{
	return text
  },
  "color":(color:any)=>{
	return color
  },
  "boolean":(b:any)=>{return b}
}
	const valueType:any = Array.isArray(props.value)?"array": props.value instanceof Date?"date":props.type == "color"?"color":"string"
	const [value, setValue] = useState(flatten[valueType](props.value));
	const [changed,setChanged]:any = useState(false)
	const [saveTimer,setSaveTimer]:any = useState(null)
	
	 


	const handleChange= async (e:any)=>{
		
		var v = props.valueToAssign? props.valueToAssign:(typeof e) == "boolean"? e:e.target.value
		//console.log("hello",e,e.target.value,v)
		setChanged(true)
		setValue(v);
		console.log("after set value",value)
		
		scheduleSave(v)
	  }
	  
	  
	 
	  
	  const scheduleSave = (v:any)=>{
		if(saveTimer) clearTimeout(saveTimer)
		setSaveTimer(setTimeout(()=>{
		  save(v)
		  setSaveTimer(null)
		},props.saveDelay != null? props.saveDelay:2000))
	  }
	  const save=(v:any)=>{
		  var obj:any = {}
		  obj[props.name] = format[valueType](v)
		  console.log(obj)
		firestore.collection(props.collection).doc(props.id).update(obj)
		setChanged(false)
	  }




	  
	
	  
	
	
	
	  













	return (<div className="firebase-input">
		{props.type == "button" && <button className={`optionButton ${value? "selected":""}`} onClick={(e)=>handleChange(!value)}>{props.children}</button>}
	  		{props.type != 'textarea' && props.type != 'button' && <input type={props.type} placeholder={props.placeholder} value={value} onChange={(e)=>handleChange(e)}/>}
		  {props.type == 'textarea' && <textarea placeholder={props.placeholder} value={value} onChange={(e)=>handleChange(e)}/>}
		  <div className={"savedCheck" + (props.type == 'button'?" small":"")}>
		  {changed && <BiCheck></BiCheck>}
		  {!changed && <BiCheckDouble></BiCheckDouble>}
		  </div>
		  
	  </div>)
}
