import * as React from 'react'
import {useEffect,useState} from 'react'
import './logo.css'
export default function IntroText({flagSubject}:any){
	const [hide,setHide]:any = useState(true)
	const [isIsland,setIsland]:any = useState(false)
	useEffect(()=>{
		var sub = flagSubject.subscribe(({entered,loaded,minimize,fullscreen}:any)=>{
			setHide(!entered || !loaded || fullscreen || minimize)
			//setIsland()
		})
		return ()=>{
			sub.unsubscribe()
		}
	},[flagSubject])
	return ( 
		<div className={`logo ${hide?'hide':''} ${isIsland?'island':''}`}>
			<div className="logo-text">
			<h2>TIMELINE</h2>
			<p>by Vincent Jonsson Qi</p>
			</div>
			
			
		</div>)
}