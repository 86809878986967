import * as React from 'react'
import {useEffect,useState} from 'react'
import { VscClose } from 'react-icons/vsc'
import {useSpring,a,config} from 'react-spring'
import "./close-button.css"
export default function CloseButton ({flagSubject,onClick}:any){
   const [hide,setHide]:any = useState(true)
   const [isSearching,setSearching]:any = useState(false)
	var props:any = useSpring({
      opacity:hide?0.0:1.0,
      transform:hide?"translate3d(0px,10px,0px) scale(1.0)":"translate3d(0px,0px,0px) scale(1.0)",
      pointerEvents:hide?"none":"all",
      native:true,
      config:config.wobbly
   })
   useEffect(()=>{
		var sub = flagSubject.subscribe(({entered,loaded,minimize,fullscreen,searching}:any)=>{
         setHide(!entered || !loaded || fullscreen || !minimize)
         if(searching != isSearching) setSearching(searching)
		})
		return ()=>{
			sub.unsubscribe()
		}
	},[flagSubject])
	return <a.button style={props} className="close-button" onClick={()=>{onClick()}}>
                   <VscClose/>
                </a.button>
}