import * as React from 'react'
import  { useEffect,useMemo} from 'react'
import Dropzone from 'react-dropzone'
import FirebaseImage from '../../firebase-image/firebase-image'
import { useSelector } from 'react-redux'
import { useFirebaseConnect, actionTypes,isLoaded, isEmpty, useFirestoreConnect,useFirestore,useFirebase } from 'react-redux-firebase'
import {useState} from 'react'
import uuidv1 from 'uuid'
import './firebase-film-upload.css'
import { BehaviorSubject } from 'rxjs'
import {BsFillTrashFill} from 'react-icons/bs'
import FirebaseFilm from '../../firebase-film/firebase-film'
const gifshot = require('gifshot')
// Create a reducer using the following action type:
export default function FirebaseFilmUpload({value,name,collection,id,createPreview}:any){
	const [film,setFilm]:any = useState(value)
	const [tasks,setTasks]:any = useState([])
	const uploadTasks:any = useMemo(()=> new BehaviorSubject([]),[])
	const firebase:any = useFirebase()
	const firestore:any = useFirestore()
	const filesPath = "Files"

	
	const deleteFile=(path:any)=>{
		// Create a reference to the file to delete
		console.log("deleting",path)
		firebase.deleteFile(path).catch((err:any)=>console.log(err))
		
	  }
	  const deleteVideo=()=>{
		  if(!film) throw "could not find video based on id"
		  if(film.pathFilm) deleteFile(film.pathFilm)
			var update:any = {}
		  update[`${name}.pathFilm`] = firebase.firestore.FieldValue.delete()
		  firestore.collection(collection).doc(id).update(update)
	  }
	  const save=(value:any)=>{
		var obj:any = {}
		obj[name+'.pathFilm'] = value
	  	firestore.collection(collection).doc(id).update(obj)
	}
	
	const updateUploadTaskStatusById = (taskId:any,status:any)=>{
		const taskIndex = uploadTasks.value.findIndex((a:any)=>a.id == taskId)
			const task = {...uploadTasks.value[taskIndex]}
			task.status = status
			const newUploadTasks = [...uploadTasks.value]
			newUploadTasks[taskIndex] = task
			console.log("upload tasks", newUploadTasks)
			uploadTasks.next(newUploadTasks)
	}
	const removeUploadTaskById = (taskId:any)=>{
		const taskIndex = uploadTasks.value.findIndex((a:any)=>a.id == taskId)
		const newUploadTasks = [...uploadTasks.value]
		newUploadTasks.splice(taskIndex,1)
		uploadTasks.next(newUploadTasks)

	}
	
	const upload = (files:any,taskId:any)=>{
		// Create a root reference
		console.log("upload begin")
		updateUploadTaskStatusById(taskId,"uploading")
		 
			var storageRef = firebase.storage().ref();
			var filePaths:any = []
		var promises = files.map((file:any,index:any) => {
			return new Promise((resolve:any,reject:any)=>{


			console.log("upload begin 2", index,file)
		// Create a reference to 'mountains.jpg'
		var filePath = `${filesPath}/${file.name}`
		filePaths.push(filePath)
		console.log("upload has path",filePath)
		var fileRef = storageRef.child(filePath)
		var uploadTask = fileRef.put(file)
		console.log("upload has task",uploadTask,fileRef)
		uploadTask.on('state_changed', (snapshot:any)=>{
			// Observe state change events such as progress, pause, and resume
			// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
			
			var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
			
			const taskIndex = uploadTasks.value.findIndex((a:any)=>a.id == taskId)
			const task = {...uploadTasks.value[taskIndex]}
			if(index == 0) task.progressFilm = progress
			else if(index == 1) task.progressGIF = progress
			const newUploadTasks = [...uploadTasks.value]
			task.status = `${(Math.round(task.progressFilm+task.progressGIF)/2)}%`
			newUploadTasks[taskIndex] = task
			uploadTasks.next(newUploadTasks)
			
		}, (error:any)=> {
			// Handle unsuccessful uploads
		  }, () =>{
			
				resolve(filePath)
				
		  })
		})
	});
		return Promise.all(promises)
	
	

	}
	/*const createGIF= async (video:any,taskId:any)=>{
		console.log("video",video)
		return new Promise((resolve:any,reject:any)=>{
			updateUploadTaskStatusById(taskId,"Making Preview")
		gifshot.createGIF({
			gifWidth: 400,
			gifHeight: 250,
			video: [video.path],
			interval: 1,
			numFrames: 10,
			frameDuration: 1,
			sampleInterval: 10,
			numWorkers: 2,
			progressCallback:(progress:any)=>{
				updateUploadTaskStatusById(taskId,`Preview: ${progress}`)
			}
		},  (obj:any)=> {
			console.log("gifshot callback",obj)
			if (!obj.error) {
				resolve(obj.image)
				
			}else{
				
			}
		});
		}).catch((err)=>{
			console.log(err)
		})
		
	}*/
	const onFilesDrop = async (files:any) =>{
		if(files[0].type.indexOf('video') == -1) return
		
		console.log("film upload 1",files)
		var id = uuidv1()
		var fileUploadObject = {
			id:id,
			progressFilm:0,
			progressGIF:createPreview?0:1,
			status:'dropped..'
		}
		uploadTasks.next([...uploadTasks.value,fileUploadObject])
		var filesToUpload = [files[0]]
		
		//only attach gif if needed
		/*if(createPreview){
			var gif = await createGIF(files[0],id)
			filesToUpload.push(gif)
		}
		*/
		var paths:any = await upload(filesToUpload,id)
		removeUploadTaskById(id)
		//save paths to the node
		var data = {
			pathFilm:paths[0],
			//pathGIF:paths[1],
			//highbw:coverHighResBWDownloadUrl
		  }
		  
		setFilm(data)
		save(paths[0])
	  		 
	  }
	  useEffect(()=>{
		  setFilm(value)
	  },[value])


	  useEffect(()=>{
		  var sub = uploadTasks.subscribe((tasks:any)=>{
			setTasks(tasks)
		  })
		  return ()=>sub.unsubscribe()
	  },[])



	return <div className={`firebase-film-upload`}>
		
		
	  {film && film.pathFilm && <div className="filmPreview">

		  <FirebaseFilm path={film.pathFilm}/>
		  <button onClick={()=>deleteVideo()}><BsFillTrashFill/></button>
		  </div>}

	{
	tasks.map((task:any,index:any)=>{
		return <div className="section upload-task">
			{task.status}
		
	</div>})}
	{tasks.length == 0 && (!film || !film.pathFilm) &&
		<Dropzone onDrop={(f)=>onFilesDrop(f)}>
		{({getRootProps, getInputProps}) => (
		<section className="dropzone">
	  		<div {...getRootProps()}>
				<input {...getInputProps()} />
				<p>drop file</p>
  			</div>
		</section>
		)}
</Dropzone>
	}
	
		

	
	</div>
}