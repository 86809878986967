import * as React from 'react'

import { Canvas, useFrame,extend,useThree } from 'react-three-fiber'
import { useRef, useState,useMemo, useEffect,Suspense } from 'react'
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore' // <- needed if using firestore
import {ReactReduxFirebaseProvider, ReduxFirestoreProvider,useFirebase} from 'react-redux-firebase'
import {ReactReduxContext,Provider} from 'react-redux'
import {useHistory} from 'react-router'
import { LinearScale, animateObjectToState, animateObjectsToState, worldToCameraCoords,easeInOutCirc ,getTextHeight} from '../helperFunctions'
import './timeline.css'


 
import {Subject,BehaviorSubject} from 'rxjs'
import { BiCube } from 'react-icons/bi'
import { CgShapeSquare } from 'react-icons/cg'
import CameraControls from './camera-controls.component';
import Tree from './tree.component'
import ContentManager from './content/content-manager.component'


import SearchInput from './search-input/search-input.component'
import NodeList from './node-list/node-list.component'
import CloseButton from './close-button/close-button.component'
import NextBar from './next-bar/next-bar.component'
import NodeLine from './node-line.component'
import Logo from './logo/logo.component'
import IntroText from './intro-text/intro-text.component'
import DimensionButton from './dimension-button/dimension-button.component'
//import * as SearchWorker from 
import DataWorker from './worker.shim'



let dataService = DataWorker()















export default function Timeline(props:any) {

    
    //CONSTANTS
    //first we begin by attaching additional information that our tree constructor needs to create the tree datastructure with the proper layout
	//createTreeDataStructure(props.nodes,props.edges,props.dateRange),[props.nodes,props.dateRange]);
    var [root,setRoot]:any = useState(null)
    var [nodes,setNodes]:any = useState([])
    var basePath = props.editMode?'/admin-timeline':''
    var tree:any = useRef(null)
    //var [leafs,setLeafs]:any = useState([])
    //var [leafsWithContent,setLeafsWithContent]:any = useState([])
    var [dateRange,setDateRange]:any = useState(null)
    //console.log("root",root)

    //var {gl,camera}:any =useThree()

    //const [levels,setLevels]:any = useState([{node:root,mesh:null}])
    //const [childlNodes,setChildNodes]:any = useState(leafsWithContent)
    //const [loaded,setLoaded]:any = useState(false)
    //const [entered,setEntered]:any = useState(false)
    //const [minimize,setMinimize]:any = useState(false)
    //const [fullscreen,setFullscreen]:any = useState(false)
    //const [searching,setSearching]:any = useState(false)
    //const [currentIndex,setCurrentIndex]:any = useState(0)
    //const [currentHover,setCurrentHover]:any = useState(null)
    
    //const nodeStatusSubject:any = useMemo(()=> new BehaviorSubject({}),[])
    const readySubject:any = useMemo(()=> new BehaviorSubject(null),[])
    const hoverSubject:any = useMemo(()=> new BehaviorSubject(null),[])
    const zoomSubject:any = useMemo(()=> new BehaviorSubject(0),[])
    const openSubject:any = useMemo(()=> new BehaviorSubject(undefined),[])
    const nodeListSubject:any = useMemo(()=>new BehaviorSubject(null),[])
    const operateSubject:any = useMemo(()=> new BehaviorSubject({type:null,args:null,options:{}}),[])
    const flagSubject:any = useMemo(()=> new BehaviorSubject({entered:false,minimize:false,loaded:false,fullscreen:false,searching:false,currentIndex:0,totalCount:0,dimensions:3,status:'Loading'}),[])
    const nodesSubject:any = useMemo(()=>new BehaviorSubject({currentLeafsWithContent:[],currentLeafs:[]}),[])
    const timers:any = useRef([])
    const ready:any = useRef(false)
    const currentState:any = useRef('loading')
    const currentPathTimeout:any = useRef(null)
    //const [zoom,setZoom]:any = useState(0)
    const timelineStatus:any = useRef({})
    

    const history = useHistory();
    /*
    
    const updateNodeList=(ns:any)=>{
        setChildNodes(ns)
       
    }
*/

   
  
    /*const onKeyUp = (keyCode:number)=>{
        console.log("keyCODE",keyCode)
        switch(keyCode){
            case 27:
                if(levels.length > 1){
                    console.log("hej svejs")
                    popZoomLevel(levels[levels.length-2].node.level)
                }
                break;
        }
    }*/

    useEffect(()=>{
        (async ()=>{
        console.log(dataService)
        flagSubject.next({...flagSubject.value,status:"Processing"})
        var data = await dataService.load(props.nodes,props.edges)
        console.log("data",data.nodes,data.index)
                flagSubject.next({...flagSubject.value,status:"Building 3D assets"})
                data.nodes.forEach((doc:any)=>{
                    doc.subject = new Subject()
                    doc.parent = doc.parent? data.nodes[data.index[doc.parent]]:null
                    doc.listItemHeight = getTextHeight(doc.title.toUpperCase(),150,13,'h2',1.1,900) + getTextHeight(doc.subheadline,150,12,'p',1.1,700) + 105
                })
                
                setRoot(data.root)
                setNodes(data.nodes)
                tree.current = data
               
                
                data.dateRange.dateToX = LinearScale(data.dateRange.dates, data.dateRange.widths)
		        data.dateRange.xToDate = LinearScale(data.dateRange.widths, data.dateRange.dates)
                setDateRange(data.dateRange)

                //data.nodes.forEach(()=>{})
        
        })()
    },[props.nodes,props.edges])
    const changeDimensions=(d:any)=>{
        flagSubject.next({...flagSubject.value,dimensions:d})
        setTimeout(()=>{
            tree.current.nodes.forEach((n:any)=>{
                n.subject.next({
                    action:"dimensions",
                    value:d
                })
            })
        },500)
        
    }
    const search = (searchInput:any)=>{
        (async ()=>{
            flagSubject.next({...flagSubject.value,searching:true,searchTerm:searchInput})
            var data = await dataService.search(searchInput)        
            console.log("worker message",data)
            if(!data) return
            if(flagSubject.value.searching){
                var matchesWithContent:any = []
                var matches:any = []
                    data.forEach((r:any)=>{
                        var n = getNodeById(r.id)
                        if(!n) return
                        if(r.isSearchResult&&n.leaf) matches.push(n)
                        if(r.isSearchResult&&n.leaf&&n.contentCount && n.contentCount>0) matchesWithContent.push(n)
                        n.subject.next({
                            action:'searchResult',
                            value:r.isSearchResult
                        })
                    })
                    nodesSubject.next({currentLeafs:matches,currentLeafsWithContent:matchesWithContent})
                    operateSubject.next({type:"fitNodes",args:[matches,0,0],options:{
                        enabled:true,
                        azimuthRotateSpeed:1.0,
                        polarRotateSpeed:1.0,
                        truckSpeed:0.05,
                        dampingFactor:0.05
                    }})
                
                
            }
        })()
        
    
        
        
    }
    const openSearch=(searchTerm:any)=>{
        flagSubject.next({...flagSubject.value,searching:true,searchTerm})
        
        tree.current.nodes.forEach((n:any)=>{
            n.subject.next({
                action:"search",
                value:true
            })
        })
        search(searchTerm)
        


    }


    const closeSearch=()=>{
       
        flagSubject.next({...flagSubject.value,searching:false,searchTerm:""})
        nodesSubject.next({currentLeafs:tree.current.leafs,currentLeafsWithContent:tree.current.leafsWithContent})
        operateSubject.next({
            type:"fitNodes",
            args:[tree.current.leafs,4],
            options:{
                enabled:true,
                maxPolarAngle:Math.PI,
                minAzimuthAngle:-Infinity,
                maxAzimuthAngle:Infinity,
                azimuthRotateSpeed:0.0,
                polarRotateSpeed:0.0,
                dampingFactor:0.02
            }
        })
        tree.current.nodes.forEach((n:any)=>{
            n.subject.next({
                action:"searchResult",
                value:false
            })
        })
        tree.current.nodes.forEach((n:any)=>{
            n.subject.next({
                action:"search",
                value:false
            })
        })
    }
    
    
    
    useEffect(()=>{
        //props.onZoomUpdate(levels)
        var sub = hoverSubject.subscribe((e:any)=>{
            console.log("on hover subject update",e)
            if(!e) return
            var n = e.n
            var h = e.h
            console.log("on hover subject update",n,h)
            //onNodeHover(n,h)
        })
        var openSub = openSubject.subscribe((e:any)=>{   
            console.log("openSubHello")
            if(e) history.push(basePath+'/projects/'+e.id)
            else if(e != undefined){
                if(flagSubject.value.searching) history.push(`${basePath}/search/${flagSubject.value.searchTerm}`)
                else history.push(basePath)
            }
        })
        var readySub = readySubject.subscribe((e:any)=>{
            if(!e) return
            timelineStatus.current[e.node.id] = e.isReady
            var timelineReady = Object.keys(timelineStatus.current).length == nodes.length
            console.log("timelineStatus",timelineStatus,timelineReady,nodes.length,Object.keys(timelineStatus.current).length,e.level)
            
            if(timelineReady){
                flagSubject.next({...flagSubject.value,status:"Post Processing"})
                console.log("timelineStatus","COMPLETE")
                ready.current = true
                fromRouteToState(history.location)
                
            }
            
        })

        
        var historySub = history.listen((e:any)=>{
            fromRouteToState(e)
        });
        return ()=>{
            sub.unsubscribe()
            openSub.unsubscribe()
            readySub.unsubscribe()
        }
    },[nodes])
    const getNodeById=(id:any)=>{
        var nodeIndex = tree.current? tree.current.index:null
        var index = nodeIndex && nodeIndex[id]
        return tree.current?  tree.current.nodes[index]:null
    }
    const fromRouteToState = (change:any)=>{
        console.log("from router to state lets go")
        clearTimers()
        /*if(props.editMode){
            openTimeline()
            return
        }*/
        if(!ready.current) return
        var urlDecomposition = change.pathname.split("/")
        if(urlDecomposition.length > 1 && urlDecomposition[1] == 'admin-timeline') urlDecomposition.splice(1,1)
        var action = urlDecomposition.length > 1 && urlDecomposition[1]
        var param1 = urlDecomposition.length > 2? urlDecomposition[2]:""
        console.log("history change",change,urlDecomposition,action,param1)
        var paramIsWorthyIfEntered = param1.length > 0//((openSubject.value && openSubject.value.id != param1) || (!openSubject.value && param1.length > 0))
        var paramIsWorthyOnLoad = param1.length > 0
        if(action == 'projects' && ready.current && paramIsWorthyIfEntered && flagSubject.value.loaded && flagSubject.value.entered) {
            var match = getNodeById(param1)
            console.log("switch node",match)
            flagSubject.next({...flagSubject.value,entered:true,loaded:true})
            if(match) openNode(match)
            currentState.current = "project"
        }else if(action == "" && openSubject.value && ready.current && flagSubject.value.loaded && flagSubject.value.entered){
            //flagSubject.next({...flagSubject.value,loaded:true})
            closeNode()
            currentState.current = "timeline"
        }else if(action == "" && currentState.current == 'loading' && props.editMode){
            nodesSubject.next({currentLeafs:tree.current.leafs,currentLeafsWithContent:tree.current.leafsWithContent})
            openTimeline()
            currentState.current = "intro"
        }else if(action == "" && currentState.current == 'loading' && !props.editMode){
            nodesSubject.next({currentLeafs:tree.current.leafs,currentLeafsWithContent:tree.current.leafsWithContent})
            openIntroMessage()
            currentState.current = "intro"
        }else if(action == "" && currentState.current == 'search'){
            closeSearch()
            currentState.current = "timeline"
        }else if(action == 'projects' && ready.current && !flagSubject.value.entered && param1.length > 0 &&paramIsWorthyOnLoad ){
                var node = getNodeById(param1)
                if(node){
                    nodesSubject.next({currentLeafs:tree.current.leafs,currentLeafsWithContent:tree.current.leafsWithContent})
                    tree.current.nodes.forEach((n:any)=>{
                        n.subject.next({
                            action:'state',
                            value:'origin',
                            options:{
                                meshOpacity:0.0,
                                immediate:true,
                            }
                        })
                        n.subject.next({
                            action:'state',
                            value:'hide',
                            value2:true,
                            options:{
                                immediate:true,
                            }
                        })
                     
                    })
                   
                        operateSubject.next({
                            type:'unlock',
                            args:[true],
                            options:{
                                dampingFactor:0.03
                            }
                        })
                        flagSubject.next({...flagSubject.value,entered:true,loaded:true})
                        openNode(node)
                        currentState.current = 'project'
                    }else{
                        history.push(basePath)
                    }
                    
        }else if(action == 'search' && ready.current && currentState.current != 'searching' && (param1==""||param1!=flagSubject.value.searchTerm||currentState.current == 'project')){
            if(currentState.current == 'project') closeNode()
            if(!flagSubject.value.entered) openToSearch(param1)
            else search(param1)
            currentState.current = "search"
            
        }
    }
    const openToSearch=(searchTerm:any)=>{
        tree.current.nodes.forEach((n:any)=>{
            n.subject.next({
                action:'state',
                value:'final',
                options:{
                    immediate:true
                }
            })
            n.subject.next({
                action:'state',
                value:'hide',
                value2:true,
                options:{
                    immediate:true
                }
            })
        })
        flagSubject.next({...flagSubject.value,entered:true,loaded:true,status:'Searching'})
        setTimeout(()=>{

            
            
            operateSubject.next({
                type:'unlock',
                args:[true,false],
                options:{
                    dampingFactor:0.03
                }
            })
            operateSubject.next({
                type:'offsetStateNoReset',
                args:[props.device],
                options:{
                    dampingFactor:0.07
                }
            })
           
            nodeListSubject.next({
                action:'open',
                value:true
            })
            
            tree.current.nodes.forEach((n:any)=>{
            
                n.subject.next({
                    action:'state',
                    value:'hide',
                    value2:false,
                    options:{
                        immediate:true
                    }
                })
            })
            openSearch(searchTerm)
        },500)
        
        
        
        
        
    }
    const openIntroMessage=()=>{
        console.log("openIntroMessage")
        tree.current.nodes.forEach((n:any)=>{
            n.subject.next({
                action:'state',
                value:'hide',
                value2:true,
                options:{
                    immediate:true
                }
            })
            
           
        })
        setTimeout(()=>{
            tree.current.nodes.forEach((n:any)=>{
                
                n.subject.next({
                    action:'state',
                    value:'rotateParticle',
                    options:{
                        config:{friction:10,tension:220,mass:10}
                    }
                })
               
            })
        },500)
        setTimeout(()=>{
            tree.current.nodes.forEach((n:any)=>{
                n.subject.next({
                    action:'state',
                    value:props.editMode?'final':'initial'
                })
            })
            setTimeout(()=>{
            flagSubject.next({...flagSubject.value,loaded:true,status:""})
            },700)
            
        },2000)
    }
    const openNode=(e:any)=>{
        if(openSubject.value && e.id != openSubject.value.id || !openSubject.value) openSubject.next(e)
        console.log("node req open request",e)
        
            //this place is for opening a leaf node and its content
            /**
                event format
                node,mesh
            */
           if(!e) return
           
           nodeListSubject.next({
            action:'open',
            value:false
        })
        operateSubject.next({
            type:'offsetStateNoReset',
            args:['initial'],
            options:{
            
            }
        })
           
           if(e) {
                
               var nodeIndex:any = nodesSubject.value.currentLeafsWithContent.findIndex((n:any)=>{return n.id == e.id})
               flagSubject.next({...flagSubject.value,currentIndex:nodeIndex,entered:true,loaded:true})
                if(flagSubject.value.minimize) return
                tree.current.nodes.forEach((n:any)=>{
                    if(e.id == n.id) return
                n.subject.next({
                    action:"state",
                    value:'hide',
                    value2:true,
                    delay:Math.random()*500,
                    options:{
                        config:{friction:30,tension:180,mass:3}
                    }
                })
                })
                flagSubject.next({...flagSubject.value,minimize:true})
                
            }
    }
    const clearTimers:any = ()=>{
        timers.current.forEach((t:any)=>{
            clearTimeout(t)
        })
    }
    const closeNode=()=>{
        console.log("node req close request")
        if(openSubject.value) openSubject.next(null)
        nodeListSubject.next({
            action:'open',
            value:true
        })
        
        flagSubject.next({...flagSubject.value,minimize:false})
        
           
        setTimeout(()=>{
            operateSubject.next({
            type:'offsetStateNoReset',
            args:[props.device],
            options:{
                
            }
         })
        operateSubject.next({
            type:'unlock',
            args:[true,false],
            options:{
                dampingFactor:0.03
            }
        })
        },500)
         
        
             
        
    }
    const onContentReady=(contentState:any)=>{
        if(contentState=='close'){
            tree.current.nodes.forEach((n:any)=>{
                n.subject.next({
                    action:"state",
                value:'hide',
                value2:false,
                    delay:300+Math.random()*500,
                    options:{
                        config:{friction:30,tension:180,mass:1}
                    }
                })
                
                n.subject.next({
                    action:"state",
                    value:'final',
                    delay:500+Math.random()*500
                })
                })
               
                
            setTimeout(()=>{
                flagSubject.next({...flagSubject.value,minimize:false})
            }   ,1200)    
                  
        }else if(contentState == "contentRetrieved"){
            var node = openSubject.value
            if(node) node.subject.next({
                action:"state",
                value:'hide',
                value2:true,
                delay:0
            })
        }
        
    }
    const switchNode=(index:any)=>{
        //setState("switch")
        //this only runs if we have already opened a node and wish to switch to another
        if(!props.editMode) history.push(basePath + '/projects/'+nodesSubject.value.currentLeafsWithContent[index].id)
        //openSubject.next(childlNodes[index])
    }
    const openTimeline=()=>{
        
        flagSubject.next({...flagSubject.value,loaded:false})
        
            
            if(!props.editMode){
                tree.current.nodes.forEach((n:any)=>{
                n.subject.next({
                    action:"state",
                    value:"particle",
                    options:{
                        config:{friction:10,tension:100,mass:5}
                    }
                })
                })
               
            }
            
        timers.current.push(setTimeout(()=>{
            
           
           
            tree.current.leafs.forEach((n:any)=>{
                n.subject.next({
                    action:"state",
                    value:"pre-final",
                    options:{
                        config:{friction:30,tension:30,mass:5}
                    }
                })
                })
                flagSubject.next({...flagSubject.value,entered:true,loaded:true})
        },props.editMode? 0:500))
        timers.current.push(setTimeout(()=>{
            nodeListSubject.next({
                action:'open',
                value:true
            })
            
            tree.current.nodes.forEach((n:any)=>{
                n.subject.next({
                    action:"state",
                    value:"pre-final",
                    options:{
                        config:{friction:50,tension:220,mass:2}
                    }
                })
                })
              
        },props.editMode? 0:1000))
        setTimeout(()=>{
            tree.current.nodes.forEach((n:any)=>{
                n.subject.next({
                    action:"state",
                    value:"final",
                    
                })
                })
        },1700)
        timers.current.push(setTimeout(()=>{
            operateSubject.next({
                type:'unlock',
                args:[true],
                options:{
                    dampingFactor:0.1
                }
            })
            operateSubject.next({
                type:'offsetStateNoReset',
                args:[props.device],
                options:{
                    dampingFactor:0.1
                }
            })
            operateSubject.next({
                type:"fitNodes",
                args:[[tree.current.root],4],
                options:{
                    enabled:true,
                    maxPolarAngle:Math.PI,
                    minAzimuthAngle:-Infinity,
                    maxAzimuthAngle:Infinity,
                    azimuthRotateSpeed:0.0,
                    polarRotateSpeed:0.0,
                    dampingFactor:0.1
                }
            })
            
                
        },props.editMode? 0:2400))
    }
    const onCloseSearchClick=()=>{
        history.push(basePath)
    }
    const onCloseButtonClick=()=>{
        
            if(flagSubject.value.searching) history.push(`${basePath}/search/${flagSubject.value.searchTerm}`)
            else history.push(basePath)
        
    }
    const onSearch=(e:any)=>{
        history.push(`${basePath}/search/${e}`)
    }
    return (

            <div className="timeline" >
                <Canvas 
                    
                    
                      
                    orthographic
                
                    pixelRatio={window.devicePixelRatio}
                    camera={{ position: [-300, 300, 300], zoom: 5 ,rotation:[0,0,0]}}>
                        
                         {/*<mesh>
                           <boxGeometry attach={"geometry"} args={[1,1,1]}></boxGeometry>
                           <meshBasicMaterial attach={"material"} color="white"></meshBasicMaterial>
                         </mesh>*/}
                        
                       {nodes.length > 0 && dateRange && <Tree  
                            nodes={nodes} 
                            dateRange={dateRange} 
                             
                            //focus={levels} 
                            openSubject={openSubject}
                            hoverSubject={hoverSubject}
                            readySubject={readySubject}
                            editSubject={props.editSubject}
                            editMode={props.editMode}
                            flagSubject={flagSubject}
                            >
                     
                                <pointLight position={[200, 300, -300]} color={"rgb(255, 255, 255)"} />
                        <pointLight position={[-200, 300, 300]} color={"rgb(100, 100, 100)"} />
                        <pointLight position={[-200, 300, 300]} color={"rgb(255, 255, 255)"} intensity={1.0} />
                        
                            </Tree>  }
                       
                        <ContentManager firebase={props.firebase} firestore={props.firestore} openSubject={openSubject} editMode={props.editMode} transformMode={props.transformMode} flagSubject={flagSubject}  onReady={(contentState:any)=>{onContentReady(contentState)}} operateSubject={operateSubject}></ContentManager>
                        
                        <NodeLine subject={hoverSubject}/>
                        
                        <CameraControls root={root} device={props.device} nodesSubject={nodesSubject} operateSubject={operateSubject} openSubject={openSubject} zoomSubject={zoomSubject} flagSubject={flagSubject} editMode={props.editMode}/>
                        
                </Canvas>
               
               <IntroText flagSubject={flagSubject} onOpen={()=>openTimeline()} device={props.device}></IntroText>
                <Logo flagSubject={flagSubject}></Logo>
               
                    <div className={`nodelist`} >
                        <NodeList nodesSubject={nodesSubject} hoverSubject={hoverSubject} openSubject={openSubject} nodeListSubject={nodeListSubject} vertical={props.device=='desktop'}></NodeList>
                    </div>
                
                <SearchInput onSearch={(e:any)=>onSearch(e)} flagSubject={flagSubject} onOpen={()=>openSearch("")} onClose={()=>onCloseSearchClick()} delay={600}></SearchInput>
                <CloseButton onClick={()=>{onCloseButtonClick()}} flagSubject={flagSubject}/>
                 <DimensionButton editMode={props.editMode} device={props.device} onClick={(e:any)=>changeDimensions(e)} flagSubject={flagSubject}></DimensionButton>
                <NextBar next={(e:any)=>{switchNode(e)}} previous={(e:any)=>switchNode(e)} flagSubject={flagSubject} nodesSubject={nodesSubject}></NextBar>
                   
            </div>
        )
    
}

/*

function ForwardCanvas({ children,...props }:any) {
    const firebase:any = useFirebase()
    const rrfConfig = {
        userProfile: 'users',
        // useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
        }
    
    var nested:never[] = children
        return <ReactReduxContext.Consumer>
        {({ store }:any) => {
            var rrfProps:any = {
                firebase,
                config: rrfConfig,
                dispatch: store.dispatch,
                createFirestoreInstance // <- needed if using firestore
                }
                return <Canvas
          {...props}>
            <Provider store={store}>
                {/*
                @ts-ignore }
                <ReactReduxFirebaseProvider {...rrfProps}>
                {nested}
               </ReactReduxFirebaseProvider>
            </Provider>
          </Canvas>
    }}
    </ReactReduxContext.Consumer>
      
    
  }*/
  