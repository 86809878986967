import * as React from 'react';
import {useEffect,useState} from 'react'
import {useParams} from 'react-router'
import './home.css'

import {  useSelector } from 'react-redux'
import {  useFirestore, useFirestoreConnect, isLoaded, useFirebase } from 'react-redux-firebase'


import Timeline from './timeline/timeline.component'

export default function Home(props:any){
  const graphId:any = "SpacetimeTimeline"
	/*useFirestoreConnect(() => [
		{ collection: 'Graphs', doc: graphId } // or `todos/${props.todoId}`
		,{collection:'Nodes', where: [["graphId", "==", graphId]]}
		,{collection:'Edges', where: [["graphId", "==", graphId]]}
	  ])*/
  const firestore:any =  useFirestore()
  const firebase:any = useFirebase()
  //const graph = useSelector((state:any) => state.firestore.data.Graphs && state.firestore.data.Graphs[graphId])
  const zoomMode = "map"

	const [nodes,setNodes] = useState([])//useSelector((state:any) => state.firestore.ordered.Nodes)
  const [edges,setEdges] = useState([])//useSelector((state:any) => state.firestore.ordered.Edges)
  

  useEffect(()=>{
    var edgesCollectionRef = firestore.collection('Edges')
    var nodesCollectionRef = firestore.collection('Nodes')
    var unsubscribeToEdgeUpdates = edgesCollectionRef.where("graphId", "==", graphId).onSnapshot((querySnapshot:any)=>{
      const docs:any = []
      querySnapshot.forEach((d:any)=>{docs.push(d.data())})
      console.log("edges",docs)
      setEdges(docs)
      unsubscribeToEdgeUpdates()
    })

    var unsubscribeToNodeUpdates = nodesCollectionRef.where("graphId", "==", graphId).onSnapshot((querySnapshot:any)=>{
      const docs:any = []
      querySnapshot.forEach((d:any)=>{docs.push(d.data())})
      console.log("nodes",docs)
      setNodes(docs)
      unsubscribeToNodeUpdates()

    })

    return ()=>{
      unsubscribeToEdgeUpdates()
      unsubscribeToNodeUpdates()
    }
  },[])

  const resize=(width:any,height:any)=>{
    var d:any;
    console.log("resize event",width,height)
    if(width < 700) d = 'mobile'
    else d = 'desktop'
    return d
  }
  const [device,setDevice] = useState(resize(window.innerWidth,window.innerHeight))
  useEffect(()=>{
      
    window.addEventListener('resize', (e:any)=>{
      console.log("resize event",e)
      setDevice(resize(e.srcElement.innerWidth,e.srcElement.innerHeight))
    })
  },[])
  if (!isLoaded(nodes) && !isLoaded(edges)) {
		return <div>Loading...</div>
	  }
	
	  
	  const onZoomUpdate = async (ls:any)=>{
    }
    
  return <div className="home">
			{nodes.length > 0 && edges && <Timeline nodes={nodes} edges={edges} firebase={firebase} firestore={firestore} zoomMode={zoomMode} onZoomUpdate={(levels:any)=>onZoomUpdate(levels)} device={device}/>}
			</div> 
}
/*
class Home extends React.Component<any, any>  {
  mount:any;
  prevent(e:any){
    e.preventDefault();
  }
  props:any;
  state:any = {
    currentIndex:0,
    current:null,
    currentDate:new Date(),
    transitionProgress:0,
    showDateFilter:false,
    showLocationList:false
  }
  async componentDidMount(){
    //load fonts
    var fonts = await initFonts()
    this.setState({
      fonts
    })
    window.addEventListener('resize',()=>{
      this.resize()
    })
    this.resize()
  }
  onScrollChange(commitment:any,index:any){
    console.log(commitment,index)
    var location = this.props.locations.find((location:any)=>{
      return commitment.circleId == location.circleId
    })
    let currentDate = new Date((commitment.start.toDate().getTime() +commitment.end.toDate().getTime() )/2)
    this.setState({current:commitment,location:location,currentDate,currentIndex:index})
  }
  
  timelineChange(date:any,commitment:any){
    console.log(date,commitment)
    if(commitment){
      var location = this.props.locations.find((location:any)=>{
        return commitment.circleId == location.circleId
      })
      var index = this.props.commitments.findIndex((c:any)=>{
        return commitment.id == c.id
      })
      this.setState({currentDate:date,current:commitment,location,currentIndex:index})
    }else{
      this.setState({currentDate:date})
    }
    
    
  }
  locationQuery(location:any){
    
  }
  searchQuery(searchText:any){

  }
  resize(){
    var width = this.mount.offsetWidth
    var height = this.mount.offsetHeight
    var device = width < 640 || height < 640? 'mobile':'mobile'
    var orientation = width > height? 'landscape':'portrait'
    var direction = device == 'mobile'?'horisontal':'vertical'
    this.setState({
      width,
      height,
      device,
      orientation,
      direction
    })
  }
  openDateFilter(){
    this.setState({showDateFilter:true})
  }
  onCommitmentClick(c:any,i:any){
    this.setState({showDateFilter:false})
  }
  public render() {
    console.log("rendering home")
    return (
      <div className="home" 
        ref={(ref:any)=>this.mount = ref}>
          
        <div className={`timelineContainer ${this.state.showDateFilter?'show':''}`}>
        {this.props.commitments && 
          <Timeline 
            
            nodes={this.props.commitments}></Timeline>
           
        }
        
        </div>
        
        {this.props.commitments &&  <CommitmentList 
          commitments={this.props.commitments} 
          onChange={(current:any,index:any)=>{this.onScrollChange(current,index)}}
          onCommitmentTransition={(progress:any)=>{this.setState({transitionProgress:progress})}}
          onCommitmentClick={(c:any,i:any)=>this.onCommitmentClick(c,i)}
          showDateList={this.state.showDateFilter}
          showLocationList={this.state.showLocationList}
          device={this.state.device}
          currentCommitmentIndex={this.state.currentIndex}
          currentDate={this.state.currentDate}
          currentLocation={this.state.location}>
      </CommitmentList>}

           {this.state.current && 
            <div className={`navContainer ${this.state.showDateFilter || this.state.showLocationList? 'hide':''}`}>
              <Navigation 
            commitment={this.state.current} 
            location={this.state.location} 
            transitionProgress={this.state.transitionProgress}
            onDateClick={()=>this.openDateFilter()}
            ></Navigation>
              </div>
            }
          {this.state.current && <h2 className={`logo`} style={{color: this.state.showDateFilter?'white':this.state.current.color}}>VINCENT SPACETIME</h2>}
      </div>
    
    );
  }
}

export default compose(
  firestoreConnect(() => ['Static', 'Locations', 'Commitments']), // or { collection: 'todos' }
  connect((state: any, props) => {
    var cs = state.firestore.ordered.Commitments ? state.firestore.ordered.Commitments : null
    console.log(cs)
    if(cs){
      cs = cs.map((value:any,index:any)=>{
        var doc = {...value}
        return doc
      })
      cs.sort((a:any,b:any)=>{
        return (b.start.toDate().getTime()+ b.end.toDate().getTime())/2-(a.start.toDate().getTime()+ a.end.toDate().getTime())/2
      })
      cs = cs.map((value:any,index:any)=>{
        var doc = {...value,projectNumber:index}
        return doc
      })
      
    }
    
    return {
      // circles:state.firestore.ordered.Circles,

      commitments: cs,
      locations: state.firestore.ordered.Locations ? state.firestore.ordered.Locations : null,
      //circles: state.firestore.ordered.Static && state.firestore.ordered.Static[0] ? state.firestore.ordered.Static[0].circles : null,
      title: state.firestore.ordered.Static ? state.firestore.ordered.Static[1] : { id: 'title', title: 'Test' }
    }
  }, null)
)(Home) as React.ComponentType

  */