// redux/actions.js
import { ADD_COMMITEMNT,SCRUB,ADD_TRACK,ADD_COMMITEMNT_TRACK,SET_TIME_SCALE,COMMIT_TO_COMMITMENT, SET_COMMITMENT,SET_LOCATION, SET_CIRCLE } from './actionTypes'
import uuidv1 from 'uuid/v1'


export const addCommitment = (content:any) => ({
  type: ADD_COMMITEMNT,
  payload: content
})
export const scrub = (date:Date) => ({
    type: SCRUB,
    payload: date
  })

  export const addTrack = (content:any) => ({
    type: ADD_TRACK,
    payload: content
  })

  export const addCommitmentTrack = (id:any,trackId:any) => ({
    type: ADD_COMMITEMNT_TRACK,
    payload: {
        id:id,
        trackId:trackId
    }
  })

  export const setTimeScale = (scale:any) =>({
    type:SET_TIME_SCALE,
    payload:scale
  })
  
  export const commitToCommitment = (commitmentId:any) => ({
    type:COMMIT_TO_COMMITMENT,
    payload:commitmentId
  })






  //ADMIN STUFF
  export const setCommitment = (commitmentId:any) => ({
    type:SET_COMMITMENT,
    payload:commitmentId
  })
  export const setLocation = (locationId:any) => ({
    type:SET_LOCATION,
    payload:locationId
  })

  export const setCircle = (circleId:any) => ({
    type:SET_CIRCLE,
    payload:circleId
  })