import { SET_COMMITMENT,SET_LOCATION,SET_CIRCLE} from "../actionTypes";
import uuidv1 from 'uuid/v1'

const initialState = {
    locationId:null,
    commitmentId:null,
    circleId:null
}
export default function admin(state:any = initialState, action:any) {
    switch (action.type) {
        case SET_LOCATION:
            var locationId = action.payload
            return {...state,locationId:locationId}
        case SET_COMMITMENT:
            var commitmentId = action.payload
            return {...state,commitmentId:commitmentId}
        case SET_CIRCLE:
            var circleId = action.payload
            return {...state,circleId:circleId}
        default:
            return {...state}
    }
}
