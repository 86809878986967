
import * as THREE from 'three'
import {useMemo} from 'react'
import { Canvas, useFrame,extend,useThree } from 'react-three-fiber'
import * as React from 'react'
import {a, useSpring} from 'react-spring/three'
import {Text} from 'drei'
function getDateText(dates:any,dateRange:any){
	var text = dates.reverse().map((dateLayout:any,i:any)=>{
		return dateLayout.text + (i < dates.length-1?"\n":"")
	})
	var date1 = new Date()
	var date2 = new Date()
	date2.setDate(31)
	date1.setDate(1)
	//date1.setMonth(date1.getMonth()-1)
	var x1 = dateRange.dateToX(date1)
	var x2 = dateRange.dateToX(date2)
	var delta = (x2-x1)
	console.log("month delta", delta)
	return {dateText:text,delta}
}
function getDateGeometry(dates:any){
	var boxGeometry:any = new THREE.BoxGeometry(0.001,1,1)
	const meshes = new THREE.Geometry()
	const lineMeshes:any = new THREE.Geometry()
	dates.forEach((date:any) => {
		const mesh = new THREE.Mesh(boxGeometry)
		mesh.position.set(date.x,0,0)
		mesh.updateMatrix();
		meshes.merge(mesh.geometry as THREE.Geometry, mesh.matrix)
		if(date.lineOpacity == 0.0) return

		const edge = new THREE.Mesh(boxGeometry)
		edge.position.set(date.x,0,0)
		edge.updateMatrix();
		lineMeshes.merge(edge.geometry as THREE.Geometry, edge.matrix);
		
	});
	
	const edges = new THREE.EdgesGeometry(lineMeshes)
	return {edges,meshes,edgeMeshes:lineMeshes}
}
var getDateLines = (startDate:Date,endDate:Date,increment:string,dateRange:any) => {
	var dates = []
	if(!startDate || !endDate) return []
	var currentDate:Date = new Date(startDate.getFullYear(),0,1)
	endDate = new Date(endDate.getFullYear()+1,0,1)
	while (endDate > currentDate) {

		var currentDateCopy:any;
		switch(increment){
			case 'month':
				currentDate = new Date(currentDate.setDate(1))
			 	currentDateCopy = new Date(currentDate.setDate(1))
				currentDate.setMonth(currentDate.getMonth() + 1)
				
				break;
			case 'year':
				currentDate = new Date(currentDate.getFullYear(), 0, 1)
				currentDateCopy = new Date(currentDate.getFullYear(), 0, 1)
				currentDate.setFullYear(currentDate.getFullYear() + 1)
				break;
			case 'date':
				currentDate.setDate(currentDate.getDate() + 1)
				break;
			default:
				throw "incrementor not supported by getDateLines method"
		}
		var yearStart:any = currentDateCopy.getMonth() == 0 || dates.length == 0 || currentDate >= endDate
const lineOpacity:any = yearStart? 0.04:0.0
const meshOpacity:any = !yearStart? 0.0:0.01
var dateString = currentDateCopy.toDateString()
var month = dateString.split(' ')[1]
var day = dateString.split(' ')[2]
var year = dateString.split(' ')[3]
var text = (increment=="month"? month:"") + " " + (yearStart ? year:"")
text = text.toUpperCase()
		dates.push({
			x:dateRange.dateToX(currentDateCopy),
			text:text,
			lineOpacity:lineOpacity,
			meshOpacity:meshOpacity,
			fontSize:yearStart? 2:1,
			textOpacity:yearStart? 1:0.5,
			date:new Date(currentDateCopy.getTime())
		})
	}
	return dates
}

export default function DateBoxV2({dateRange,layout}:any){
	
	
	const dates:any = useMemo(()=>getDateLines(layout.startDate,layout.endDate,'year',dateRange),[layout,dateRange])
	const {edges,meshes,edgeMeshes}:any = useMemo(()=>getDateGeometry(dates),[dates])


	const animatedProps:any = useSpring({
		scale:[1,0.000001,layout.h+layout.padding+10],
		datePosition:[layout.x,0 ,layout.h/2+5],
		meshOpacity:0.01,
		lineOpacity:0.02,
		dateScale:[1,1,1]
	})

	return <a.group>
		{/* 
		@ts-ignore */}
		<a.group scale={animatedProps.scale} position={[layout.x,0,0]} >
			<a.mesh geometry={meshes}>
				<a.meshStandardMaterial attach="material" color="white" transparent opacity={animatedProps.meshOpacity} depthTest={false} depthWrite={false} />
			</a.mesh>
			<a.mesh geometry={edgeMeshes}>
				<a.meshStandardMaterial attach="material" color="white" transparent opacity={animatedProps.lineOpacity} depthTest={false} depthWrite={false} />
			</a.mesh>
			<a.lineSegments geometry={edges}>
				<a.lineBasicMaterial attach="material" color="white" transparent opacity={animatedProps.lineOpacity} depthWrite={false} />
			</a.lineSegments>
		</a.group>
		{/* 
		@ts-ignore */}
		<a.group position={animatedProps.datePosition} scale={animatedProps.dateScale}>
			{dates && dates.map((date:any)=>{
				return <Text	
					key={date.date.toString()}
					position={[date.x,0,5]}
					fontSize={4}
					rotation={[-Math.PI/2,0,0]}
					color="white"
					  maxWidth={300}
					  lineHeight={1}
					  letterSpacing={0}
					  textAlign="center"
					font={"https://cdn.jsdelivr.net/npm/lato-font@3.0.0/fonts/lato-bold/lato-bold.woff"}
					anchorX="center"
					anchorY="middle"
				  >
					  {date.text}
					  <meshBasicMaterial attach="material" color={"white"} opacity={0.5}  depthWrite={false}/>
				  </Text>
			})}
		
		</a.group>
		</a.group>
	
}