import * as React from 'react'
import  { useEffect } from 'react'
import Dropzone from 'react-dropzone'
import FirebaseImage from '../../firebase-image/firebase-image'
import FirebaseImageUpload from '../firebase-image-upload/firebase-image-upload.component'
import { useSelector } from 'react-redux'
import { useFirebaseConnect, isLoaded, isEmpty, useFirestoreConnect,useFirestore,useFirebase } from 'react-redux-firebase'
import {useState} from 'react'
import uuidv1 from 'uuid/v1'
import './node-form.css'
import FirebaseInput from '../firebase-input/firebase-input.component'
import { BsFillTrashFill } from 'react-icons/bs'
import { CgFormatText } from 'react-icons/cg'
import { AiFillCamera, AiFillVideoCamera } from 'react-icons/ai'
export default function NodeForm({id,onContentSelect}:any){
	useFirestoreConnect(() => [
		{ collection: 'Nodes', doc: id,storeAs:"adminNodeDetail" }, // or `todos/${props.todoId}`
		{collection:'Contents', where:["nodeId","==",id], storeAs:"adminNodeContentList"},
		{collection:'Edges', where:["parentId","==",id], storeAs:"adminChildrenList"}
	  ])
	const firestore:any =  useFirestore()
	const firebase:any = useFirebase()
	var node = useSelector((state:any) => {
		console.log("sdfsf",state.firestore.data.adminNodeDetail,id)
		var n = state.firestore.data.adminNodeDetail
		if(!n) return
		var d:any = {...n}
		d.start = d.start.toDate()
		d.end = d.end.toDate()
		return d
	})
	var contentList = useSelector((state:any)=>{
		console.log("state.firestore.data.adminNodeContentList",state.firestore.ordered.adminNodeContentList)
		return state.firestore.ordered.adminNodeContentList
	})

	var isLeaf = useSelector((state:any)=>{
		return state.firestore.ordered.adminChildrenList && state.firestore.ordered.adminChildrenList.length == 0
	})
	
	
	
	  if(isEmpty(node) || !isLoaded(node) || !isLoaded(contentList)){
		  return <div>not loaded</div>
	  }
	  
	
	var deleteContent = (contentId:any,e:any)=>{
		e.stopPropagation()
		firestore.collection("Nodes").doc(id).update({
			contentCount:  contentList? contentList.length-1:0
		})
		var docRef = firestore.collection("Contents").doc(contentId)
		docRef.delete()
		
	}
	const fontUrls = {
		bold:"https://cdn.jsdelivr.net/npm/lato-font@3.0.0/fonts/lato-bold/lato-bold.woff",
		bolditalic:"https://cdn.jsdelivr.net/npm/lato-font@3.0.0/fonts/lato-bold-italic/lato-bold-italic.woff",
		italic:"https://cdn.jsdelivr.net/npm/lato-font@3.0.0/fonts/lato-normal-italic/lato-normal-italic.woff",
		normal:"https://cdn.jsdelivr.net/npm/lato-font@3.0.0/fonts/lato-normal/lato-normal.woff"
	}
	var addContent = async (contentType:any)=>{
		var contentId = uuidv1()
		var doc:any = {
			id:contentId,
			nodeId:id,
			type: contentType,
			source: null,
			style:{
				
			},
			geometry:{
				position:[0,0,0],
				scale:[20,15,1],
				rotation:[0,0,0]
			}
			
		}
		if(contentType){
			doc.style.fontUrls = fontUrls
			doc.style.color = "white"
			doc.style.lineHeight = 20
			doc.style.letterSpacing = 0
			doc.style.bold = false
			doc.style.italic = false
			doc.style.textAlign = "center"
			doc.style.fontSize = 2
		}
		firestore.collection("Nodes").doc(id).update({
			contentCount: contentList? contentList.length+1:0
		})
		var docRef = await firestore.collection('Contents').doc(contentId).set(doc)
		
		
		
	}



	  













	return <div className="node-form" key={node.id}>
	<div className="section">
		
	  

	  <FirebaseInput type="text" placeholder="title of titles" value={node.title} name="title" id={node.id} collection="Nodes"  formatOptions={{}}/>
	  <FirebaseInput type="text" placeholder="Main Category" value={node.category && node.category} name="category" id={node.id} collection="Nodes"  formatOptions={{}}/>
	  <FirebaseInput type="text" placeholder="tags" value={node.tags} name="tags" id={node.id}  collection="Nodes"formatOptions={{seperator:', '}}/>
	  <FirebaseInput type="textarea" placeholder="write a short subheadline about this commitment" value={node.subheadline} name="subheadline" id={node.id} collection="Nodes"  formatOptions={{}} />
	  <FirebaseInput type="text" placeholder="Youtube URL" value={node.youtubeUrl} name="youtubeUrl" id={node.id} collection="Nodes" formatOptions={{}}/>
	  <FirebaseInput type="date" value={node.start} name="start" id={node.id} collection="Nodes"/>
	  <FirebaseInput type="date" value={node.end} name="end" id={node.id}  collection="Nodes"/>
	  
	  <div className="color-input">
	  <FirebaseInput type="color" name="color" value={node.color} id={node.id} collection="Nodes"/>
	  <FirebaseInput type="color" name="backgroundColor" value={node.backgroundColor} id={node.id} collection="Nodes"/>
	  </div>
	  {isLeaf && <div className="section contentList">
	  <div className="addContentButtons" >
			  <p>Content</p>
			  <button onClick={()=>addContent("text")}> <CgFormatText/></button>
			  <button onClick={()=>addContent("film")}><AiFillVideoCamera/></button>
			  <button onClick={()=>addContent("photo")}><AiFillCamera/></button>
			  </div>
	  		{contentList && contentList.map((content:any)=>{
				  return <div key={content.id} className="contentListItem" onClick={()=>{onContentSelect(content)}}>
					  {content.type == 'text'?  <CgFormatText/>:content.type== 'film'?<AiFillVideoCamera/>:<AiFillCamera/>}
					  
			  		{content.type=='text' && <p>{content.source && content.source.text}</p>}
					  {content.type=='photo' && content.source && <div className="imagePreview"><FirebaseImage path={content.source.pathLow}></FirebaseImage></div>}
				  		<button className="deleteImageButton" onClick={(e:any)=>deleteContent(content.id,e)}><BsFillTrashFill/></button>
				  </div>
			  })}
			 
			
	  </div>
	  
			}
		</div>

  </div>
}