import * as React from 'react'
import { useState ,useEffect} from 'react';
import { useFirebase } from 'react-redux-firebase'
import './firebase-film.css'
var cache:any  = {}
//@ts-ignore
export default function FirebaseFilm({path}){
  const firebase = useFirebase()
  var [url,setUrl] = useState('')

  function downloadImageFromPath(path:any){
    var ref = firebase.storage().ref()
    return ref.child(path).getDownloadURL().then((downloadUrl:any)=> {
      // `url` is the download URL for 'images/stars.jpg'
      setUrl(downloadUrl)
      cache[path] = downloadUrl 
      
    }).catch(function(error) {
      // Handle any errors
    });
  }
  useEffect(()=>{
    downloadImageFromPath(path)
  },[path])
  
  

 
  
  
  //console.log("rendering firebaseimage")
    return (
      <span>
        <div className="firebase-film">
          
          {url && (<video  width="320" height="240" controls>
            <source src={url} type="video/mp4"/>
            </video>)
            }
          
        </div>
      </span>
      
    );
}

  