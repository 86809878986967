import { TransformControls } from 'drei'
import * as React from 'react'
import {useRef,useEffect,useState} from 'react'
import {useFirestore} from 'react-redux-firebase'
import {useFrame, useThree } from 'react-three-fiber'
import {Vector3} from 'three'
import {useSpring} from 'react-spring/three'
export default function ContentTransform ({content,transformMode,firestore}:any){
	var transformRef = useRef()
	var {camera,gl}:any = useThree()

	var [geometry,setGeoemtry]:any = useState({
			position:[0,0,0],
			scale:[1,1,1],
			rotation:[0,0,0]
		})

	const updateContentGeometry=(position:any,scale:any,rotation:any,save:any)=>{
		var data = {...content}
		data.geometry = {position,scale,rotation}
		//update(data,true)
		//@ts-ignore
		if(transformRef.current) transformRef.current.update()
			if(save){
				firestore.collection("Contents").doc(content.id).update({
				geometry:{
					position,scale,rotation
				}
			})}
		
		
		
	}
	const onControlChange = (event:any) => {
		console.log("transform event",event)
		  //trigger save to content object
		//event has changed things
		//extract rotation position and scale
		if(!event.target.object) return
		console.log(camera,camera.orbit)
		//if(camera.orbit && event.type == "dragging-changed") camera.orbit.enabled = !event.value
		var object = event.target.object
		var mesh = object
		var worldScale = new Vector3()
		mesh.getWorldScale(worldScale)
	
		var position = [mesh.position.x,mesh.position.y,mesh.position.z]
		
		var scale = [worldScale.x,worldScale.y,worldScale.z]
		var rotation = [object.rotation.x,object.rotation.y,object.rotation.z]//content.geometry.rotation//[content.geometry.scale[0]+(1-object.scale.x),content.geometry.scale[1]+(1-object.scale.y),content.geometry.scale[2]+(1-object.scale.z)]
		//the scale is relative to a box that starts at 1,1,1 in scale
		
		var saveToDatabase = event.type == "dragging-changed" && event.value == false
		updateContentGeometry(position,scale,rotation,saveToDatabase)
		
	  }

	useEffect(() => {
		if (transformRef.current) {
		  const controls:any = transformRef.current
		  controls.addEventListener('dragging-changed', onControlChange)
		  controls.addEventListener('change', onControlChange)
		  return () => {
			  controls.removeEventListener('dragging-changed', onControlChange)
			  controls.removeEventListener('change', onControlChange)
		  }
		}
	  })
	useEffect(()=>{
		setGeoemtry({
			position:content.geometry.position,
			scale:content.geometry.scale,
			rotation:content.geometry.rotation,
		})
	},[content])
	/*useFrame(()=>{
		if(transformRef.current){
			
			transformRef.current.position.set()
		}
	})*/
	return content && 
		<TransformControls ref={transformRef} size={0.1} space="world" mode={transformMode} scale={geometry.scale}  position={geometry.position} rotation={geometry.rotation}>
	  		<mesh  >
			  <boxGeometry attach="geometry" args={[1,1,1]}></boxGeometry>
				<meshStandardMaterial attach="material" color="white" transparent opacity={0.1} depthTest={false} depthWrite={false} ></meshStandardMaterial>
		
			  </mesh>
		</TransformControls>
	
}