import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useFirebase, isLoaded, isEmpty } from 'react-redux-firebase'
// import GoogleButton from 'react-google-button' // optional
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";
function LoginPage () {
  const firebase = useFirebase()
  const auth = useSelector((state:any) => state.firebase.auth)
  let history = useHistory();
  let location = useLocation();
  let locationState:any = location.state || { from: { pathname: "/" } };
  if (isLoaded(auth) && !isEmpty(auth)){
    console.log("is loaded",locationState.from,auth)
    history.replace(locationState.from)
  }
  
  function loginWithGoogle() {
    firebase.login({ provider: 'google', type: 'popup' }).then(()=>{
      console.log("LOGGED IN");
      history.replace(locationState.from)
    })
  }

  return (
    <div className="loginPage">
      <div>
        <h2>Auth</h2>
        {
          !isLoaded(auth)
          ? <span>Loading...</span>
          : isEmpty(auth)
            // <GoogleButton/> button can be used instead
            ? <button onClick={loginWithGoogle}>Login With Google</button>
            : <pre>{JSON.stringify(auth, null, 2)}</pre>
        }
      </div>
    </div>
  )
}

export default LoginPage