import * as React from 'react'
import {useState,useEffect, useMemo, useRef} from 'react'
import { connect,useSelector } from 'react-redux'
import { BehaviorSubject } from 'rxjs'
import * as THREE from 'three'
import {Box3,Color,Vector3,BoxHelper, Sphere} from 'three'
import { VertexNormalsHelper } from "three/examples/jsm/helpers/VertexNormalsHelper"

import { computeBoundingBoxFromGroup, screenToWorld } from '../../helperFunctions'
import { useFrame, useResource, useThree} from 'react-three-fiber'
import {useHelper} from 'drei'
import {useSpring,a} from 'react-spring/three'

import ContentTransform from './content-transform.component'
import Content from './content.component'
import uuid from 'uuid/v1'
//const THREE:any = null;
const getContentBounds=(camera:any,el:any)=>{
	var size=[200,200,1]
	var nw = new THREE.Vector3(-size[0]/2,size[1]/2,0)
	var ne = new THREE.Vector3(size[0]/2,size[1]/2,0)
	var se = new THREE.Vector3(size[0]/2,-size[1]/2,0)
	var sw = new THREE.Vector3(-size[0]/2,size[1]/2,0)
	return {
		bounds:{nw,ne,se,sw},size
	}
}
const getScreenBoundsInWorldCoordinates=(camera:any,el:any)=>{
	var nw = screenToWorld(0,0,camera,el,-1)
	var ne = screenToWorld(el.clientWidth,0,camera,el,-1)
	var se = screenToWorld(el.clientWidth,el.clientHeight,camera,el,-1)
	var sw = screenToWorld(0,el.clientHeight,camera,el,-1)
	var size = [ne.distanceTo(nw),nw.distanceTo(sw),1]
	var box = new THREE.Box3()
	box.expandByPoint(new Vector3(-size[0]/2,-size[1]/2,0))
	box.expandByPoint(new Vector3(size[0]/2,-size[1]/2,0))
	box.expandByPoint(new Vector3(-size[0]/2,size[1]/2,0))
	box.expandByPoint(new Vector3(size[0]/2,size[1]/2,0))
	return {
		bounds:{nw,ne,se,sw},size,box
	}
}

 const buildContents = (docs:any,node:any)=>{
	 

	const contentCubeCount:any = {
		text:2,
		photo:2,
		film:6
	}
	var contentsBounds = new THREE.Box3()
	if(!node) return {docsPatched:[],contentsBounds:contentsBounds}
	 	//if this is run it means the query result has changed which can only happen in two scenarios
		//the data changed or the query changed
		//since I'm the only one who can change the data and do it very rarrely this is most likely due to a new id changing the query
		//and thus we can expect to have a different state than what we had before
		//there can be two types of state changes that call for two different transition of the component
		//The first is going from having no contents (and no node id) to having a node and contents.
		//The second transition is going from content from one node to another.
		//The third state change is going from having content to not having any.
		/*const meshToLayout = !contents
		const layoutToLayout = node&&props.node && node.id != props.node.id
		const layoutToMesh = !props.node && contents
		const dataUpdate = node && node.id == props.node.id*/
		

		

		//compute position and size of the initial cube formation
		var globalPosition = new THREE.Vector3(0,0,0)
			var objectScale = new THREE.Vector3(1,1,1)
			if(node.mesh){
				node.mesh.getWorldPosition(globalPosition)
				node.mesh.getWorldScale(objectScale)
			}
			
			var width = node.hoverLayout.w
			var height = node.hoverLayout.h
			var depth = node.hoverLayout.d
			var originalWidth = node.layout.w
			var originalHeight = node.layout.h
			var originalDepth = node.layout.d
			var x = globalPosition.x
			var y = globalPosition.y
			var z = globalPosition.z

		//initialise cube layout variables
		
		var totalCubeCount = docs.reduce((r:any,c:any)=>{
			return r+contentCubeCount[c.type]
		},0)
		console.log("docsPatched before",totalCubeCount,docs)
		if(totalCubeCount == 0) totalCubeCount = 1
		var fragmentedWidth = width+3*totalCubeCount
		var cubeSize = width/totalCubeCount
		var originalCubeSize = originalWidth/totalCubeCount
		var fragmentedSize = fragmentedWidth/totalCubeCount
		var currentX = x-width/2
		var currentFragmentedX = x-fragmentedWidth
		
		var currentCubeIndex = 0
		var currentCubeIndexReverse = totalCubeCount
		var docsPatched:any = docs.map((doc:any,index:any)=>{
			//doc == content
			var content = {...doc}
			
			const cubeCount = contentCubeCount[doc.type]
			console.log("docsPatched inside",doc,index,cubeSize,cubeCount,totalCubeCount,width)
			//with the cube count we can compute the initial position and size for the content
			const initialContentWidth = cubeCount*cubeSize
			const originalContentWidth = cubeCount*originalCubeSize
			const fragmentedContentWidth = cubeCount*fragmentedSize
			content.initial={
				scale: [initialContentWidth,height,depth],
				position:[currentX,y,z],
				color:node.color
			}
			content.original={
				scale: [originalContentWidth,originalHeight,originalDepth],
				position:[currentX,y,z],
				color:node.color
			}
			content.fragmented={
				scale: [fragmentedContentWidth,height,depth],
				position:[currentFragmentedX,y,z],
				color:node.color
			}
			content.sessionId = uuid()
			content.cubeStartIndex = currentCubeIndex
			content.cubeStartIndexReverse = currentCubeIndexReverse
			currentX+=initialContentWidth
			currentFragmentedX+=fragmentedContentWidth
			currentCubeIndex+= cubeCount
			currentCubeIndexReverse-=cubeCount
			var contentNW = new THREE.Vector3(content.geometry.position[0]-content.geometry.scale[0]/2,content.geometry.position[1]-content.geometry.scale[1]/2,content.geometry.position[2]-content.geometry.scale[2]/2)
			var contentNE = new THREE.Vector3(content.geometry.position[0]+content.geometry.scale[0]/2,content.geometry.position[1]-content.geometry.scale[1]/2,content.geometry.position[2]-content.geometry.scale[2]/2)
			var contentSW = new THREE.Vector3(content.geometry.position[0]-content.geometry.scale[0]/2,content.geometry.position[1]+content.geometry.scale[1]/2,content.geometry.position[2]-content.geometry.scale[2]/2)
			var contentSE = new THREE.Vector3(content.geometry.position[0]+content.geometry.scale[0]/2,content.geometry.position[1]+content.geometry.scale[1]/2,content.geometry.position[2]-content.geometry.scale[2]/2)
			contentsBounds.expandByPoint(contentNW)
			contentsBounds.expandByPoint(contentNE)
			contentsBounds.expandByPoint(contentSW)
			contentsBounds.expandByPoint(contentSE)
			content.contentsBounds = contentsBounds
			return content
			//
		})
		return {docsPatched,contentsBounds}
 }




















export default function ContentManager({openSubject,onReady,flagSubject,editMode,transformMode,firestore,firebase,operateSubject}:any){
	
	
	const timers:any = useRef([])
	const contentReadyState:any = useRef([]);
	const pass:any = useRef(0)
	const contentNode:any = useRef(null)
	const currentState:any = useRef("close")
	const currentContents:any = useRef([])
	const lockScrolling:any = useRef(true)
	const [contents,setContents]:any = useState([])
	const [screenRef,screenMesh]:any = useResource()
	const [boundRef,boundGroup]:any = useResource()
	const contentRef:any= useRef()
	const query:any =  useRef(null)
	const bounds:any = useRef(null)
	const scrollBounds:any = useRef(null)
	var {gl,camera}:any =useThree()
	
	var screenBounds:any = useMemo(()=>getScreenBoundsInWorldCoordinates(camera,gl.domElement),[])	
	var fixedBounds:any = useMemo(()=>getContentBounds(camera,gl.domElement),[])	
	//if(contents.length == 0) alert("content is not here")
	const [scrollProps,updateScrollProps,stopScroll]:any = useSpring(()=>{
		return {
			position:[0,0,0],
			immediate:false
		}
	})
	const _event:any = new BehaviorSubject({
		y: 0,
		deltaY: 0
	})
	const percentage:any = new BehaviorSubject(0)

	const scroll = (e:any)=> {
		console.log("scroolllll",_event.value)
		var maxHeight = 0
		if(boundGroup){
			
			var box:any = computeBoundingBoxFromGroup(boundGroup)
			var screen:any = getScreenBoundsInWorldCoordinates(camera,gl.domElement)
			console.log("box",box)
			var screenHeight = screen.size[1]
			var deltaTop = screenHeight/2 - box.min.y 
			var scrollHeight = Math.abs(box.max.y - box.min.y) + deltaTop

			
			maxHeight = Math.max(0,scrollHeight-screenHeight)
		}
		
		var evt = _event.value;
		// limit scroll top
		if ((evt.y + evt.deltaY) > 0 ) {
		  evt.y = 0;
		// limit scroll bottom
		} else if ((-(evt.y + evt.deltaY)) >= maxHeight) {
		  evt.y = -maxHeight;
		} else {
			evt.y += evt.deltaY;
		}
		var smoothedY = lerp(percentage.value, -evt.y, .07)
		percentage.next(smoothedY);
		console.log("smoothedY",smoothedY,percentage.value)
		updateScrollProps({
			position:[0,smoothedY,0]
		})
	  }
	  const resetScrolling = ()=>{
		  if(editMode) return
		percentage.next(0);
		_event.next({
			y:0,
			deltaY:0
		})
		updateScrollProps({
			position:[0,0,0],
		})
	  }
	  const wheelEvent = (e:any)=>{
		  if(lockScrolling.current || editMode) return
		//contentRef.current.rotation.y += event.movementX * 0.005;
		var evt = _event.value;
		evt.deltaY = e.wheelDeltaY || e.deltaY * -1;
		// reduce by half the delta amount otherwise it scroll too fast (in a other way we could increase the height of the container too)
		evt.deltaY *= 0.5;
		_event.next(evt)
		scroll(e);
	}






	const onContentReady = (contentId:any,contentState:any,nodeState:any,remove:any,index:any)=>{
		if(nodeState == currentState.current) contentReadyState.current.push(contentId)
		if(contentReadyState.current.length == contents.length){
			onReady(currentState.current)
			if(nodeState != 'close' && !editMode){
				fitToAll()
				lockScrolling.current = false
			}else{
				lockScrolling.current = true
			}
			
		}

		if(remove){
			var oldContents = [...currentContents.current]
			oldContents.splice(index,1)
			setContents(currentContents.current)
			currentContents.current = oldContents
		}
		
	}





















	useEffect(()=>{
		var canvas = gl.domElement;
		
		  
		window.addEventListener('wheel', wheelEvent);

		return ()=>{
			window.removeEventListener('wheel', wheelEvent);
		}
	},[boundGroup])
	const lerp = (a:any, b:any, t:any) =>{
return ((1 - t) * a + t * b);
}
	
const clearTimers = ()=>{
	timers.current.forEach((t:any)=>{
		clearTimeout(t)
	})
}
const animateCamera=(newState:any,contentsBounds:any)=>{
	
	if(newState == 'open'){
		
		
		timers.current.push(setTimeout(()=>{
			operateSubject.next({type:"fitMesh",args:[contentsBounds,0,Math.PI/2,10],options:{
				enabled:editMode,
				maxPolarAngle:Math.PI,
				minAzimuthAngle:-Infinity,
				maxAzimuthAngle:Infinity,
				azimuthRotateSpeed: 0.1,
				polarRotateSpeed: 0.1,
				dampingFactor: 0.02
			}})
			
			
		},800))
		
	}
	else if(newState == 'switch'){
		operateSubject.next({type:"fitMesh",args:[contentsBounds,0,Math.PI/2,10],options:{
			enabled:editMode,
			maxPolarAngle:Math.PI,
			minAzimuthAngle:-Infinity,
			maxAzimuthAngle:Infinity,
			azimuthRotateSpeed: 0.1,
			polarRotateSpeed: 0.1,
			dampingFactor: 0.001
		}})
		
		
	}
}
const computeSize=(contents:any)=>{
	const boundingBox = new THREE.Box3();
			boundingBox.setFromObject(screenMesh)
			const sphere = new THREE.Sphere()
			 boundingBox.getBoundingSphere(sphere);
			const { center, radius } = sphere;
	
}
const updateContents = (snapshot:any,n:any,newState:any)=>{
	if(!editMode && query.current) query.current()
	clearTimers()
	
	const docs:any = []
		if(snapshot) snapshot.forEach((d:any)=>{docs.push(d.data())})
		//const docs:any = query && query.docs
		//this doc is basically our contents
		//but we need to patch it with some live data from the timeline graph to make it work with the animations		
		var {docsPatched,contentsBounds}:any = buildContents(docs,n)
		//with the content bounds we can modify the bounding box
		var contentsScreenBounds = contentsBounds.clone()
		contentsScreenBounds.min.set(contentsScreenBounds.min.x,screenBounds.box.min.y,0)
		contentsScreenBounds.max.set(contentsScreenBounds.max.x,screenBounds.box.max.y,0)
		//if(screenMesh) contentsBounds.expandByObject(screenMesh)	
		//okay we our contents ready to go now for some logistics
		//so what if we had content already displayed? Well we should close that content or morph it into the new content
		//we do this by matching current content in the contents list with the new patched docs 
		//if type is the same 
		var newContents:any = []
		var oldContents:any = []
		//we start by removing any oldContent that was removed during the last transition
		currentContents.current.forEach((content:any,index:any)=>{
			if(content.state != "remove") oldContents.push({...content})
		})
		
		//now for the fun part
		docsPatched.forEach((doc:any)=>{
			var matchingContent = oldContents.reduce((a:any,c:any,index:any)=>{
				if(doc.type == c.type)  a.push(index)
				return a
			},[])
			//we take this content and compute the distance between each content and the current content
			var newPos = new THREE.Vector3(doc.geometry.position[0],doc.geometry.position[1],doc.geometry.position[2])
			var matchIndex = -1
			var matchDistance = Infinity
			matchingContent.forEach((cIndex:any,index:any)=>{
				var c = oldContents[cIndex]
				var oldPos = new THREE.Vector3(c.geometry.position[0],c.geometry.position[1],c.geometry.position[2])
				var distance = oldPos.distanceTo(newPos)
				if(matchDistance > distance ) {
					matchDistance = distance
					matchIndex = cIndex
				}
			})
			//this will ensure the object is kept in the dom when react renders the content list since the key is the sessionId
			if(matchIndex > -1){
				var content:any = {...oldContents[matchIndex]}
				doc.sessionId = content.sessionId
				doc.state = "morph"
				
				//now we add it the newContents array
				oldContents.splice(matchIndex,1)
			}else{
				//no matching content was found so we have to create a new one
				doc.state = "add"
				
			}
			doc.nodeState = newState
			newContents.push(doc)
		})
		//after this has been done we might still have oldContent objects left that we could not morph
		//for those we need to set their state to remove and add them to the newContents array as well
		oldContents.forEach((content:any)=>{
			
			content.state = 'remove'
			content.nodeState = newState
			content.contentsBounds = contentsBounds
			newContents.push(content)
		})
		
		//finally we add the newContents list and rerender the component
		
		console.log("new contents!",[...newContents],[...oldContents],docsPatched,docs,pass.current)
		setContents(newContents)
		contentNode.current = n
		currentContents.current = newContents
		currentState.current = newState
		bounds.current = contentsScreenBounds
		scrollBounds.current = contentsBounds
		pass.current+=1
		if(!editMode||pass.current == 1) animateCamera(newState,contentsScreenBounds)
		resetScrolling()
		onReady('contentRetrieved')
	
}

useEffect(()=>{
	var sub = openSubject.subscribe(async(n:any)=>{
		//clear the contentReadyState since we should prep for transition
		clearTimers()
		if(!n) pass.current = 0
		contentReadyState.current = []
		const collectionRef = firestore.collection("Contents");
		//we first need to determine what state our component is in and where we want to go
		const newState = n? contentNode.current && contentNode.current.id != n.id? "switch": "open":"close"
		 
		if(n){
			query.current = collectionRef.where("nodeId","==",n.id).onSnapshot((querySnapshot:any)=>updateContents(querySnapshot,n,newState))
			
		}
		else updateContents(null,n,newState)
		console.log("query ssub",query.current)
	})
	return ()=>{
		sub.unsubscribe(); 
		if(query.current) {
			console.log(query.current)
			query.current()
		}
	}
},[openSubject,screenMesh,firestore])

	const fitToContent=(mesh:any)=>{
		console.log("fit to content",mesh)
		operateSubject.next({type:"fitMesh",args:[mesh,0,Math.PI/2,10],options:{
			enabled:editMode,
			maxPolarAngle:Math.PI,
			minAzimuthAngle:-Infinity,
			maxAzimuthAngle:Infinity,
			azimuthRotateSpeed: 0.1,
			polarRotateSpeed: 0.1,
			dampingFactor: 0.2
		}})
		//time to hide all other stuff //disable scrolling as well
		lockScrolling.current = true
		flagSubject.next({...flagSubject.value,fullscreen:true})

	}
	const fitToAll=()=>{
		if(!bounds.current) return
		operateSubject.next({type:"fitMesh",args:[bounds.current,0,Math.PI/2,10],options:{
			enabled:editMode,
			maxPolarAngle:Math.PI,
			minAzimuthAngle:-Infinity,
			maxAzimuthAngle:Infinity,
			azimuthRotateSpeed: 0.1,
			polarRotateSpeed: 0.1,
			dampingFactor: 0.05
		}})
		lockScrolling.current = editMode
		flagSubject.next({...flagSubject.value,fullscreen:false})
	}

	useHelper(boundRef, VertexNormalsHelper,0.0, "white")
	//const color = new Color("white")
	return <group ref={contentRef}>
		<a.mesh ref={screenRef} frustumCulled={false} position={[0,0,0]} scale={screenBounds.size} dispose={null}>
			<boxBufferGeometry attach="geometry" args={[1,1,1]}></boxBufferGeometry>
			<a.meshStandardMaterial attach="material" color={ "white"} transparent={true} opacity={0} depthTest={false} depthWrite={false}   />	
			</a.mesh>
		
			<a.group position={scrollProps.position}>
			{contents && editMode && <group>
			
				
		 {contents.map((content:any,index:any)=>{
			//I wonder if percentage position isnt the way t go?
			if(content.state == 'remove') return
				return <ContentTransform key={index} content={content} transformMode={transformMode} firestore={firestore}/>
			
	})}</group>}
	
			<group ref={boundRef}>
		{contents.map((content:any,index:any)=>{
			//I wonder if percentage position isnt the way t go?
				return <Content key={content.sessionId} firebase={firebase}  content={content} editMode={editMode} fitToAll={()=>{fitToAll()}} fitToContent={(mesh:any)=>{fitToContent(mesh)}} onReady={(e:any)=>{onContentReady(content.sessionId,e.contentState,e.nodeState,e.remove,index)}} />
			
	})}</group>
</a.group>

	</group>
}