import * as React from 'react'
import  { useCallback,useMemo,useEffect ,useState,useRef} from 'react'
import {a,useSpring} from 'react-spring/three'

export default function FullscreenButton({particleMode,expand,particleProps,onToggle}:any){

	var [arrow1Props,updateArrow1,stop1]:any = useSpring(()=>{
		return {
			scale:[0.001,0.001,0.001],
			position:[0,-.6,0],
			
			rotation:[0,0,0],
			color:"white",
			opacity:0.0
		}
	})

	var [arrow2Props,updateArrow2,stop2]:any = useSpring(()=>{
		return {
			scale:[0.001,0.001,0.001],
			position:[0,.6,0],
		
			rotation:[0,0,0],
			color:"white",
			opacity:0.0
		}
	})
	var [screenProps,updateScreen,stopScreen]:any = useSpring(()=>{
		return {
			scale:[0.001,0.001,0.001],
			position:[0,0,0],
			rotation:[0,0,0],
			color:"white",
			opacity:0.0,
		}
	})

	
	
	

	useEffect(()=>{
		toggleIcon(expand)
	},[expand])
	const toggleIcon=(value:any)=>{
		if(!value) {
			updateScreen({scale:[1,0.4,0.1],opacity:1})
			updateArrow1({scale:[0.2,0.2,0.2],rotation:[0,0,-Math.PI/2],opacity:1})
			updateArrow2({scale:[0.2,0.2,0.2],rotation:[0,0,Math.PI/2],opacity:1})
		}else{
			updateScreen({scale:[1,0.8,0.1],opacity:1})
			updateArrow1({scale:[0.2,0.2,0.2],rotation:[0,0,Math.PI/2],opacity:1})
			updateArrow2({scale:[0.2,0.2,0.2],rotation:[0,0,-Math.PI/2],opacity:1})
		}
	}
	const onClick = (e:any)=>{
		if(e) e.stopPropagation()
		onToggle(!expand)
	}
	
	return (<a.group   >
		
		<a.mesh scale={[1.5,1.5,1.5]} frustumCulled={false} dispose={null} onClick={(e:any)=>{onClick(e)}}>
			<boxBufferGeometry attach="geometry" args={[1,1,1]}></boxBufferGeometry>
			<a.meshStandardMaterial attach="material"  color={ "red"} transparent={true} opacity={0.0} depthTest={false} depthWrite={false}  />	
		</a.mesh>
		
		
		


		<a.mesh  frustumCulled={false} scale={arrow2Props.scale}  position={arrow2Props.position} rotation={arrow2Props.rotation} dispose={null}>
			
			<circleGeometry attach="geometry" args={[1,3]}></circleGeometry>
			<a.meshStandardMaterial attach="material"  color={ arrow2Props.color} transparent={true} opacity={arrow2Props.opacity} depthTest={false} depthWrite={false}  />	
		</a.mesh>
		<a.mesh  frustumCulled={false} scale={arrow1Props.scale}  position={arrow1Props.position} rotation={arrow1Props.rotation} dispose={null}>
			<circleGeometry attach="geometry" args={[1,3]}></circleGeometry>
			<a.meshStandardMaterial attach="material"  color={ arrow1Props.color} transparent={true} opacity={arrow1Props.opacity} depthTest={false} depthWrite={false}  />	
		</a.mesh>
		<a.mesh  frustumCulled={false} scale={screenProps.scale}  position={screenProps.position} rotation={screenProps.rotation} dispose={null} >
		<boxBufferGeometry attach="geometry" args={[1,1,1]}></boxBufferGeometry>
			<a.meshStandardMaterial attach="material"  color={ screenProps.color} transparent={true} opacity={screenProps.opacity} depthTest={false} depthWrite={false}  />	
		</a.mesh>


		
		
		</a.group>)
}