import * as React from 'react'
import {useEffect,useState} from 'react'
import { IoIosArrowRoundBack,IoIosArrowRoundForward } from 'react-icons/io'
import {useSpring,a,config} from 'react-spring'
import "./next-bar.css"
export default function NextBar ({flagSubject,next,previous,nodesSubject}:any){
   const [hide,setHide]:any = useState(true)
   const [current,setCurrentIndex]:any = useState(0)
   const [totalCount,setTotalCount]:any = useState(0)
   const [isSearching,setSearching]:any = useState(false)
	var backButtonProps:any = useSpring({
      //opacity:hide?0.0:1.0,
      transform:hide || current == 0?"scale(0.0)":"scale(1.0)",
      pointerEvents:hide || current == 0?"none":"all",
      native:true,
      config:config.stiff
   })
   var forwardButtonProps:any = useSpring({
      //opacity:hide?0.0:1.0,
      transform:hide || current == totalCount-1?"scale(0.0)":"scale(1.0)",
      pointerEvents:hide || current == totalCount-1?"none":"all",
      native:true,
      config:config.stiff
   })
   var props:any = useSpring({
      //opacity:hide?0.0:1.0,
      transform:hide?"translateX(-50%) scale(0.0)":"translateX(-50%)  scale(1.0)",
      pointerEvents:hide?"none":"all",
      native:true,
      config:config.stiff
   })
   useEffect(()=>{
		var sub = flagSubject.subscribe(({entered,loaded,minimize,fullscreen,currentIndex,searching}:any)=>{
         setHide(!entered || !loaded || fullscreen || !minimize)
         setCurrentIndex(currentIndex)
         setSearching(searching)
      })
      var sub2 = nodesSubject.subscribe(({currentLeafsWithContent,currentLeafs}:any)=>{
         
         setTotalCount(currentLeafsWithContent.length)
      })
		return ()=>{
         sub2.unsubscribe()
			sub.unsubscribe()
		}
	},[flagSubject])
	return <a.div style={props} className="next-bar">
        <a.button style={backButtonProps} className="left-arrow button" onClick={()=>{previous(current-1)}}>
            <IoIosArrowRoundBack/>
         </a.button>
         <p className="projectCount">{current+1 +" / "+ totalCount}</p>
         
         <a.button style={forwardButtonProps} className="right-arrow button" onClick={()=>{next(current+1)}}>
            <IoIosArrowRoundForward/>
            
          </a.button>
              
      </a.div>
}