import * as React from 'react'
import {useEffect, useState} from 'react'

import {useSpring,useTransition,a,config} from 'react-spring' 
import {NodeCard} from '../node-card/node-card.component'
import {getTextHeight} from '../../helperFunctions'
import './node-list.css'


function computePositions(nodes:any,positions:any,vertical:any){
	var currentTop = 0
			var width = 250
        	nodes.forEach((child:any,index:any)=>{
			positions[child.id]= {
				x:vertical?0:currentTop,
				y:vertical?currentTop:0
			}; 
			currentTop+= !vertical?width:child.listItemHeight
		})
		return positions
}
export default function NodeList({nodesSubject,hoverSubject,openSubject,nodeListSubject,vertical}:any){
	console.log("rerenderNodeList")
	var [nodes,setNodes] = useState(nodesSubject.value.currentLeafs)
	var [pos,setPos]:any = useState(computePositions(nodes,{...pos},vertical))
	var [open,setOpen]:any = useState(false)
	const transition:any = {
	
		from: (child:any) => ({opacity:0,transform: pos[child.id]?`translate3d(${pos[child.id].x}px, ${pos[child.id].y}px, 0) scale(0.6)` :'translate3d(0,0,0) scale(1.0)'}),
		enter:(child:any) => ({opacity:open?1.0:0.0,transform: pos[child.id]?`translate3d(${pos[child.id].x}px, ${pos[child.id].y}px, 0) scale(1.0) `: 'translate3d(0,0,0) scale(1.0)' }),
		leave: (child:any) => ({opacity:0,transform: pos[child.id]?`translate3d(${pos[child.id].x}px, ${pos[child.id].y}px, 0) scale(0.6) `:'translate3d(0,0,0) scale(1.0)' }),
		update:(child:any) => ({opacity:open?1.0:0.0,transform: pos[child.id]?`translate3d(${pos[child.id].x}px, ${pos[child.id].y}px, 0) scale(1.0) `:'translate3d(0,0,0) scale(1.0)'}),
		keys: nodes.map((node:any, index:any) => node.id),
		config:  {friction:26,mass:1,tension:190},
		native:true,
		unique:true,
		trail:10,

		
	}

	useEffect(()=>{
		console.log("nodelist recomp",vertical,nodes)
		var positions:any = computePositions(nodes,{...pos},vertical)
		setPos(positions)
		var sub = nodeListSubject.subscribe((e:any)=>{
			if(!e) return
			if(e.action == 'open'){
				setOpen(e.value)
				
			}
		})
		var sub2 = nodesSubject.subscribe(({currentLeafsWithContent,currentLeafs}:any)=>{
			console.log("node list", currentLeafs,currentLeafsWithContent)
			var positions:any = computePositions(currentLeafs,{...pos},vertical)
			setPos(positions)
			setNodes(currentLeafs)
		})
		return ()=>{
			sub2.unsubscribe()
			sub.unsubscribe()
		}
	},[vertical])
	const onCLick=(node:any)=>{
		if(node.contentCount > 0) openSubject.next(node)
	}
	
	const transitions2 = useTransition(nodes, transition)
		

		
		const onNodeListItemHover=(n:any,h:any)=>{
			//onNodeHover(n,h)
			console.log("hover event in list",n,h)
			n.subject.next({action:'hover',value:h})
			hoverSubject.next({n,h,origin:"list"})
		}
	
		
	return <a.div className={"node-list" + (vertical?" vertical":"") + (open?"":" hide")} >
		 <div className="nodeListDivider">
			
                   
					</div>
					<a.div  className="background" ></a.div>
	{transitions2((styles:any,node:any)=>{
		return <a.div className="node-list-item" style={styles} onMouseLeave={(e:any)=>{onNodeListItemHover(node,false)}} onMouseEnter={(e:any)=>{onNodeListItemHover(node,true)}} onClick={(e:any)=>onCLick(node)}>
			<NodeCard node={node} focus={true} />
			</a.div>
	})}
	
</a.div>
}