import * as React from 'react'
import  { useCallback,useMemo,useEffect ,useState,useRef} from 'react'
import {a,useSpring} from 'react-spring/three'

export default function PlayButton({pause,autoFadeOnPlay,autoFadeDelay,onToggle}:any){

	const pauseRef = useRef(pause);
	var [pause1Props,updatePause1,stopPause1]:any = useSpring(()=>{
		return {
			scale:[0.001,0.001,0.001],
			position:[-0.25,0,0],
			color:"white",
			opacity:0.0
		}
	})

	var [pause2Props,updatePause2,stopPause2]:any = useSpring(()=>{
		return {
			scale:[0.001,0.001,0.001],
			position:[0.25,0,0],
			color:"white",
			opacity:0.0
		}
	})
	var [playProps,updatePlay,stopPlay]:any = useSpring(()=>{
		return {
			scale:[0.001,0.001,0.001],
			position:[0,0,0],
			rotation:[Math.PI/4,0,0],
			color:"white",
			opacity:0.0,
		}
	})

	var [props,update,stop]:any = useSpring(()=>{
		return {
			opacity:0.0
		}
	})
	const schedulePauseFade=()=>{
		
			setTimeout(()=>{
					console.log("fade out pause button")
					updatePause1({opacity:0.0})
					updatePause2({opacity:0.0})
				
			},autoFadeDelay)
		
	}
	

	const toggleIcon=(value:any)=>{
		if(!value) {
			updatePlay({scale:[0.001,0.001,0.001],opacity:1})
			updatePause1({scale:[0.4,1,0.001],opacity:1})
			updatePause2({scale:[0.4,1,0.001],opacity:1})
		}else{
			updatePlay({scale:[0.8,1,1],opacity:1})
			updatePause1({scale:[0.001,0.001,0.001],opacity:1})
			updatePause2({scale:[0.001,0.001,0.001],opacity:1})
		}
	}
	useEffect(()=>{
		toggleIcon(pause)
		if(autoFadeOnPlay && !pause) schedulePauseFade()
	},[pause])
	const onClick = (value:any,propagate:any,e:any)=>{
		if(e) e.stopPropagation()
		if(propagate) onToggle(value)
	}
	
	return (<a.group>
		
		
			
		<a.mesh scale={[1.5,1.5,1.5]} frustumCulled={false} dispose={null} onClick={(e:any)=>{onClick(!pause,true,e)}}>
			<boxBufferGeometry attach="geometry" args={[1,1,1]}></boxBufferGeometry>
			<a.meshStandardMaterial attach="material"  color={ "red"} transparent={true} opacity={0.0} depthTest={false} depthWrite={false}  />	
		</a.mesh>
		<a.mesh  frustumCulled={false} scale={pause1Props.scale} position={pause1Props.position} dispose={null}>
			<boxBufferGeometry attach="geometry" args={[1,1,1]}></boxBufferGeometry>
			<a.meshStandardMaterial attach="material"  color={ pause1Props.color} transparent={true} opacity={pause1Props.opacity} depthTest={false} depthWrite={false}  />	
		</a.mesh>


		<a.mesh  frustumCulled={false} scale={pause2Props.scale}  position={pause2Props.position} dispose={null}>
			<boxBufferGeometry attach="geometry" args={[1,1,1]}></boxBufferGeometry>
			<a.meshStandardMaterial attach="material"  color={ pause2Props.color} transparent={true} opacity={pause2Props.opacity} depthTest={false} depthWrite={false}  />	
		</a.mesh>
		<a.mesh  frustumCulled={false} scale={playProps.scale}  position={playProps.position} rotation={playProps.rotation} dispose={null}>
			<circleGeometry attach="geometry" args={[1,3]}></circleGeometry>
			<a.meshStandardMaterial attach="material"  color={ playProps.color} transparent={true} opacity={playProps.opacity} depthTest={false} depthWrite={false}  />	
		</a.mesh>


		
		
		</a.group>)
}