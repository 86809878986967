import * as React from 'react'
import {useEffect,useState} from 'react'
import { BiCube } from 'react-icons/bi'
import { CgShapeSquare } from 'react-icons/cg'

export default function DimensionButton({flagSubject,device,onClick,editMode}:any){
	const [hide,setHide]:any = useState(true)
	const [d,setDimensions]:any = useState(3)
	useEffect(()=>{
		var sub = flagSubject.subscribe(({entered,loaded,minimize,fullscreen,dimensions}:any)=>{
			setHide(!entered||minimize||!loaded)
			setDimensions(dimensions)
		})
		return ()=>{
			sub.unsubscribe()
		}
	},[flagSubject])
	return (<button className={`dimensionButton ${hide?'hide':''} ${device == 'mobile'? 'top':''}`}  onClick={()=>onClick(d == 3? 2:3)}>{d == 3?<CgShapeSquare/>:<BiCube/>}<p>{d==2?3:2}D</p></button>
	)
}