import * as React from 'react'
import {useState,useEffect, useMemo,useRef} from 'react'
import { useSelector } from 'react-redux'
import { BehaviorSubject } from 'rxjs'
import {useSprings,useSpring,animated,config} from 'react-spring/three'
import { BufferGeometry, LinearFilter, Object3D, RGBFormat, Vector3,BufferAttribute,VideoTexture,TextureLoader, Mesh,Box3,NearestFilter, ImageBitmapLoader, CanvasTexture } from 'three'
import { rotateVectorAroundPivotPoint, rotateVectorAroundPivotPointWithEuler } from '../../helperFunctions'
 import {extend, useThree,useResource, useFrame,} from 'react-three-fiber'
 import {TransformControls, useAspect} from "drei"
import PlayButton from './playbutton.component'
import DurationSlider from './durationslider.component'
import FullscreenButton from './fullscreenbutton.component'
import {Text} from 'drei'
/*var Text:any = require('troika-three-text').Text
console.log("troika text",Text,mesh)
extend({TroikaText:Text})
declare global {
	namespace JSX {
	  interface IntrinsicElements {
		troikaText:mesh
	  }
	}
  }*/
  const fontUrls:any = {
	bold:"https://cdn.jsdelivr.net/npm/lato-font@3.0.0/fonts/lato-bold/lato-bold.woff",
	bolditalic:"https://cdn.jsdelivr.net/npm/lato-font@3.0.0/fonts/lato-bold-italic/lato-bold-italic.woff",
	italic:"https://cdn.jsdelivr.net/npm/lato-font@3.0.0/fonts/lato-normal-italic/lato-normal-italic.woff",
	normal:"https://cdn.jsdelivr.net/npm/lato-font@3.0.0/fonts/lato-normal/lato-normal.woff"
}
  var a:any = animated
  const AnimText = a(Text)

const getBufferGeometry=()=>{
	var contentGeometry = new BufferGeometry()
	var vertices = new Float32Array( [
		0,0,0,
		0,0,0,
		0,0,0,

		0,0,0,
		0,0,0,
		0,0,0
		
	] );

	var uvs = new Float32Array( [
		0.0, 0.0,
		1.0, 0.0,
		1.0, 1.0,

		1.0, 1.0,
		0.0, 1.0,
		0.0,0.0
		]);
		
	contentGeometry.setAttribute( 'uv', new BufferAttribute( uvs, 2 ) );
	contentGeometry.setAttribute( 'position', new BufferAttribute( vertices, 3 ) );
	return contentGeometry
}
 const getFinalCubeGeometry=(type:any,parentSize:any,padding:any,durationBarWidth:any)=>{
	
	var cursorStart = (parentSize[0]/2-padding-1.5)
	var paddingBottom = 4
	switch(type){
		case "corner1":
			var point:any = [-parentSize[0]/2,-parentSize[1]/2,0]
			return {
				position:point,
				scale:[0.5,0.5,0.5],
				rotation:[0,0,0],
				opacity:1
			}
		/*case "corner2":
			var point:any = [+parentSize[0]/2,-parentSize[1]/2,0]
			return {
				
				position:point,
				scale:[0.5,0.5,0.5],
				rotation:[0,0,0],
				opacity:1
			}*/
		case "corner3":
			var point:any = [+parentSize[0]/2,+parentSize[1]/2,0]
			return {
				position:point,
				scale:[0.5,0.5,0.5],
				rotation:[0,0,0],
				opacity:1
			}
		/*case "corner4":
			var point:any = [-parentSize[0]/2,+parentSize[1]/2,0]
			return {
				position:point,
				scale:[0.5,0.5,0.5],
				
				rotation:[0,0,0],
				opacity:1
			}*/
		case "pause":
			var point:any = [-parentSize[0]/2 + padding,-parentSize[1]/2+paddingBottom,4]
			return {
				position:point,
				scale:[0.00001,0.00001,0.000001],
				controlScale:[2,2,0.1],
				rotation:[0,0,0],
				opacity:1
			}
			case "pauseBig":
				var point:any = [0,0,4]
				return {
					position:point,
					scale:[0.00001,0.00001,0.000001],
					controlScale:[10,10,0.1],
					rotation:[0,0,0],
					opacity:1
				}
		
		case "duration":
			var point:any = [0,-parentSize[1]/2+paddingBottom,4]
			return {
				position:point,
				scale:[0.00001,0.00001,0.000001],
				controlScale:[1,1,.1],
				rotation:[0,0,0],
				opacity:0.1//parentRotation//[parentRotation[0],parentRotation[1],parentRotation[2],]
			}
		
		
		
		case "expand":
			var point:any = [+parentSize[0]/2 - padding,-parentSize[1]/2+paddingBottom+0.4-0.5,4]
			return {
				position:point,
				scale:[0.000001,0.00001,0.00001],
				controlScale:[2,2,1],
				rotation:[0,0,0],
				opacity:1
			}
		
	}
}











export default function Content({content,editMode,transformMode,fitToContent,fitToAll,onReady,firebase}:any){
	
	var [contentRef,contentMesh]:any = useResource()
	var contentGeometry:any = useMemo(()=>getBufferGeometry(),[])
	var [particleGroupRef,particleGroup]:any = useResource()
	var [textMaterialRef,textMaterial]:any = useResource()
	var [textRef,textMesh]:any = useResource()
	var [wireframeRef,wireframeMesh]:any = useResource()
	const particlesRef:any = useRef([])
	const currentState:any = useRef("")
	const currentCubeData:any = useRef()
	const currentContent:any = useRef()
	const [videoRef,videoMesh]:any = useResource()
	const [imageRef,imageMesh]:any = useResource()
	const imageTexture:any = useRef()
	const videoTexture:any = useRef()

	const timers:any = useRef([])
	

	//const [current,setCurrent]:any = useState(null)
	const cubeTypes = !content? []: content.type == 'film'? ["corner1","corner3","pause","pauseBig","duration","expand"]:["corner1","corner3"]
	const cubeCount = !content? 0:content.type == 'film'? 6:2
	const [videoProgress,setVideoProgress]:any = useState(0)
	const [paused,setPaused]:any = useState(true)
	const [expanded,setExpanded]:any = useState(false)
	const rotationInterval:any = useRef(null)
	const ready:any = useMemo(()=>new BehaviorSubject(null),[])
	
	const {camera}:any = useThree()
	var padding= 6
	var durationBarWidth = content.geometry.scale[0] - padding*2-9
	const isReady=(type:any)=>{
		if(!ready.value) return false
		//console.log("video.readyState",ready.value.readyState)
		if(ready.value.readyState > 2 && type=='film') return true
		else if(type=='text' || ready.value != null) return true
		else return false
	}
	
	
	
	const [springs, set, stop] = useSprings(cubeCount, (index:any) => {
		//figure out what the final position is and place it here
		//var cubeGeometry:any = getFinalCubeGeometry(cubeTypes[index],content.geometry.scale,padding,durationBarWidth)

		return {
		position:[0,0,0],
		scale:[0.0001,0.0001,0.0001],
		rotation:[0,0,0],
		color:"white",
		opacity:0.0,
		controlScale:[0.00000001,0.0000001,0.00000001],
		immediate:false,
		config:{friction:30,tension:220,mass:20},
		native:true
	}})
	const [contentProps,setContentProps,stopContent]:any = useSpring(()=>{
		
		return {
			position:content.geometry.position,
			rotation:content.geometry.rotation,
			scale:[0.0001,0.0001,0.0001],
			opacity:0.0,
			videoOpacity:0.0,
			imageOpacity:0.0,
			backgroundOpacity:0.0,
			textOpacity:0.0,
			//completeScale:[1,1,1],
			immediate:false
		}
	})

	const loadVideo=(path:any)=>{
		//setVideo(null)
		
		console.log("path",path)
		if(!path) return
		ready.next(null)
		var ref = firebase.storage().ref()
		var id:any = content.id
    	ref.child(path).getDownloadURL().then((downloadUrl:any)=>{
			if(id != content.id) return 
			console.log("downloadUrl",downloadUrl)
			const vid = document.createElement("video")
			vid.src = downloadUrl
			vid.crossOrigin = ""
			vid.load()
			vid.currentTime = 0.01
			console.log("console",vid)
			//setVideo(vid)
			
			videoTexture.current = buildVideoTexture(vid)
			if(videoMesh && videoMesh.material){
				
				if(videoMesh.material.map) videoMesh.material.map.dispose()
				videoMesh.material.map = videoTexture.current
				videoMesh.material.needsUpdate = true
			}
			//currentVideo.current = vid
			ready.next(vid)
		})
	}
	const getImageTexture = async (path:any,pId:any)=>{
		// instantiate a loader
		return new Promise(async(resolve:any,reject:any)=>{
			var ref = firebase.storage().ref()
			var url = await ref.child(path).getDownloadURL()
			if(pId != content.id) {
				reject("image was fetched but the component has moved onto other content")
				return
			}
			const loader = new ImageBitmapLoader().setOptions( { imageOrientation: 'flipY' } );
			
			// load a image resource
			loader.load(
				// resource URL
				url,
			
				// onLoad callback
				function ( imageBitmap:any ) {
					const texture = new CanvasTexture( imageBitmap );
					resolve({texture,imageBitmap})
				},
			
				// onProgress callback currently not supported
				undefined,
			
				// onError callback
				function ( err:any ) {
					console.log( 'An error happened',err );
				}
			);
		})

	}
	const loadImage= async(sources:any,isVideoPoster:any,pId:any)=>{
		// instantiate a loader
		if(!isVideoPoster) ready.next(null)
		if(imageMesh && imageMesh.material && imageMesh.material.map) {
			imageMesh.material.map.dispose()
			imageMesh.material.needsUpdate = true
		}
		var ref = firebase.storage().ref()
		//var id:any = content.id
		getImageTexture(sources.pathHigh,pId).then(({texture,imageBitmap}:any)=>{
		console.log("pathHigh texture", texture)	
				if(pId != content.id|| !imageMesh || !imageMesh.material) return
				if(imageMesh.material.map ) imageMesh.material.map.dispose()
				texture.minFilter = NearestFilter;
				texture.magFilter = NearestFilter;
				imageMesh.material.map = texture
				imageMesh.material.needsUpdate = true
				//texture.needsUpdate = true
				imageTexture.current = texture
				if(!isVideoPoster) ready.next(texture)
		}).catch((errr:any)=>{
			console.log(errr)
		})
		getImageTexture(sources.pathLow,pId).then(({texture,imageBitmap}:any)=>{
			console.log("pathHigh texture", texture)	
			
			if(pId != content.id || !imageMesh || !imageMesh.material) return
			if(imageMesh.material.map) return 
			texture.minFilter = NearestFilter;
			texture.magFilter = NearestFilter;
			imageMesh.material.map = texture
			texture.needsUpdate = true
			imageMesh.material.needsUpdate = true
			imageTexture.current = texture
			if(!isVideoPoster) ready.next(texture)
			}).catch((errr:any)=>{
				console.log(errr)
			})
		/*
		ref.child(sources.pathLow).getDownloadURL().then((url:any)=>{
			
		var loader = new TextureLoader();
		if(imageMesh){
			var map = loader.load(url,()=>{
				if(id != content.id || !imageMesh || !imageMesh.material) return
				if(imageMesh.material.map) return 
				map.minFilter = NearestFilter;
				map.magFilter = NearestFilter;
				imageMesh.material.map = map
				map.needsUpdate = true
				imageMesh.material.needsUpdate = true
				imageTexture.current = map
				if(!isVideoPoster) ready.next(map)
			})
			
		}
		})*/
		
		
	}
	const loadText = (text:any)=>{
		ready.next(text)
	}
	const buildVideoTexture=(vid:any)=>{
		var texture = new VideoTexture( vid );
		texture.minFilter = LinearFilter;
		texture.magFilter = LinearFilter;
		texture.format = RGBFormat;
		return texture
	}
	const isContentTheSame=(newC:any,oldC:any)=>{
		var filmIsTheSame = oldC && 'pathFilm' in oldC && 'pathFilm' in newC && newC.pathFilm == oldC.pathFilm
		var photoIsTheSame = oldC && 'pathLow' in oldC && 'pathLow' in newC && newC.pathLow == oldC.pathLow
		return filmIsTheSame && photoIsTheSame
	}
	const update=(data:any)=>{
		//set the currentX at the start of the virtual size of the item
		if(rotationInterval.current) clearInterval(rotationInterval.current)
		clearTimers()
		//stop()
		//stopContent()
		if(!particleGroup) return
		//determine if it is a video content
		var contentIsFilm = data.type == "film" && data.source && data.source.pathFilm
		var contentIsImage = (data.type == "photo"||data.type == "film") && data.source && data.source.pathLow
		var contentIsText = data.type == "text" && data.source && data.source.text
		var updateIsOnSameContent = currentContent.current && data.id == currentContent.current.id && isContentTheSame(data.source,currentContent.source)
		var cubeWidth = data.initial.scale[0]/cubeCount
		var originalCubeWidth = data.original.scale[0]/cubeCount
		var fragmentedCubeWidth = data.fragmented.scale[0]/cubeCount
		var finalPosition = data.geometry.position
		var finalSize = data.geometry.scale
		var finalRotation = data.geometry.rotation
		
		var oldCubes = currentCubeData.current
			var convertedPositions:any = []
			if(oldCubes){
				oldCubes.forEach((oldCube:any)=>{
					var oldFinalPosition = new Vector3(oldCube.final.position[0],oldCube.final.position[1],oldCube.final.position[2])					
					particleGroup.localToWorld(oldFinalPosition)
					convertedPositions.push(oldFinalPosition)
				})
				

			}

		particleGroup.position.set(finalPosition[0],finalPosition[1],finalPosition[2])
		particleGroup.rotation.set(finalRotation[0],finalRotation[1],finalRotation[2])
		particleGroup.updateMatrix()
		particleGroup.updateMatrixWorld(true)

		console.log(finalPosition,finalRotation,finalSize)
		setContentProps({
			position:finalPosition,
			rotation:finalRotation,
			scale:finalSize,
			immediate:true
		})
		if(oldCubes){
			
		set((index:any)=>{
				var position = convertedPositions[index]
				console.log("cube count change error",convertedPositions,oldCubes,currentContent.current.type,content.type,position,cubeCount,index)
				particleGroup.worldToLocal(position)
				return{
					position:[position.x,position.y,position.z],
					immediate:true
				}
			})
			
			
		}
		
		//particleGroup.scale.set(finalSize[0],finalSize[1],finalSize[2])
		//create cubes based on content position and size
		var cubeData = []
		var loadPosition:any = new Vector3()//new Vector3(,0,0)
		data.contentsBounds.getCenter(loadPosition)
		//var currentVector = new Vector3(data.initial.position[0]-data.initial.scale[0]/2,0,0)
		//console.log("test1",currentVector.x)
		particleGroup.worldToLocal(loadPosition)
			//console.log("test1",currentVector.x)
		var currentX = data.initial.position[0]//-data.initial.scale[0]/2//currentVector.x
		var currentOriginalX = data.original.position[0]
		var currentFragmentedX = currentOriginalX//data.fragmented.position[0]
		var pageCenter = data.contentsBounds.center()
		for(var i = 0; i < cubeCount; i++){
			const type = cubeTypes[i]
			//the type dictates the final position relative to the content geometry position
			var initialPosition = new Vector3(currentX,data.initial.position[1],data.initial.position[2])
			var originalPosition = new Vector3(currentOriginalX,data.original.position[1],data.original.position[2])
			var fragmentedPosition = new Vector3(currentFragmentedX,data.fragmented.position[1],data.fragmented.position[2])
			//particleGroup.worldToLocal(originalPosition)
			//particleGroup.worldToLocal(initialPosition)
			//particleGroup.worldToLocal(fragmentedPosition)
			
			console.log(particleGroup,particleGroupRef,initialPosition)
			
			
			var cube:any = {
				type:type,
				randomZ:Math.random(),
				randomX:1,//+0.5*Math.random(),
				hide:{
					position:[initialPosition.x,initialPosition.y,initialPosition.z],
					scale:[0.001,0.001,0.001],
					rotation:[-data.geometry.rotation[0],-data.geometry.rotation[1],-data.geometry.rotation[2]],
					color:data.initial.color,
					opacity:0.0,
					controlScale:[0.000001,0.0000001,0.0000001],
					visible:true,
					world:true
				},
				initial:{
					position:[initialPosition.x,initialPosition.y,initialPosition.z],
					scale:[cubeWidth,data.initial.scale[1],data.initial.scale[2]],
					rotation:[-data.geometry.rotation[0],-data.geometry.rotation[1],-data.geometry.rotation[2]],
					color:data.initial.color,
					opacity:1.0,
					controlScale:[0.000001,0.0000001,0.0000001],
					visible:true,
					world:true
				},
				fragmented:{
					position:[fragmentedPosition.x,fragmentedPosition.y+(Math.random()*10-5),fragmentedPosition.z+(Math.random()*10-5)],
					scale:[1,1,1],
					rotation:[-data.geometry.rotation[0],-data.geometry.rotation[1],-data.geometry.rotation[2]],
					color:data.fragmented.color,
					opacity:1,
					controlScale:[0.000001,0.0000001,0.0000001],
					visible:true,
					world:true
				},
				original:{
					position:[originalPosition.x,originalPosition.y,originalPosition.z],
					scale:[cubeWidth,data.original.scale[1],data.original.scale[2]],
					rotation:[-data.geometry.rotation[0],-data.geometry.rotation[1],-data.geometry.rotation[2]],
					color:data.original.color,
					opacity:1,
					controlScale:[0.000001,0.0000001,0.0000001],
					visible:true,
					world:true
				},

				particle:{
					position:[0,0,0],
					scale:[0.5,0.5,0.5],
					rotation:[0,0,0],
					controlScale:[0.000001,0.0000001,0.0000001],
					color:"white",
					opacity:1,
					visible:true,
					world:true
				},
				

				final:{...getFinalCubeGeometry(type,finalSize,padding,durationBarWidth),color:"white",requireWorldConvertion:false},
				cubeIndex:data.cubeStartIndex + i,
				cubeIndexReverse:data.cubeStartIndexReverse - i
			}
			cube.remove={
				position:[0,0,0],
				scale:[0.001,0.0001,0.0001],
				rotation:[0,0,0],
				controlScale:[0.000001,0.0000001,0.0000001],
				color:"white",
				opacity:0.0,
				visible:true,
				world:true
			}
			
			cubeData.push(cube)
			currentOriginalX+= originalCubeWidth
			currentFragmentedX+=fragmentedCubeWidth
			currentX+= cubeWidth
		}
		
		var contentState:any = data.state
		var nodeState:any = data.nodeState
	//if(contentState !="add")	return
	
		
	if(contentState=="add"){
		//within add we can either add from an open state or a switch state
		if(contentIsFilm && !updateIsOnSameContent) loadVideo(data.source.pathFilm)
		if(contentIsImage && !updateIsOnSameContent) loadImage(data.source,contentIsFilm,data.id)
		if(contentIsText && !updateIsOnSameContent) loadText(data.source.text)
		if(nodeState == "open") toFinal(cubeData,true,false,data)
		else if(nodeState == "switch") toFinal(cubeData,false,true,data)
	}else if(contentState=='morph'){

		if(contentIsFilm && !updateIsOnSameContent) loadVideo(data.source.pathFilm)
		if(contentIsImage && !updateIsOnSameContent) {
			loadImage(data.source,contentIsFilm,data.id)
		}
		if(contentIsText && !updateIsOnSameContent) loadText(data.source.text)
		toFinal(cubeData,false,true,data)
		
	}else if(contentState == "remove"){
		//setVideo(null)
		//if the node is closing we go to original else we go to hiden state (centered finish)
		if(nodeState == "close") toOriginal(cubeData,data)
		else if(nodeState == "switch"||nodeState == "open") toRemove(cubeData,data)
	}


	
		currentState.current = contentState
		//setCurrent(data)
		currentCubeData.current =cubeData
		currentContent.current = data
		
	}
	
	
	const clearTimers = ()=>{
		timers.current.forEach((t:any)=>{
			clearTimeout(t)
		})
		timers.current = []
	}
	
	useEffect(()=>{
		update(content)
	},[content,particleGroup])

	
	useEffect(()=>{
		return ()=>{
			if(content.type == "film" && ready.value){
				clearVideo(ready.value)
			}
			if(rotationInterval.current) clearInterval(rotationInterval.current)
		}
	},[content,ready])
	//----------------------animation functions-----------------------
	
	const fromTo=(cubeData:any,from:any,to:any,delay:any,cfg:any,immediate:any,reverseDelay:any,delayOverride:any)=>{
		set((index:any)=>{
			var props:any = {}
			var cube = cubeData[index]
			
			if(from) props.from = {...cube[from]}
			props.to = typeof(to) == "object"?to:{...cube[to]}
			if(props.to.position && props.to.world) {
				var position = new Vector3(props.to.position[0],props.to.position[1],props.to.position[2])
				particleGroup.worldToLocal(position)
				props.to.position = [position.x,position.y,position.z]
			}
			if(props.from && props.from.position && props.from.world) {
				var position = new Vector3(props.from.position[0],props.from.position[1],props.from.position[2])
				particleGroup.worldToLocal(position)
				props.from.position = [position.x,position.y,position.z]
			}
			if(delay>0) props.delay=delayOverride?delay*index:reverseDelay? cube.cubeIndexReverse*delay:delay*cube.cubeIndex
			if(cfg) props.config = cfg
			if(immediate){
				props.delay=0
				props.immediate = immediate
				props.reset = immediate
			}
			if(editMode) props.immediate = true
			return props
		})
	}

	const clearVideo=(videoElement:any)=>{
		
			videoElement.pause()
			videoElement.removeAttribute('src'); // empty source
			videoElement.load();

		
	}
	const toOriginal=(cubeData:any,data:any)=>{
		if(data.type == 'film' && ready.value) clearVideo(ready.value)
		ready.next(null)
		if(rotationInterval.current) clearInterval(rotationInterval.current)
		setContentProps({
			opacity:0.0,
			videoOpacity:0.0,
			backgroundOpacity:0.0,
			imageOpacity:0.0,
			textOpacity:0.0,
			
			duration:400,
			native:true
		})
		set((index:any)=>{
			return {
				controlScale:[0.00000001,0.0000001,0.00000001],
				immediate:true
			}
		})
		//fromTo(cubeData,null,'particle',30,{friction:30,tension:220,mass:1},false,true)
		var totalDuration:any = 0
		var minSpinDuration:any = 100
	//	setTimeout(()=>{
			var angle = 0
			//var position:any = new Vector3(content.geometry.position[0],content.geometry.position[1],content.geometry.position[2])
			
			rotationInterval.current = setInterval(()=>{
				window.requestAnimationFrame(()=>{
					var position:any = new Vector3(0,0,0)
					particleGroup.worldToLocal(position)
					rotateParticles(angle+=0.3,30,cubeData,null,[position.x,position.y,position.z],true,false,true,false,{friction:30,tension:220,mass:3})
					if(minSpinDuration < totalDuration){
						clearInterval(rotationInterval.current)
						fromTo(cubeData,null,"fragmented",20,{friction:30,tension:120,mass:2},false,true,false)
						timers.current.push(setTimeout(()=>{
							onReady({nodeState:data.nodeState,contentState:data.state})
							fromTo(cubeData,null,'original',10,{friction:30,tension:220,mass:1},false,true,false)
						},800))
						
						timers.current.push(setTimeout(()=>{
							fromTo(cubeData,null,'hide',0,{friction:30,tension:220,mass:1},true,true,false)
						},1900))
						timers.current.push(setTimeout(()=>{onReady({nodeState:data.nodeState,contentState:data.state,remove:true})},2300))
						
						
					}
				totalDuration+=5
				})
				
			},5)
			
		//},200)
	}
	const toRemove=(cubeData:any,data:any)=>{
		if(data.type == 'film' && ready.value) clearVideo(ready.value)
		ready.next(null)
		var angle =0
		var radius = 30
		var totalDuration:any = 0
		var minSpinDuration:any = 500
		if(rotationInterval.current) {
			clearInterval(rotationInterval.current)
		}
		setContentProps({
			backgroundOpacity:0.0,
			videoOpacity:0.0,
			imageOpacity:0.0,
			opacity:0.0,
			textOpacity:0.0,
			
			immediate:true
		})
		set((index:any)=>{
			return {
				controlScale:[0.00000001,0.0000001,0.00000001],
				immediate:true
			}
		})
		rotationInterval.current = setInterval(()=>{
			
			var position:any = new Vector3(0,0,0)
			//data.contentsBounds.getCenter(position)
			particleGroup.worldToLocal(position)
			rotateParticles(angle+=0.3,radius,cubeData,null,[position.x,position.y,position.z],true,false,false,true,{friction:30,tension:220,mass:1})
			if(minSpinDuration < totalDuration){
				clearInterval(rotationInterval.current)
				fromTo(cubeData,null,{position:[position.x,position.y,position.z],scale:[0.0001,0.0001,0.0001]},20,{friction:30,tension:220,mass:1},false,false,true)
				timers.current.push(setTimeout(()=>{
					onReady({nodeState:data.nodeState,contentState:data.state,remove:true})
				},800))
			}
			totalDuration+=20
			radius-=1
			radius = Math.max(1,radius)
		},20)
	}
	const toFinal=(cubeData:any,fromInitialState:any,fromParticleState:any,data:any)=>{
		if(data.type == 'film' && ready.value) clearVideo(ready.value)
		if(rotationInterval.current) {
			clearInterval(rotationInterval.current)
		}
		
		if(!editMode){
			setContentProps({
				backgroundOpacity:0.0,
				videoOpacity:0.0,
				imageOpacity:0.0,
				textOpacity:0.0,
				
				opacity:0.0,
				immediate:true,
				native:true
			})
			var position:any = new Vector3()
			position.set(0,0,0)
			particleGroup.worldToLocal(position)
		if(fromInitialState){
			fromTo(cubeData,"initial","initial",0,null,true,false,false)
			fromTo(cubeData,"initial",'fragmented',20,{friction:30,tension:220,mass:10},false,false,false)
		}else if(fromParticleState){
			//fromTo(cubeData,null,"initial",0,{},true,false)
			
			if(data.state=="add") fromTo(cubeData,'remove','particle',0,{friction:30,tension:120,mass:1},true,false,true)
			//fromTo(cubeData,"initial",'fragmented',10,{friction:30,tension:120,mass:2},false,false)
			//fromTo(cubeData,null,'particle',30,{friction:30,tension:220,mass:1},false,false)
			set((index:any)=>{
				return {
					controlScale:[0.00000001,0.0000001,0.00000001],
					immediate:true
				}
			})
			if(data.state=='morph') fromTo(cubeData,null,'particle',50,{friction:30,tension:120,mass:30},false,false,true)
		}
	}
		var radius = 20
		var totalDuration:any = 0
		var minSpinDuration:any = editMode? 0:2000
		var rotationSpeed:any = 0.2
		var minSpinChangeDuration:any = 800
		var angle = 0//Math.random()*Math.PI*2
		var mass = 10
		timers.current.push(setTimeout(()=>{
			
			
			rotationInterval.current = setInterval(()=>{

				window.requestAnimationFrame(()=>{
					var position:any = new Vector3()
				
				
				
					position.set(0,0,0)
					
				particleGroup.worldToLocal(position)
				/*if(minSpinDuration-totalDuration < 200){
					rotateParticles(angle+=rotationSpeed,radius,cubeData,'final',null,true,false,false,false,{friction:50,tension:220,mass:mass})
				}else{
					
				}*/
				
				rotateParticles(angle,radius,cubeData,null,[position.x,position.y,position.z],true,false,false,false,{friction:30,tension:220,mass:3})
				if(isReady(data.type) && minSpinDuration < totalDuration){
					
					clearInterval(rotationInterval.current)
					
					setContentProps({
						opacity:0.3,
						
					})
					fromTo(cubeData,null,'final',20,{friction:30,tension:140,mass:1},false,false,true)
					
					
					timers.current.push(setTimeout(()=>{
						
						setContentProps({
							videoOpacity:data.type == 'film'?1.0:0.0,
							imageOpacity:data.type == 'photo' || (data.type=='film'&&data.source&&data.source.pathHigh)?1.0:0.0,
							textOpacity:data.type == 'text'?1.0:0.0,
						//	completeScale:[1,1,1],
						opacity:0.05,
							delay:100,
							duration:2000
						})
						onReady({nodeState:data.nodeState,contentState:data.state})
					},editMode? 0:250))
				}
				totalDuration+=20
				angle+=rotationSpeed
				//mass+= totalDuration>250?-0.04:0.07
				//mass = Math.max(10,Math.min(mass,20))
				})
				
			},5)
			
		},fromInitialState && !editMode?200:fromParticleState?100:0))
		
	}
	
	
	
	const rotateParticles=(angle:any,scaleFactor:any,cubeData:any,positionKey:any, position:any,randomZ:any,flipZAndX:any,reverseDelay:any,delayOverride:any,config:any)=>{
		
		var x = Math.cos(angle)*scaleFactor
		var z = -Math.sin(angle)*scaleFactor
		var y = Math.sin(angle)*scaleFactor
		//var z =  *scaleFactor
		
		set((index:any)=>{	
			var cube = cubeData[index]
			//var z = randomZ?  (Math.random()*scaleFactor - scaleFactor/2):0
			if(flipZAndX){
				var tempX = y
				y = z
				z = tempX
			}
			y *= cube.randomZ
			x *= cube.randomX
			var cubePosition = position? [position[0]+x,position[1]+y,position[2]+z]:[cube[positionKey].position[0]+x,cube[positionKey].position[1]+y,cube[positionKey].position[2]+z]
			return {
				
				position:cubePosition,
				scale:[0.5,0.5,0.5],
				opacity:1.0,
				rotation:[cube.final.rotation[0],cube.final.rotation[1],cube.final.rotation[2]],
				delay:delayOverride? 10*index:reverseDelay? 20*cubeData[index].cubeIndexReverse:20*cubeData[index].cubeIndex,
				config:config,
				native:true
			}
		})
		
	}
	
	
	

	const toggleVideoPlayback=()=>{
		//pause
		console.log("toggling playback",content)
		var video = ready.value
		if(!video || content.type != 'film') return
		const isVideoPlaying = !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
		console.log("toggling video playback",isVideoPlaying)
		if(isVideoPlaying){
			video.pause()
			setContentProps({
				backgroundOpacity:1.0,
				videoOpacity:0.7
			})
			setPaused(true)
		}
		else{
			setContentProps({
				backgroundOpacity:0.0,
				imageOpacity:0.0,
				videoOpacity:1.0
			})
			video.play()
			setPaused(false)
		}
	}
	const toggleVideoExpand=(expand:any)=>{
		if(expand) fitToContent(contentMesh)
		else{fitToAll()}
		setExpanded(expand)
	}
	const setVideoTime = (progress:any,play:any)=>{
		//var width = event.point.x - (content.geometry.position[0] - durationBarWidth/2)
		//	var progress = width/durationBarWidth
			//var duration1Width = progress*durationBarWidth
			var video = ready.value
			video.currentTime = video.duration*progress
			setVideoProgress(progress)
			const isVideoPlaying = !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
			if(!isVideoPlaying && play) toggleVideoPlayback()
	}
	

	useFrame(()=>{
		if(videoTexture.current && content && content.type=="film" && ready.value && !ready.value.paused){
			//if(!video) videoTexture.dispose() 
			videoTexture.current.needsUpdate = true;
			
		}
	
		
		/*if(video && stable){
			var progress = video.currentTime/video.duration
			var duration1Width = progress*durationBarWidth
			//console.log(progress,duration1Width)
			
			updateVideoProgress(duration1Width,durationBarWidth)
			updateVideoCursor(duration1Width,durationBarWidth)
			
			//compute the width of the duration bar based on the currentTime and the width of the entire bar
		}*/
		//console.log(contentMesh,particlesRef.current)
		if(contentGeometry && particlesRef.current.length >= 2){
			var c1 = particlesRef.current[0].position
			var c2 = particlesRef.current[0].position.clone()
			var c3 = particlesRef.current[1].position
			var c4 = particlesRef.current[1].position.clone()
			c2.setComponent(0,c3.x)
			c4.setComponent(0,c1.x)
			//console.log("corners",c1,c2,c3,c4)
			var vertices = new Float32Array( [
				c1.x,c1.y,c1.z,
				c2.x,c2.y,c2.z,
				c3.x,c3.y,c3.z,

				c3.x,c3.y,c3.z,
				c4.x,c4.y,c4.z,
				c1.x,c1.y,c1.z,
				
			] );

			var uvs = new Float32Array( [
				0.0, 0.0,
				1.0, 0.0,
				1.0, 1.0,

				1.0, 1.0,
				0.0, 1.0,
				0.0,0.0
				]);
				
			contentGeometry.setAttribute( 'uv', new BufferAttribute( uvs, 2 ) );
			contentGeometry.setAttribute( 'position', new BufferAttribute( vertices, 3 ) );
			//contentGeometry.setAttribute('normal',new BufferAttribute(normals, 3));
			//contentGeometry.setIndex([0,1,2, 2,3,0])
			contentGeometry.attributes.position.needsUpdate = true
			contentGeometry.verticesNeedUpdate = true;
		}
		if(textMesh && contentMesh){
			
			textMesh.material.opacity = contentProps.textOpacity.get()
			contentGeometry.computeBoundingBox()
			var box = contentGeometry.boundingBox
			//console.log("textMesh",box,contentGeometry,contentMesh.geometry)
			if(!box) return
			var position = new Vector3()
			box.getCenter(position)
			//console.log("textMesh2",position,contentGeometry,textMesh)
			textMesh.position.set(position.x,position.y,position.z)
			//textMesh.sync()
		}
	})



	
	return <group >
			<a.mesh ref={contentRef} position={contentProps.position} rotation={contentProps.rotation} scale={contentProps.scale} onClick={(e:any)=>{toggleVideoPlayback()}}>
				<boxGeometry attach="geometry" args={[1,1,1]}>
       
				</boxGeometry>
      			<a.meshBasicMaterial attach="material" color="black" transparent={true} opacity={contentProps.backgroundOpacity} depthTest={false} depthWrite={false}>
      			</a.meshBasicMaterial>
			</a.mesh>

			<a.mesh ref={wireframeRef} position={contentProps.position} rotation={contentProps.rotation} geometry={contentGeometry} >
			
      			<a.meshBasicMaterial attach="material" color="white" transparent={true} opacity={contentProps.opacity} depthTest={false} depthWrite={false} wireframe={true}>
      			</a.meshBasicMaterial>
			</a.mesh>
			<a.mesh ref={videoRef} frustumCulled={false} position={contentProps.position} rotation={contentProps.rotation} geometry={contentGeometry} >
				<a.meshBasicMaterial attach="material" color="white" transparent={true} opacity={contentProps.videoOpacity} depthTest={false} depthWrite={false}>
        			 
					 </a.meshBasicMaterial>
			</a.mesh>

			<a.mesh ref={imageRef} frustumCulled={false} position={contentProps.position} rotation={contentProps.rotation} geometry={contentGeometry} >
				<a.meshBasicMaterial attach="material" color="white" transparent={true} opacity={contentProps.imageOpacity} depthTest={false} depthWrite={false}>
        			 
					 </a.meshBasicMaterial>
			</a.mesh>
			{content.type == "text" && <a.group position={contentProps.position} rotation={contentProps.rotation} frustumCulled={false} >
		{/*

		@ts-ignore */}		
		<Text	
					ref={textRef}
					
					{...{
						
						fontSize:content.style.fontSize,
						color:content.style.color,
						maxWidth:content.geometry.scale[0]-4,
						lineHeight:content.style.lineHeight,
						letterSpacing:content.style.letterSpacing,
						textAlign:content.style.textAlign,
						
						font:fontUrls[(!content.style.bold && !content.style.italic)? "normal":(content.style.bold?"bold":"") + (content.style.italic?"italic":"")],
						anchorX:"center",
						anchorY:"middle"
					}}
					
					
				  >
					 {content.source? content.source.text:"placeholder text"} 
					  <meshBasicMaterial ref={textMaterialRef} attach="material" color={"white"} opacity={1.0}  depthWrite={false}/>
					 {/*

		@ts-ignore */}	
				  </Text>
				  
		</a.group>}
		<a.group ref={particleGroupRef} position={contentProps.position} rotation={contentProps.rotation}>
						
		{springs && springs.map((props:any,index:any)=>{
				return <a.group key={index} >
		
					<a.mesh ref={(el:any) => particlesRef.current[index] = el} scale={props.scale} visible={props.visible} position={props.position} rotation={props.rotation} frustumCulled={false} dispose={null}>
						<boxBufferGeometry attach="geometry" args={[1,1,1]}></boxBufferGeometry>
						<a.meshStandardMaterial attach="material"  color={ props.color} transparent={true} opacity={props.opacity} depthTest={false} depthWrite={false}  />	
					</a.mesh>


					<a.group scale={props.controlScale} position={props.position} rotation={props.rotation}>
					{index > 1 && index < 4 && <PlayButton key={index}  pause={paused} autoFadeOnPlay={index==3} autoFadeDelay={500} onToggle={()=>{toggleVideoPlayback()}}></PlayButton>}
					{index == 4 && <DurationSlider  video={ready} onCursorChange={(progress:any,play:any)=>{setVideoTime(progress,play)}} totalWidth={durationBarWidth}></DurationSlider>}
					{index == 5 && <FullscreenButton key={index}  expand={expanded} onToggle={(e:any)=>{toggleVideoExpand(e)}}></FullscreenButton>}
					</a.group>
					
				</a.group>
				
				
			
			
			})}
			</a.group>
	</group>


}