
import { Canvas, useFrame,useResource,useThree } from 'react-three-fiber'
import * as React from 'react'
import { useRef, useState,useMemo, useEffect } from 'react'
import {useSpring,a, animated,config} from 'react-spring/three'
import Node from './node.component'
import { cameraToWorldCoords, easeInOutCirc, rotateVectorAroundPivotPoint, screenToCameraCoords, screenToWorldCoords } from '../helperFunctions'
//import { MouseCrossair } from './mouse-crossair.component'


export default function Tree(props:any){
	var [zoom,setZoom]:any = useState(0)
	var [focus,setFocus]:any = useState(props.focus)
	//console.log(props.dateRange)
	const {gl,camera}:any = useThree()
	const [treeRef,treeMesh]:any = useResource()
	const [animatedProps,update,stop]:any = useSpring(()=>{
		return {
			timelinePosition:[0,0,0],
			timelineScale:[1,1,1],
			timelineRotation:[0,0,0],
			native:true,
			config:config.stiff
		}
	})
	
	
	/*const minimize = (immidiate:any)=>{
		//push the tree down to bottom
		camera.orbit.enabled = false
		var el = gl.domElement
		var w = el.clientWidth
		var h = el.clientHeight
		var cameraVector = screenToCameraCoords(w-150,h-120,camera,el,0)
		var position = new THREE.Vector3(cameraVector.x, cameraVector.y, cameraVector.z);
		position.unproject( camera );

		//var coords = [vector.x,-vector.y,0]
		//console.log("on focus change", el,w,h,position,cameraVector,camera)
		update({
			timelinePosition:[position.x,position.y,position.z],
			timelineScale:[0.07,0.07,0.07],
			immediate:immidiate
		})
	}*/
	
	/*useEffect(()=>{
		//if(props.minimize) minimize(false)
	},[props.minimize])
	useEffect(()=>{
		//rotate group based on dimension 3 or 3
		/*if(props.dimensions == 3){
			update({timelineRotation:[0,0,0]})
		}else if(props.dimensions == 2){
			update({timelineRotation:[0,Math.PI/4,Math.PI/4]})
		}
	},[props.dimensions])*/
	
	//@ts-ignore
	return <a.group ref={treeRef} position={animatedProps.timelinePosition} scale={animatedProps.timelineScale} >
		{props.nodes.map((n:any)=>{
			return <Node 
			key={n.id}
		data={n}
		//zoom={zoom} 
		//parentExpanded={true}
		//parentFrontier={true}
		//focus={focus}
		dateRange={props.dateRange}
		zoomMode={props.zoomMode}
		
		//should dep
		currentHover={props.currentHover}
		
		openSubject={props.openSubject}
		hoverSubject={props.hoverSubject}
		readySubject={props.readySubject}
		editMode={props.editMode}
		editSubject={props.editSubject}
		onRef={props.onRef}
		flagSubject={props.flagSubject}
		/*onClick={(node:any,mesh:any,add:any)=>{onNodeClick(node,mesh,add)}}*/></Node>
		})}
		
		{props.children}
		
	</a.group>
	
}