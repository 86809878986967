import React from 'react';
import './App.css';
import { createStore,combineReducers } from 'redux'
import { connect } from 'react-redux'
import { Provider } from 'react-redux'
import { addCommitment } from './redux/actions'
import Admin from './components/admin';
import Home from './components/home';
import * as firebase from 'firebase';
import 'firebase/firestore';
import { ReactReduxFirebaseProvider, firebaseReducer } from 'react-redux-firebase'
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore' // <- needed if using firestore
import admin from './redux/reducers/admin';
import timeline from './redux/reducers/timeline';
import { useSelector } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'
// Add the Performance Monitoring library
import "firebase/performance";
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import Login from './components/login'
import AdminV2 from './components/adminv2/admin';
declare var window:any;
if ( ! ( 'createImageBitmap' in window ) ) {

	window.createImageBitmap = async function ( blob:any ) {

		return new Promise( ( resolve, reject ) => {

			let img = document.createElement( 'img' );

			img.addEventListener( 'load', function () {

				resolve( this );

			} );

			img.src = URL.createObjectURL( blob );

		} );

	};

}
  
var firebaseConfig = {
  apiKey: "AIzaSyBhdGCubaVmfSODyY6J5q4JezDIHbbqPkM",
  authDomain: "vincentspacetime.firebaseapp.com",
  databaseURL: "https://vincentspacetime.firebaseio.com",
  projectId: "vincentspacetime",
  storageBucket: "vincentspacetime.appspot.com",
  messagingSenderId: "869859470421",
  appId: "1:869859470421:web:555cf670693901ce8d78dc",
  measurementId: "G-99V61ELS9L"
};

// react-redux-firebase config
const rrfConfig = {
userProfile: 'users',
// useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
}

// Initialize firebase instance

firebase.initializeApp(firebaseConfig)
// Initialize other services on firebase instance
firebase.firestore() // <- needed if using firestore



// Initialize Performance Monitoring and get a reference to the service
const perf = firebase.performance();
firebase.analytics();

// Add firebase to reducers
const rootReducer = combineReducers({
firebase: firebaseReducer,
firestore: firestoreReducer, // <- needed if using firestore
admin:admin,
timeline:timeline
})

// Create store with reducers and initial state
const initialState = {}
const store = createStore(rootReducer, initialState)

const rrfProps = {
firebase,
config: rrfConfig,
dispatch: store.dispatch,
createFirestoreInstance // <- needed if using firestore
}

class App extends React.Component<any, any>  {
 
  
  render(){
    console.log("hello",store,rrfProps);
    return (
      <div className="App">
        <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <Router>
          <Switch>
          <Route path="/projects/:id">
            
              <Home></Home>
            </Route>
            <Route path="/search/:id?">
             
              <Home></Home>
            </Route>
            <Route exact={true} path="/">
             
             <Home></Home>
           </Route>
            <Route path="/login">
              
              <Login />
            </Route>
            <PrivateRoute path="/admin">
            <div>admin1</div>
            </PrivateRoute>
            <PrivateRoute path="/admin-timeline">
              <AdminV2></AdminV2>
            </PrivateRoute>
          </Switch>
        </Router>
      </ReactReduxFirebaseProvider>
    </Provider>
       

        {/*<ForceLayout></ForceLayout>
        <Timeline></Timeline>*/}
      </div>
    );
  }
  
}
{/*
//@ts-ignore*/}
function PrivateRoute({ children, ...rest }) {
  const auth = useSelector((state:any) => state.firebase.auth)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoaded(auth) && !isEmpty(auth) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default connect(
  null,
  { addCommitment}
)(App)
  
