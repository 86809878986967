import * as React from 'react'
import {useEffect, useState,useRef} from 'react'
import { FiSearch } from 'react-icons/fi'
import {CgClose} from 'react-icons/cg'
import {VscClose} from 'react-icons/vsc'
import {a,useSpring,config} from 'react-spring'
import "./search-input.css"
export default function SearchInput({onSearch,onOpen,delay,flagSubject,onClose}:any){
	const searchInputRef:any = useRef();
	const [searchOpen,setSearchOpen]:any = useState(false)
	const [hide,setHide]:any = useState(true)
    const searchProps = useSpring({
		width:searchOpen? "300px":"50px",
		background:searchOpen&&!hide? "rgba(61, 62, 64,1)":"rgba(61, 62, 64,1)",
		transform:!searchOpen||hide?"translate3d(0px,0px,0) scale(0.7)":"translate3d(0px,0px,0) scale(1)",
		opacity:hide?0.0:1.0,
		native:true,
		config:config.stiff
	})
	const searchDoneProps = useSpring({
		transform:!searchOpen||hide?"scale(0.0)":"scale(1.0)",
		opacity:searchOpen&&!hide?1.0:0.0,
		//pointerEvents:searchOpen&&!hide?'all':
		native:true,
		delay:searchOpen&&!hide?300:0,
		config:config.stiff
	})
	const searchSearchProps = useSpring({
		opacity:searchOpen||hide?0.0:1,
		transform:hide?"scale(1.0)":!searchOpen?"scale(1.0)":"scale(0.5)",
		native:true,
		config:config.stiff
	})
	const searchInputProps = useSpring({
		opacity:searchOpen&&!hide?1.0:0.0,
		transform:!searchOpen||hide?"translate3d(-300px,0px,0) scale(1.0)":"translate3d(0px,0px,0) scale(1.0)",
		config:config.stiff,
		native:true
	})
	const clearSearch = ()=>{
		setSearchOpen(false)
		//onSearch("")
		onClose()
		
	}
	const [query, setQuery] = useState("");
	useEffect(() => {
        if(!searchOpen) return
        const timeOutId = setTimeout(() => onSearch(query), delay);
        return () => clearTimeout(timeOutId);
	  }, [query,searchOpen])
	
	  const toggleSearch = (open:any,propagate:any)=>{
		setSearchOpen(open)
		if(propagate && open) onOpen()
	  }

	useEffect(()=>{
		var sub = flagSubject.subscribe(({entered,loaded,minimize,fullscreen,searching, searchTerm}:any)=>{
			var h = !entered || !loaded || fullscreen || minimize
			setHide(h)
			if(!h && searchInputRef.current) searchInputRef.current.focus()

			if(searching && !searchOpen) {
				setQuery(searchTerm)
				toggleSearch(true,false)
			}
		})
		return ()=>{
			sub.unsubscribe()
		}
	},[flagSubject])
	return (
		<div className="search-input">
		
		
		<a.div className="searchInputWrapper" style={searchProps}>
		<a.button style={searchSearchProps} className="searchButton" onClick={()=>!hide && toggleSearch(!searchOpen,true)}><FiSearch/></a.button>
		<a.input ref={(mount:any)=>searchInputRef.current = mount} style={searchInputProps} placeholder="type here" value={query} onChange={(e:any)=>{setQuery(e.target.value)}}/>
		<a.button style={searchDoneProps} className="searchButtonDone" onClick={()=>clearSearch()}><VscClose/></a.button>
		</a.div>
       
		</div>
	)
}