import * as React from 'react'
import {useEffect,useState} from 'react'

export default function Logo({onOpen,flagSubject,device}:any){
	const [hide,setHide]:any = useState(true)
	const [loading,setLoading]:any = useState(true)
	const [message,setStatus]:any = useState("Building Experience..")
	useEffect(()=>{
		var sub = flagSubject.subscribe(({entered,loaded,minimize,fullscreen,status}:any)=>{
			setHide(entered)
			setLoading(!loaded)
			setStatus(status)
		})
		return ()=>{
			sub.unsubscribe()
		}
	},[flagSubject])
	return ( <div className={`introText ${hide? 'hide':''} ${loading?'loading':''}`} >
		<h2>{message}</h2>
		<p><span>{device=='mobile'? "The mobile AR experience is not ready for prime time yet. Please view the desktop version on your computer for now.":"by Vincent Jonsson Qi"}</span></p>
	{device != 'mobile' && <button onClick={()=>{onOpen()}}>ENTER</button>}
</div>)
}