import * as React from 'react'
import  { useCallback,useMemo,useEffect ,useState,useRef} from 'react'
import {a,useSpring} from 'react-spring/three'
import {useFrame, useResource} from 'react-three-fiber'
import { BehaviorSubject } from 'rxjs'
import {Vector3} from 'three'
export default function DurationSlider({video,onCursorChange,totalWidth}:any){
	var status:any = useMemo(()=>{return new BehaviorSubject("idle")},[])
	var [durationRef,durationMesh]:any = useResource()
	var [durationBackgroundRef,durationBackgroundMesh]:any = useResource()
	var [cursorRef,cursorMesh]:any= useResource()
	var counter:any = useRef(0)
	var [duration1Props,duration1Update,duration1Stop]:any = useSpring(()=>{
		return {
			scale:[0.001,0.001,0.001],
			position:[0,0,0],
			color:"white",
			opacity:0.2
		}
	})

	var [duration2Props,duration2Update,duration2Stop]:any = useSpring(()=>{
		return {
			scale:[0.001,0.001,0.001],
			position:[0,0,0],
			color:"white",
			opacity:1
		}
	})
	var [cursorProps,cursorUpdate,stopCursor]:any = useSpring(()=>{
		return {
			scale:[0.001,0.001,0.001],
			position:[0,0,0],
			rotation:[Math.PI/4,0,0],
			color:"white",
			opacity:1
		}
	})

	
	
	useEffect(()=>{
		/*var sub = particleMode.subscribe((isParticle:any)=>{
			if(isParticle){ 
				update({
			opacity:1
		})
		duration1Update({
			scale:[0.001,0.001,0.001],
			opacity:0.0
		})
		duration2Update({
			scale:[0.001,0.001,0.001],
			opacity:0.0
		})
		cursorUpdate({
			scale:[0.001,0.001,0.001],
			opacity:0.0
		})
		}
		else{
			 update({
			opacity:0
		})
		duration1Update({
			scale:[totalWidth,1,0.001],
			opacity:0.2,
			delay:800
		})
		duration2Update({
			opacity:1
		})
		cursorUpdate({
			opacity:1
		})
		
	}
})*/
var videoSub = video.subscribe((vid:any)=>{
	if(vid) {
		updateTime(null,vid)
		duration1Update({
			scale:[totalWidth,1,0.001],
			delay:800
		})
	}
})
return ()=>{
	//sub.unsubscribe(); 
	videoSub.unsubscribe()}
	},[video,totalWidth])

	const updateTime=(progress:any,video:any)=>{
		if(!durationMesh) return
		if(!video) return
		progress = progress?progress:video.currentTime/video.duration
		var progressWidth = progress*totalWidth
		durationMesh.scale.set(progressWidth,1,0.001)
		durationMesh.position.set(-totalWidth/2 + progressWidth/2,durationMesh.position.y,durationMesh.position.z)
		cursorMesh.scale.set(0.8,0.8,0.1)
		cursorMesh.position.set(-totalWidth/2 + progressWidth,cursorMesh.position.y,cursorMesh.position.z)
		
	}
	
	const onClick = (e:any,play:any)=>{
		console.log("clicked slider",e);
		
		if(e) e.stopPropagation()
		var vector:any = new Vector3()
		durationBackgroundMesh.getWorldPosition(vector)
		var width:any = e.point.x - (vector.x - totalWidth/2)
		var progress = width/totalWidth
		console.log("will udpate time",progress,width)
		onCursorChange(progress,play)

	}
	const dragStart = (e:any)=>{
		onClick(e,false)
		if(video.value) video.value.pause()
		status.next("drag")
	}
	const dragEnd = (e:any)=>{
		onClick(e,true)
		status.next("idle")
	}
	const dragMove = (e:any)=>{
		if(status.value == "drag") onClick(e,false)
	}
	useFrame(()=>{
		if(!video.value) return
		updateTime(null,video.value)
	})
	
	//console.log("rerender duration slider", counter.current+=1)

	return (<a.group>
		
		
		
		
			<a.mesh  frustumCulled={false} dispose={null} scale={duration1Props.scale} onClick={(e:any)=>{onClick(e,true)}} onPointerDown={(e:any)=>{dragStart(e)}} onPointerUp={(e:any)=>{dragEnd(e)}} onPointerMove={(e:any)=>{dragMove(e)}}>
					<boxBufferGeometry attach="geometry" args={[1,10,1]}></boxBufferGeometry>
					<a.meshStandardMaterial attach="material"  color={ "white"} transparent={true} opacity={0.0} depthTest={false} depthWrite={false}  />	
			</a.mesh>


			<a.mesh ref={durationBackgroundRef} frustumCulled={false} scale={duration1Props.scale} position={duration1Props.position} dispose={null}>
				<boxBufferGeometry attach="geometry" args={[1,1,1]}></boxBufferGeometry>
				<a.meshStandardMaterial attach="material"  color={ duration1Props.color} transparent={true} opacity={duration1Props.opacity} depthTest={false} depthWrite={false}  />	
			</a.mesh>


			<a.mesh ref={durationRef} frustumCulled={false} scale={duration2Props.scale}  position={duration2Props.position} dispose={null}>
				<boxBufferGeometry attach="geometry" args={[1,1,1]}></boxBufferGeometry>
				<a.meshStandardMaterial attach="material"  color={ duration2Props.color} transparent={true} opacity={duration2Props.opacity} depthTest={false} depthWrite={false}  />	
			</a.mesh>

			<a.mesh ref={cursorRef} frustumCulled={false} scale={cursorProps.scale}  position={cursorProps.position} dispose={null}>
				<circleGeometry attach="geometry" args={[1,32]}></circleGeometry>
				<a.meshStandardMaterial attach="material"  color={ cursorProps.color} transparent={true} opacity={cursorProps.opacity} depthTest={false} depthWrite={false}  />	
			</a.mesh>


		
		
		</a.group>)
}