
import Timeline from '../home/timeline/timeline.component'
import { useSelector } from 'react-redux'
import { useFirebaseConnect, isLoaded, isEmpty, useFirestoreConnect,useFirestore, useFirebase } from 'react-redux-firebase'
import * as React from 'react'
import {useState,useMemo,useEffect} from 'react'
import {AiFillPlusCircle, AiOutlineExpandAlt, AiOutlineRotateLeft} from 'react-icons/ai'
import {useParams} from 'react-router'
import './adminv2.css'
import uuid from 'uuid/v1'
import NodeForm from './node-form/node-form.component'
import { IoMdCut } from 'react-icons/io'
import ContentForm from './content-form/content-form.component'
import { BsArrowsMove } from 'react-icons/bs'
import { BiPaste } from 'react-icons/bi'
import { BehaviorSubject } from 'rxjs'
export default function AdminV2(props:any){
	const graphId:any = "SpacetimeTimeline"
	const firestore:any =  useFirestore()
	const firebase:any = useFirebase()
	/*useFirestoreConnect(() => [
		{ collection: 'Graphs', doc: graphId } // or `todos/${props.todoId}`
		,{collection:'Nodes', where: [["graphId", "==", graphId]]}
		,{collection:'Edges', where: [["graphId", "==", graphId]]}
	  ])
	
	const graph = useSelector((state:any) => state.firestore.data.Graphs && state.firestore.data.Graphs[graphId])
	const nodes = useSelector((state:any) => state.firestore.ordered.Nodes)
	const edges = useSelector((state:any) => state.firestore.ordered.Edges)*/


	const [nodes,setNodes] = useState([])//useSelector((state:any) => state.firestore.ordered.Nodes)
  const [edges,setEdges] = useState([])//useSelector((state:any) => state.firestore.ordered.Edges)
  

  useEffect(()=>{
    var edgesCollectionRef = firestore.collection('Edges')
    var nodesCollectionRef = firestore.collection('Nodes')
    var unsubscribeToEdgeUpdates = edgesCollectionRef.where("graphId", "==", graphId).onSnapshot((querySnapshot:any)=>{
      const docs:any = []
      querySnapshot.forEach((d:any)=>{docs.push(d.data())})
      console.log("edges",docs)
      setEdges(docs)
      unsubscribeToEdgeUpdates()
    })

    var unsubscribeToNodeUpdates = nodesCollectionRef.where("graphId", "==", graphId).onSnapshot((querySnapshot:any)=>{
      const docs:any = []
      querySnapshot.forEach((d:any)=>{docs.push(d.data())})
      console.log("nodes",docs)
      setNodes(docs)
      unsubscribeToNodeUpdates()

    })

    return ()=>{
      unsubscribeToEdgeUpdates()
      unsubscribeToNodeUpdates()
    }
  },[])
	const zoomMode = "drilldown"
	var [levels,setLevels]:any = useState([])
	var [tab,setTab]:any = useState("node")
	var [current,setCurrent]:any = useState(null)
	var [currentContent,setCurrentContent]:any = useState(null)
	var [clipboard,setClipBoard]:any = useState(null)
	var [transformMode,setTransformMode]:any = useState("translate")
	var editSubject:any = useMemo(()=>new BehaviorSubject(null),[])
	let { id }:any = useParams();
	const createNewGraph = ()=>{
		firestore.set('Graphs/'+graphId, {id:graphId})
		addNewNode()
	  }
	  useEffect(()=>{
		var sub = editSubject.subscribe((n:any)=>{
			console.log("edit node changed")
		  setCurrent(n)
		})
		return ()=>sub.unsubscribe()
	},[])



	const resize=(width:any,height:any)=>{
		var d:any;
		console.log("resize event",width,height)
		if(width < 700) d = 'mobile'
		else d = 'desktop'
		return d
	  }
	  const [device,setDevice] = useState(resize(window.innerWidth,window.innerHeight))
	  useEffect(()=>{
		  
		window.addEventListener('resize', (e:any)=>{
		  console.log("resize event",e)
		  setDevice(resize(e.srcElement.innerWidth,e.srcElement.innerHeight))
		})
	  },[])



	  const addNewNode = ()=>{
		//we presume that the user wants to add a new node at the current level
		var id = uuid()
		var level = current?current.level+1:0
		firestore.set('Nodes/'+id, {
			id:id,
			graphId:graphId,
			title:"add a title",
			subheadline:"",
			color:"#00000",
			
			tags:["these","are","tags"],
			//location Ref
			location:null,
			end:new Date(),
			level:level,
			start:new Date(new Date().setMonth(new Date().getMonth()-5))
		  })
		
		
		

		if(current){
			var edgeId = uuid()
			firestore.set('Edges/'+edgeId, {
				id:edgeId,
				graphId:graphId,
				parentId:current.id,
				childId:id
			})
		}
	}
	const cutNode=()=>{
		//use clipboard and current to query firestore to remove all edges that has the clipbord node 
		//as a childId
		var query = firestore.collection('Edges').where('childId','==',current.id);
		query.get().then((querySnapshot:any)=> {
		  querySnapshot.forEach((doc:any)=> {
			doc.ref.delete();
		  });
		});
		setClipBoard(current)
	}

	const pasteNode=()=>{

		
		//then create a new edge to with parentId = current.id and childId = clipboard.id
		if(clipboard){
			var edgeId = uuid()
			firestore.set('Edges/'+edgeId, {
				id:edgeId,
				graphId:graphId,
				parentId:current.id,
				childId:clipboard.id
			})
			firestore.update('Nodes/'+clipboard.id, {
				level:current.level+1
			})
		}


		setClipBoard(null)

	}
	/*
	if(isEmpty(graph)){
		return <button className="newGraph" onClick={()=>{createNewGraph()}}><AiFillPlusCircle/> Create new graph</button>
	}*/

	if (!isLoaded(nodes) && !isLoaded(edges)) {
		return <div>Loading...</div>
	  }
	
	  
	  const onZoomUpdate = async (ls:any)=>{
		levels = ls
		await setCurrent(ls[ls.length-1].node)
		console.log("current",levels,current,ls[ls.length-1].node)
	  }
	  
	

	  
		  console.log("nodes",nodes)
		return <div className="admin">
			<div className="transformModeButtons">
				<button style={transformMode == "scale"? {background:"white",color:"black"}:{}} onClick={()=>{setTransformMode("scale")}}><AiOutlineExpandAlt/></button>
				<button style={transformMode == "rotate"? {background:"white",color:"black"}:{}} onClick={()=>{setTransformMode("rotate")}}><AiOutlineRotateLeft/></button>
				<button style={transformMode == "translate"? {background:"white",color:"black"}:{}} onClick={()=>{setTransformMode("translate")}}><BsArrowsMove/></button>
				<button className="newNode" onClick={()=>{addNewNode()}}><AiFillPlusCircle/></button>
				<button className="cutNode" onClick={()=>{clipboard? pasteNode():cutNode()}}>{!clipboard?<IoMdCut/>:<BiPaste/>}</button>
			</div>
			
			{nodes.length > 0 && edges && <Timeline  editMode={true} editSubject={editSubject} firebase={firebase} firestore={firestore} nodes={nodes} edges={edges} zoomMode={zoomMode} onZoomUpdate={(levels:any)=>onZoomUpdate(levels)} device={device} nodeId={id} transformMode={transformMode}/>}
			<div className="tabs">
			<div className="tabButtons">
				<button onClick={()=>setTab("node")}>Node</button>
				<button onClick={()=>setTab("content")}>Content</button>
			</div>
			{tab=="node" && current && <NodeForm id={current.id} onContentSelect={(content:any)=>{setCurrentContent(content); setTab('content')}}></NodeForm>}
			{tab=="content" && currentContent && <ContentForm id={currentContent.id}></ContentForm>}
			</div>
			
			
			</div>
	  
	
}