import * as React from 'react'
import {useEffect,useMemo,useRef} from 'react'
import {useThree,useFrame} from 'react-three-fiber'
import { Vector3 ,BufferGeometry,LineDashedMaterial,Line,Color} from 'three'
import {screenToWorld} from '../helperFunctions'

export default function NodeLine({node,subject}:any){
	const {camera,gl}:any = useThree()
	const geometry:any = useMemo(()=>new BufferGeometry(),[])
	const material:any = useMemo(()=>new LineDashedMaterial( {
		color: "white",
		linewidth: 1,
		scale: 1,
		dashSize: 0.5,
		gapSize: 1,
		depthWrite:false,
		transparent:true,
		opacity:0.1
	} ),[])
	const line:any = useMemo(()=>{
		var l = new Line(geometry,material)
		l.frustumCulled = false
		return l},[])
	var hoverNode:any = useRef(null) 
	useEffect(()=>{
		if(!subject) return
		subject.subscribe((e:any)=>{
			
			if(e && e.n && e.h && e.origin == 'list'){
				hoverNode.current = e.n
			}
			else hoverNode.current = null
		})
	},[subject])
	useFrame(()=>{
		if(!node && !hoverNode.current){
			line.material.opacity = 0
			return
		}
		var n = node || hoverNode.current
		var hide = false
		var pointTimeline = new Vector3(0,0,0),pointList = new Vector3(0,0,0),pointMiddle=new Vector3(0,0,0);
		if(n.mesh){
			pointTimeline = n.mesh.getWorldPosition()
		}
		if(n.listDotEl){

			const domRect:any = n.listDotEl.getBoundingClientRect();
			if(domRect.y > gl.domElement.clientHeight || domRect.y < 120) hide = true
			pointList = screenToWorld( domRect.x+domRect.width/2,domRect.y+domRect.height/2,camera,gl.domElement,0)
		}
		
		pointMiddle = new Vector3(pointTimeline.x,pointList.y,pointTimeline.z)
		var points = [pointList,pointTimeline]
		//console.log("nodeline points",points)
		geometry.setFromPoints(points)
		line.computeLineDistances()
		if(hide == true) line.material.opacity = 0
		else line.material.opacity = 1

		line.material.color.set(n.contentCount && n.contentCount > 0?n.color:"white")
	})
	
	return <primitive object={line}>
	</primitive>
}