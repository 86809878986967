

import { Canvas, useFrame,useThree, extend,useResource } from 'react-three-fiber'
import * as React from 'react'
import  { useRef, useState,useEffect,useMemo } from 'react'
//@ts-ignore
import {useSpring,a} from 'react-spring/three'
import {Html, Text} from 'drei'
import { rotateVectorAroundPivotPoint, screenToWorld, screenToWorldCoords } from '../helperFunctions'
import { BehaviorSubject } from 'rxjs'
export function MouseCrossair({layout,hoverSubject,openSubject,flagSubject}:any) {
	var current:any = useMemo(()=> new BehaviorSubject(null),[])
	var [node,setNode]:any = useState(null)
	var [hide,setHide]:any = useState(null)
	var [invert,setInvert]:any = useState(false)
	var [ref,mesh]:any = useResource()
	var [textRef,textMesh]:any = useResource()
	var {camera,gl}:any = useThree()
	var [animatedProps,update,stop]:any = useSpring(()=>{
		return{
			position:[0,0,0],
			position2:[0,0,0],
			position3:[0,0,0],
			opacityHorisontal:0.0,
			opacityVertical:0.0
		}
	})
	var [lineSegmentRef2,lineSegment2]:any = useResource()
	var [lineSegmentRef1,lineSegment1]:any = useResource()
	var [boxGeometryRef,boxGeometry]:any = useResource()
	
	if(lineSegment1) lineSegment1.computeLineDistances()
	if(lineSegment2) lineSegment2.computeLineDistances()
	
	console.log("current",current.value)
	const startDate:any = current.value && current.value.start
	const endDate:any = current.value && current.value.end
	const startDateFormatted = startDate && startDate.toString().split(' ')[1] + " " + startDate.toString().split(' ')[3]
	const endDateFormatted = endDate && endDate.toString().split(' ')[1] + " " + endDate.toString().split(' ')[3]
	const onPointerMove = (e:any)=>{
		if(hide) return
		var vector = e.point.clone()
		mesh.worldToLocal(vector)
		console.log("crossair",e,e.point.x,vector.x)
		update({
			position:[vector.x,-1,0],
			position2:[ vector.x,vector.y-1,vector.z],
			position3:[vector.x,-1,vector.z],
			opacityHorisontal:0.1,
			opacityVertical:0.1,
			immidiate:true
		})
		if(e.clientY < 150 && !invert){
			setInvert(true)
		}else if(e.clientY >=150 && invert){
			setInvert(false)
		}
	}
	
	const hidePointer = ()=>{
		update({
			opacityHorisontal:0.0,
			opacityVertical:0.0
		})
	}
	useEffect(()=>{
		var sub = hoverSubject.subscribe((e:any)=>{
			if(e && e.origin == 'node'){
				const directSwitch = e.h && current.value && current.value.id != e.n.id
				const toNothing = !e.h && current.value && current.value.id == e.n.id
				const toSomething = e.h && !current.value
				var v;
				if(e.h){
					 v = e.n
					 current.next(v)
				setNode(v)
				}
				else if(toNothing) {
					v=null
					current.next(v)
				setNode(v)
				}
				

			}
		})
		var sub2 = openSubject.subscribe((n:any)=>{
			if(n){
				hidePointer()
				setHide(true)
				setNode(null)
			}else{
				
				setHide(false)
			}
		})
		var sub3 = flagSubject.subscribe(({minimize,entered,loaded}:any)=>{
			if(!loaded || minimize || !entered){
				hidePointer()
				setHide(true)
				setNode(null)
			}else{
				setHide(false)
			}
		})
		return ()=>{
			sub.unsubscribe()
			sub2.unsubscribe()
			sub3.unsubscribe()
		}
	},[hoverSubject])
	
    return (<a.group >
      {/*
		//@ts-ignore */}
	  <mesh ref={ref} frustumCulled={false} position={[0,0,0]} onPointerMove={(e:any)=>{onPointerMove(e)}}  onPointerOut={()=>{hidePointer()}}>
		<boxBufferGeometry  attach="geometry" args={[layout.w+layout.padding,0.01,layout.h+layout.padding]}></boxBufferGeometry>
        <a.meshStandardMaterial attach="material" color="white" transparent opacity={0.0} depthWrite={false} />
		{/*
		//@ts-ignore */}
	{boxGeometry &&
	  <a.lineSegments frustumCulled={false} ref={lineSegmentRef1} scale={[1,1,1]} position={animatedProps.position}>
        <edgesGeometry attach="geometry" args={[boxGeometry]} />
        <a.lineDashedMaterial attach="material" color="white" transparent opacity={animatedProps.opacityHorisontal} depthWrite={false} gapSize={1} dashSize={0.5} scale={1} linewidth={1}/>
      </a.lineSegments>
	  
	  
}	  
	
{/*
		//@ts-ignore */}
	{boxGeometry &&
	  <a.lineSegments frustumCulled={false} ref={lineSegmentRef2} scale={[1,1,1]} rotation={[Math.PI/2,0,0]} position={animatedProps.position2}>
        <edgesGeometry attach="geometry" args={[boxGeometry]} />
        <a.lineDashedMaterial attach="material" color="white" transparent opacity={animatedProps.opacityVertical} depthWrite={false} gapSize={1} dashSize={0.5} scale={1} linewidth={1}/>
      </a.lineSegments>
	  }
      </mesh>
	  <mesh frustumCulled={false}>
		<boxBufferGeometry  ref={boxGeometryRef} attach="geometry" args={[0.01,0.01,layout.h+layout.padding+50]}></boxBufferGeometry>
        <a.meshStandardMaterial attach="material" color="white" transparent opacity={0.0} depthWrite={false} />
      </mesh>
	  
	  <a.group ref={textRef} position={animatedProps.position3} rotation={[-Math.PI/2,0,-Math.PI/2]}>
	  
		<Html zIndexRange={[100, 0]} // Z-order range (default=[16777271, 0])
>
{node && !hide &&	<div className={`tooltip ${invert? 'invert':''}`}>

		<h2>{node.title.toUpperCase()}</h2>
		<p className="dateText">{startDateFormatted +  " - " +endDateFormatted}</p>
		<div className="actionText" style={{background:node.contentCount > 0?node.color:"none",border:node.contentCount > 0?"none":"solid 1px white"}}>{node.contentCount > 0?"open to view":"only for refence"}</div>

	</div>
}
</Html>

		
	  </a.group>
	 
	
		
	  </a.group>
    )
}
